<template>
	<div :class="['mask',show?'mask-show':'']">
		<div :class="['tip-model',show?'model-show':'']" @click.stop="preventEvent">
			<div class="model-title">{{title}}</div>
			<slot v-if="custom" name="content"></slot>
			<div v-else class="model-content">{{content}}</div>
			<div class="model-btn-con">
				<div class="model-btn-item" v-if="showCancelBtn" @click="cancel">取消</div>
				<div class="model-btn-item right-btn"
					:style="'background-color: '+confirmBtnBg+';border-color: '+confirmBtnBg" @click="confirm">
					{{confirmText}}
				</div>
			</div>
		</div>
	</div>


</template>

<script>
	export default {
		props: {
			show: {
				type: Boolean,
				default: false
			},
			title: {
				type: String,
				default: "温馨提示"
			},
			content: {
				type: String,
				default: "这里是提示内容"
			},
			custom: {
				type: Boolean,
				default: false
			},
			showCancelBtn: {
				type: Boolean,
				default: true
			},
			confirmText: {
				type: String,
				default: "确定"
			},
			confirmBtnBg: {
				type: String,
				default: "#000"
			}
		},
		data() {
			return {
				
			}
		},
		methods: {
			preventEvent() {
				return
			},
			cancel() {
				this.$emit('cancel')
			},
			confirm() {
				this.$emit('confirm')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.mask {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		transition: 0.3s;
		visibility: hidden;
		z-index: 10;
	}

	.mask-show {
		background-color: rgba(0, 0, 0, 0.5);
		visibility: visible;
	}

	.tip-model {
		width: 600px;
		border-radius: 15px;
		background-color: #fff;
		text-align: center;
		padding: 40px;
		padding-bottom: 45px;
		transform: scale(1.2);
		opacity: 0;
		transition: 0.3s;
		visibility: hidden;
	}

	.model-show {
		transform: scale(1);
		opacity: 1;
		visibility: visible;
	}

	.model-title {
		font-weight: 700;
		font-size: 34px;
		color: #000;
	}

	.model-content {
		padding: 45px 20px 55px;
		line-height: 40px;
		color: #000;
		font-size: 30px;
	}

	.model-btn-con {
		display: flex;
		justify-content: space-around;
	}

	.model-btn-item {
		line-height: 68px;
		border-radius: 34px;
		box-sizing: content-box;
		border: 1px solid #ddd;
		width: 220px;
		font-size: 30px;
		color: #333;
	}

	.right-btn {
		border-color: #000;
		color: #fff;
		background-color: #000;
	}
</style>
