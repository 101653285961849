<template>
	<div class="container-wrapper">
		<navbar title="新手教程"></navbar>
		<div class="teach-container">
			<div class="teach-item-con" v-for="(item,index) in teach_arr" :key="index" @click="chooseItem(index)">
				<div class="teach-item">
					<div class="teach-title">{{item.title}}</div>
					<i class="iconfont icon-arrow-down-bold"></i>
				</div>
				<div class="teach-content" v-if="current==index">{{item.content}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	export default {
		data() {
			return {
				teach_arr: [{
						title: '灰豚通告是什么？',
						content: '高质量买家秀/种草平台，让你的自拍产生价值，为产品拍摄优质的展示图片/视频，获得佣金或者免费赠送的商品。'
					},
					{
						title: '模特入驻收费吗？',
						content: '模特入驻平台不收取任何费用，只需要注册并完成模特认证，审核通过之后即可报名通告。平台不需要垫付任何资金，所有风险由平台承担。'
					},
					{
						title: '哪些人可以接通告？',
						content: '18岁以上，只要拍出的照片好看！ 商家愿意付钱要拍的照片，自然不是随意乱拍的，如果没有相关经验，可以参考首页优质模特的网拍相册案例。'
					},
					{
						title: '寄拍和赠拍是什么？',
						content: '寄拍：模特拍摄完成后需要将商品寄回给商家。\n赠拍：模特拍摄完成后不需要将商品寄回，商家免费赠送。'
					},
					{
						title: '如何注册？',
						content: '提交18张照片等待审核，通过后填写基本资料并实名认证，然后就可以报名啦！'
					},
					{
						title: '报名时的报价是什么？',
						content: '为了让优秀的你能赚到更多的钱，报名通告的佣金价格由你自己决定！\n赠拍单建议报价0-20元，寄拍单建议报价20-50元（含寄回运费），主图详情建议报价80-150元，种草视频建议报价100-200元\n 认证通过后可到买家秀相册和种草相册更新更多优质的素材。商家派单时会参考模特相册素材和报价。'
					},
					{
						title: '不合格照片举例',
						content: '不合格照片举例\n1、图片模糊、光线昏暗/不佳\n要在白天或灯光充足摄。大量白色燥点是不合格照片。\n2、乱用滤镜/特效\n照片中不能让产品变色、变形。\n3、打码、遮挡、涂鸦\n照片中不能打码、贴纸、涂鸦、挡脸。\n4、不露脸\n除非商家要求不露脸，否则请尽量露脸拍摄。\n5、主体不突出\n产品不突出，占画面小于1/3。\n6、背景杂乱、难看\n如没有整洁环境可选择白墙，但人不要贴着墙。'
					},
					{
						title: '通告完成佣金什么时候到账？',
						content: '有佣金的赠拍通告，提交拍摄的照片/视频，商家审核通过后，佣金自动返回到平台余额。\n 有佣金的寄拍通告，在寄回商品后，商家收到包裹并在平台确认后，佣金自动返回到平台余额。'
					},
					{
						title: '提现有限制吗？',
						content: '每天可提现3次，最低1元起提，可提现到微信或支付宝'
					},
					{
						title: '注意事项',
						content: '需要寄回的产品，一定要保护好产品外观不影响二次销售，拍摄时一定小心不要弄脏弄坏。服装类产品有折痕的，需要用挂烫机熨烫才可拍摄。'
					},
					{
						title: '邀请规则及奖励说明',
						content: "邀请模特：被邀请模特每次接单，您都可以获得0.5元奖励。\n邀请商家:  被邀请商家每次派单，您都可以获得1元奖励。\n所有奖励上不封顶，长期有效。\n 禁止推广自己的作弊行为，发现将取消资格最终解释权归灰豚通告所有。"
					}
				],
				current: -1
			}
		},
		components: {
			navbar
		},
		activated() {
			this.current = -1
		},
		methods: {
			chooseItem(index) {
				this.current = index
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper {
		background-color: #fff;
		overflow-y: auto;
	}

	.teach-item-con {
		margin: 0 20px;
		border-bottom: 1px solid #f1f1f1;
		padding: 10px 0;

		.teach-item {
			display: flex;
			justify-content: space-between;
			line-height: 80px;


			.teach-title {
				font-size: 32px;
				font-weight: 700;
			}

			.icon-arrow-down-bold {
				font-size: 24px;
				color: #AAAAAA;

			}
		}

		.teach-content {
			line-height: 40px;
			font-size: 28px;
			color: #888;
			padding-bottom: 20px;
			white-space: pre-wrap;
		}
	}
</style>
