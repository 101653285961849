<template>
	<div class="container-wrapper">
		<navbar title="模特认证"></navbar>
		<div class="auth_step_progress">
			<div class="step-icon">
				<img v-if="current_step==1"
					src="https://img.jimeimadou.com/common/resource/step_icon/step2%403x.png"></img>
				<img v-if="current_step==2"
					src="https://img.jimeimadou.com/common/resource/step_icon/step3%403x.png"></img>
				<div class="step-text">1、填写基本信息</div>
			</div>
			<div class="line" :style="current_step==1?'background-color: #C4C6CC;':''"></div>
			<div class="step-icon">
				<img v-if="current_step==1"
					src="https://img.jimeimadou.com/common/resource/step_icon/step1%403x.png"></img>
				<img v-if="current_step==2"
					src="https://img.jimeimadou.com/common/resource/step_icon/step2%403x.png"></img>
				<div class="step-text">2、实名认证</div>
			</div>
		</div>

		<div v-if="current_step==1">
			<div class="msg-container">
				<div class="msg-item">
					<div>模特类型</div>
					<div class="msg-item-right" v-if="is_disabled">
						<div>{{mdTypes[select_mt_type]}}</div>
					</div>
					<div class="msg-item-right" v-else @click="showModel('sizeModel','mdType',select_mt_type)">
						<div v-if="select_mt_type">{{mdTypes[select_mt_type]}}</div>
						<div v-else class="no-select">请选择</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
				<div class="msg-item">
					<div>身高</div>
					<div class="msg-item-right">
						<input class="item-input" v-model="model_height" placeholder="请输入身高"
							placeholder-class="placeholder" />
						<div class="right-icon">cm</div>
					</div>
				</div>
				<div class="msg-item">
					<div>体重</div>
					<div class="msg-item-right">
						<input class="item-input" v-model="model_weight" placeholder="请输入体重"
							placeholder-class="placeholder" />
						<div class="right-icon">kg</div>
					</div>
				</div>
				<div class="msg-item">
					<div>上衣</div>
					<div class="msg-item-right" @click="showModel('sizeModel','coat',select_coat_size)">
						<div v-if="select_coat_size">{{select_coat_size}}</div>
						<div v-else class="no-select">请选择</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
				<div class="msg-item">
					<div>裤子</div>
					<div class="msg-item-right" @click="showModel('sizeModel','trousers',select_trousers_size)">
						<div v-if="select_trousers_size">{{select_trousers_size}}</div>
						<div v-else class="no-select">请选择</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
				<div class="msg-item">
					<div>鞋子</div>
					<div class="msg-item-right" @click="showModel('sizeModel','shoes',select_shoes_size)">
						<div v-if="select_shoes_size">{{select_shoes_size}}</div>
						<div v-else class="no-select">请选择</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
				<div class="msg-item" v-if="select_mt_type == 102">
					<div>罩杯</div>
					<div class="msg-item-right" @click="showModel('sizeModel','cup',select_cup_size)">
						<div v-if="select_cup_size">{{select_cup_size}}</div>
						<div v-else class="no-select">请选择</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
			</div>

			<div class="msg-container" style="margin: 20px 0;">
				<div class="msg-item">
					<div>微信号</div>
					<div class="msg-item-right">
						<input class="item-input" type="text" v-model="wx_number" placeholder="请填写微信号"
							placeholder-class="placeholder" />
					</div>
				</div>
			</div>

			<div class="msg-container">
				<div class="msg-item">
					<div class="addr-label">收货人</div>
					<input class="addr-input" type="text" v-model="receive_name" placeholder="请填写收货人姓名"
						placeholder-class="placeholder" />
				</div>
				<div class="msg-item">
					<div class="addr-label">手机号码</div>
					<input class="addr-input" v-model="receive_phone" placeholder="请填写手机号码"
						placeholder-class="placeholder" />
				</div>
				<div class="msg-item">
					<div class="addr-label">收货地址</div>
					<div class="addr-input city-con" v-if="address_area" @click="showModel('cityModel')">
						{{address_area}}
					</div>
					<div class="addr-input" v-else style="color: #aaa;" @click="showModel('cityModel')">请选择省市区</div>
				</div>
				<div class="msg-item">
					<div class="addr-label">详细地址</div>
					<input class="addr-input" type="text" v-model="address" placeholder="请填写详细地址"
						placeholder-class="placeholder" />
				</div>
			</div>

			<div class="btn-container">
				<div class="next-btn" @click="toNextStep">确认并下一步</div>
			</div>
		</div>

		<div v-if="current_step==2">
			<div class="msg-container">
				<div class="msg-item">
					<div class="addr-label">真实姓名</div>
					<input class="addr-input" type="text" v-model="real_name" placeholder="请填写真实姓名"
						placeholder-class="placeholder" />
				</div>
				<div class="msg-item">
					<div class="addr-label">身份证号</div>
					<input class="addr-input" type="text" v-model="idcard_no" placeholder="请填写身份证号"
						placeholder-class="placeholder" />
				</div>
				<div class="msg-item">
					<div class="addr-label">手机号</div>
					<input class="addr-input" maxlength="11" v-model="phone_number" placeholder="请填写手机号"
						placeholder-class="placeholder" />
					<div v-if="is_send" class="send-btn">验证码已发送({{time_out}})</div>
					<div v-else class="send-btn" @click="get_sms">获取验证码</div>
				</div>
				<div class="msg-item">
					<div class="addr-label">验证码</div>
					<input class="addr-input" v-model="sms_code" maxlength="6" placeholder="请填写验证码"
						placeholder-class="placeholder" />
				</div>
			</div>

			<div class="card-container">
				<div class="card-wrapper">
					<div v-if="IDcard_front" class="card-item">
						<img :src="IDcard_front" />
						<i class="iconfont icon-deletefill" @click="deleteIDcardImage1"></i>
					</div>
					<van-uploader v-else :before-read="chooseIDcardImage1">
						<div class="card-item">
							<img src="https://img.jimeimadou.com/common/resource/normal_icon/id_example%403x.png" />
							<div class="camera-con">
								<i class="iconfont icon-camera2"></i>
							</div>
						</div>
					</van-uploader>
				</div>

				<div class="card-wrapper">
					<div v-if="IDcard_back" class="card-item">
						<img :src="IDcard_back" />
						<i class="iconfont icon-deletefill" @click="deleteIDcardImage2"></i>
					</div>
					<van-uploader v-else :before-read="chooseIDcardImage2">
						<div class="card-item">
							<img src="https://img.jimeimadou.com/common/resource/normal_icon/id_example2%403x.png" />
							<div class="camera-con">
								<i class="iconfont icon-camera2"></i>
							</div>
						</div>
					</van-uploader>
				</div>

				<div class="card-tip">
					<div>温馨提示</div>
					<div>1、光线充足，禁止在黑暗的地方拍摄</div>
					<div>2、需要能清晰看到身份证上相关信息</div>
					<div style="color: #FF6685;">3、照片仅用于平台审核，不会泄露用户信息</div>
				</div>
			</div>

			<div class="btn-container">
				<div class="last-btn" @click="toLastStep">上一步</div>
				<div class="next-btn" @click="complete_btn_catchtap">确认提交</div>
			</div>
		</div>

		<city-select :show="modelName=='cityModel'" @cancel="hideModel" @change="chooseArea"></city-select>

		<size-model :show="modelName=='sizeModel'" :type="modelType" :value="select_size" :isChild="select_mt_type==103"
			@cancel="hideModel" @confirm="chooseSize"></size-model>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	import citySelect from "@/components/citySelect.vue"
	import sizeModel from "@/components/size-model.vue"
	import {
		uploadFile
	} from "../../util/uploadBaogao.js"
	export default {
		data() {
			return {
				current_step: 1, //当前步骤
				mdTypes: this.mdTypes,

				select_mt_type: '',
				select_coat_size: '',
				select_trousers_size: '',
				select_shoes_size: '',
				select_cup_size: '',

				modelName: null,
				modelType: "",
				select_size: "",

				//输入框信息
				model_height: null,
				model_weight: null,
				wx_number: null,
				phone_number: null,
				sms_code: null,

				//验证码
				is_send: false,
				time_out: 60,
				sms_code: "",
				user_info: null,

				//步骤一 地址
				address_pick_des: '',
				province_id: null,
				city_id: null,
				area_id: null,
				address_area: "",
				address: '',
				receive_name: '',
				receive_phone: '',
				is_default: 0,
				is_show: -1,

				//城市选择
				show_city_sel: false,
				is_reset: false,

				//实名
				real_name: "",
				idcard_no: "",
				IDcard_front: "",
				IDcard_back: "",

				is_disabled: true
			}
		},
		components: {
			navbar,
			citySelect,
			sizeModel
		},
		activated() {
			//判断用户是否设置默认地址
			this.getDefaultAddress()
			this.getUserInfo()
		},
		methods: {
			getUserInfo() {
				let sentData = {
					access_token: localStorage.getItem("access_token")
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
					process.env.VUE_APP_URL + "/mobi/center/index",
					this.$qs.stringify(params), {
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
						},
					}
				).then((res) => {
					if (res.data.status == "n") {
						return
					}
					let result = JSON.parse(res.data.data)
					console.log(result)
					this.select_mt_type = result.md_type
					this.is_disabled = result.md_type ? true : false
				});
			},
			getDefaultAddress() {
				let sentData = {
					access_token: localStorage.getItem("access_token")
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/addrlist",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "n") {
							return
						}
						let addresslist = JSON.parse(res.data.data);
						console.log("用户的地址列表", addresslist);
						if (addresslist.length != 0) {
							addresslist.map(item => {
								if (item.is_default == 1) {
									let addr_arr = item.detail.split(" ")
									this.province_id = item.province
									this.city_id = item.city
									this.area_id = item.area

									let province = addr_arr[0]
									let city = addr_arr[1]
									let area = item.area == 0 ? '' : addr_arr[2]
									this.address_area = province + ' ' + city + ' ' + area
									this.address_area = this.address_area.trim()

									this.address = item.area == 0 ? addr_arr[2] : addr_arr[3]
									this.receive_name = item.receive_name
									this.receive_phone = item.receive_phone
								}
							})
						}
					});
			},
			showModel(model_name, model_type, select_size) {
				if (model_name == 'sizeModel') {
					this.modelType = model_type
					this.select_size = select_size
				}
				this.modelName = model_name
			},
			hideModel() {
				this.modelName = null
			},
			chooseSize(e) {
				this.hideModel()
				// console.log(`选中的 ${e.type} 是 ${e.value}`)
				switch (e.type) {
					case "mdType":
						this.select_mt_type = e.value
						this.select_coat_size = ""
						this.select_trousers_size = ""
						this.select_shoes_size = ""
						this.select_cup_size = ""
						break;
					case "coat":
						this.select_coat_size = e.value
						break;
					case "trousers":
						this.select_trousers_size = e.value
						break;
					case "shoes":
						this.select_shoes_size = e.value
						break;
					case "cup":
						this.select_cup_size = e.value
						break;
					default:
						break;
				}
			},
			chooseArea(e) {
				console.log("我选择的地址=======", e)
				let province = e.province.name
				let city = e.city.name
				let area = e.area ? e.area.name : ''

				this.address_area = province + ' ' + city + ' ' + area
				this.address_area = this.address_area.trim()

				this.province_id = e.province.id
				this.city_id = e.city.id
				this.area_id = e.area ? e.area.id : 0
			},
			toNextStep() {
				//验证参数
				if (!this.select_mt_type) {
					this.$toast('请选择模特类型')
					return
				};
				if (!this.model_height) {
					this.$toast('请输入身高');
					return
				};
				if (this.model_height > 500) {
					this.$toast('请输入真实身高');
					return
				};
				if (!this.model_weight) {
					this.$toast('请输入体重');
					return
				};
				if (this.model_weight > 1000) {
					this.$toast('请输入真实体重');
					return
				};
				if (!this.select_coat_size) {
					this.$toast('请选择上衣尺寸');
					return
				};
				if (!this.select_trousers_size) {
					this.$toast('请选择裤子尺寸');
					return
				};
				if (!this.select_shoes_size) {
					this.$toast('请选择鞋子尺寸');
					return
				};
				if (this.select_mt_type == 102) {
					if (!this.select_cup_size) {
						this.$toast('请选择罩杯尺寸');
						return
					}
				}
				if (!this.wx_number) {
					this.$toast('请输入微信号');
					return
				}
				if (!this.receive_name) {
					this.$toast('请输入收货人名称');
					return
				}
				if (!this.receive_phone) {
					this.$toast('请输入收货人手机号码');
					return
				}
				if (!this.address_area) {
					this.$toast('请选择收货地址');
					return
				}
				if (!this.address) {
					this.$toast('请输入详细地址');
					return
				}
				this.current_step = 2
			},
			toLastStep() {
				this.current_step = 1
			},
			check_realname() {
				var ts = this;
				if (!this.real_name) {
					this.$toast('请输入真实姓名');
					return false
				}
				//去除字符串中的所有空格
				this.real_name = this.real_name.replace(/\s*/g, '')

				var name = this.real_name
				var reg = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,15}$/;
				if (!name.match(reg)) {
					this.$toast('姓名格式错误');
				}
				return name.match(reg)
			},
			check_idcard_no() {
				this.idcard_no = this.idcard_no.trim()
				if (!this.idcard_no) {
					this.$toast('请输入身份证号');
					return false
				}
				var code = this.idcard_no
				//身份证号合法性验证 
				//支持15位和18位身份证号
				//支持地址编码、出生日期、校验位验证
				var city = {
					11: "北京",
					12: "天津",
					13: "河北",
					14: "山西",
					15: "内蒙古",
					21: "辽宁",
					22: "吉林",
					23: "黑龙江 ",
					31: "上海",
					32: "江苏",
					33: "浙江",
					34: "安徽",
					35: "福建",
					36: "江西",
					37: "山东",
					41: "河南",
					42: "湖北 ",
					43: "湖南",
					44: "广东",
					45: "广西",
					46: "海南",
					50: "重庆",
					51: "四川",
					52: "贵州",
					53: "云南",
					54: "西藏 ",
					61: "陕西",
					62: "甘肃",
					63: "青海",
					64: "宁夏",
					65: "新疆",
					71: "台湾",
					81: "香港",
					82: "澳门",
					91: "国外"
				};
				var tip = "";
				var pass = true;
				var reg = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/;
				if (!code || !code.match(reg)) {
					tip = "身份证号格式错误";
					pass = false;
				} else if (!city[code.substr(0, 2)]) {
					tip = "地址编码错误";
					pass = false;
				} else {
					//18位身份证需要验证最后一位校验位
					if (code.length == 18) {
						code = code.split('');
						//∑(ai×Wi)(mod 11)
						//加权因子
						var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
						//校验位
						var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
						var sum = 0;
						var ai = 0;
						var wi = 0;
						for (var i = 0; i < 17; i++) {
							ai = code[i];
							wi = factor[i];
							sum += ai * wi;
						}
						var last = parity[sum % 11];
						if (parity[sum % 11] != code[17]) {
							tip = "身份证号码错误";
							pass = false;
						}
					}
				}
				console.log(pass)
				if (!pass) {
					this.$toast(tip);
				} else {
					console.log("身份证格式正确");
				}
				return pass
			},
			chooseIDcardImage1(file) {
				console.log("选择身份证正面", file);
				this.$toast.loading({
					message: "上传中",
					duration: 0,
				});
				uploadFile(file, "user/img/IDcard_image").then(res => {
					console.log(res)
					if (res.status == "fail") {
						this.$toast("图片上传失败，请重试")
					} else {
						this.IDcard_front = res.url
					}
					this.$toast.clear();
				})
			},
			chooseIDcardImage2(file) {
				console.log("选择身份证反面");
				this.$toast.loading({
					message: "上传中",
					duration: 0,
				});
				uploadFile(file, "user/img/IDcard_image").then(res => {
					console.log(res)
					if (res.status == "fail") {
						this.$toast("图片上传失败，请重试")
					} else {
						this.IDcard_back = res.url
					}
					this.$toast.clear();
				})
			},
			deleteIDcardImage1() {
				this.IDcard_front = ""
			},
			deleteIDcardImage2() {
				this.IDcard_back = ""
			},
			//获取验证码
			get_sms() {
				if (!this.phone_number) {
					this.$toast("请输入手机号");
					return
				}
				this.phone_number = this.phone_number.trim()
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					phone: this.phone_number,
					send_type: "user_bind"
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/sms/send",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "y") {
							console.log('请求验证码成功');
							this.$toast("发送成功");
							//验证请求已发送
							this.is_send = true
							var timer;
							let that = this
							timer = setInterval(() => {
								that.time_out--
								if (that.time_out == 0) {
									that.is_send = false,
										that.time_out = 60
									clearInterval(timer)
								}
							}, 1000)
						} else {
							this.$toast(res.data.error);
						}
					});
			},


			/**
			 * 提交
			 */
			complete_btn_catchtap: async function(e) {
				if (!this.check_realname()) {
					return
				}
				if (!this.check_idcard_no()) {
					return
				}
				if (!this.sms_code) {
					this.$toast("请输入验证码");
					return
				}
				if (!this.IDcard_front) {
					this.$toast("请上传身份证正面照片");
					return
				}
				if (!this.IDcard_back) {
					this.$toast("请上传身份证反面照片");
					return
				}
				this.$toast.loading({
					message: "提交中",
					forbidClick: true,
					duration: 0
				});

				this.phone_number = this.phone_number.trim();
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					md_type: this.select_mt_type,
					height: this.model_height,
					weight: this.model_weight,
					coat: this.select_coat_size,
					trousers: this.select_trousers_size,
					shoes: this.select_shoes_size,
					cups: this.select_cup_size,
					weixin: this.wx_number,
					receive_name: this.receive_name,
					receive_phone: this.receive_phone,
					province_id: this.province_id,
					city_id: this.city_id,
					area_id: this.area_id,
					address: this.address,
					real_name: this.real_name, //真实姓名
					real_idnum: this.idcard_no, //身份证号
					phone: this.phone_number,
					sms_code: this.sms_code.trim(),
					idcard_front_img: this.IDcard_front, //身份证正面
					idcard_backend_img: this.IDcard_back, //身份证反面
				};
				console.log("提交资料参数=============", sentData);
				let params = this.$addParams(sentData);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/mtauthcommittwo",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						this.$toast.clear();
						if (res.data.status == 'y') {
							this.$router.replace({
								name: "waitAuth",
								query: {
									title: "mtAuth"
								}
							});
						} else {
							console.log(res.data.error);
							this.$toast(res.data.error)
						}
					});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper {
		overflow-y: auto;
		background-color: #f5f5f5;
	}

	/* 新 */
	.auth_step_progress {
		padding: 50px 0 80px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.auth_step_progress img {
		width: 40px;
		height: 40px;
		display: block;
	}

	.step-icon {
		position: relative;
	}

	.step-text {
		position: absolute;
		top: calc(100% + 20px);
		left: 50%;
		transform: translateX(-50%);
		white-space: nowrap;
		font-size: 24px;
		color: #888;
	}

	.auth_step_progress .line {
		width: 200px;
		height: 6px;
		border-radius: 3px;
		margin: 0 40px;
		background-color: #FF6685;
	}

	.msg-container {
		background-color: #fff;
	}

	.msg-item {
		display: flex;
		margin: 0 24px;
		line-height: 100px;
		justify-content: space-between;
		align-items: center;
		border-bottom: 2px solid #F5F5F5;
		font-size: 30px;
	}

	.msg-item:last-child {
		border: 0;
	}

	.msg-item-right {
		flex: 1;
		display: flex;
		justify-content: flex-end;
	}

	.no-select {
		color: #aaa;
		font-size: 26px;
	}

	.msg-item .icon-you {
		font-size: 14px;
		color: #ccc;
		margin-left: 5px;
	}

	.right-icon {
		width: 50px;
		text-align: right;
	}

	.item-input {
		height: 100px;
		text-align: right;
	}

	.placeholder {
		color: #aaa;
		font-size: 24px;
	}

	.addr-label {
		width: 160px;
	}

	.addr-input {
		flex: 1;
		height: 100px;
	}

	.city-con {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.send-btn {
		/* padding: 0 25px; */
		line-height: 60px;
		border-radius: 30px;
		/* background-color: #FF6685; */
		/* color: #fff; */
		color: #FF6685;
	}

	.btn-container {
		display: flex;
		justify-content: center;
		padding: 60px 0 100px;

	}

	.next-btn {
		line-height: 80px;
		border-radius: 40px;
		width: 380px;
		text-align: center;
		background-color: #000;
		color: #fff;
		font-size: 32px;
	}

	.last-btn {
		line-height: 80px;
		border-radius: 40px;
		width: 200px;
		text-align: center;
		background-color: #fff;
		font-size: 32px;
		margin-right: 30px;
	}

	.card-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.card-wrapper {
		padding-top: 30px;
	}

	.card-item {
		width: 460px;
		height: 292px;
		border-radius: 20px;
		overflow: hidden;
		position: relative;
		background-color: #fff;
	}

	.card-item img {
		width: 100%;
		height: 100%;
		border-radius: 10px;
		object-fit: cover;
	}

	.card-item .camera-con {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
		color: #fff;
		background-color: rgba(0, 0, 0, 0.6);
		border-radius: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.icon-camera2 {
			font-size: 46px;
		}
	}

	.card-item .icon-deletefill {
		position: absolute;
		right: 0;
		top: 0;
		width: 50px;
		text-align: center;
		height: 50px;
		line-height: 50px;
		border-radius: 0 10px 0 10px;
		background-color: rgba(0, 0, 0, 0.8);
		color: #fff;
	}

	.card-tip {
		width: 500px;
		line-height: 40px;
		font-size: 24px;
		padding-top: 30px;
		color: #666;
	}

	.sku-model {
		border-radius: 20px 20px 0 0 !important;
		overflow: hidden;
		z-index: 100;
		text-align: center;
	}

	.sku-model .model-title {
		font-size: 34px;
		font-weight: 700;
		line-height: 100px;
		color: #000;
	}

	.sku-model .sku-con {
		padding: 0 33px;
		display: flex;
		flex-wrap: wrap;
	}

	.sku-item-con {
		width: 25%;
		padding: 10px;
	}

	.sku-item {
		line-height: 70px;
		border-radius: 15px;
		border: 1px solid #eee;
		background-color: #eee;
		color: #333;
		font-size: 26px;
	}

	.sku-sel {
		background-color: #FFEBEF;
		color: #FF6685;
		border-color: #FF6685;
	}

	.model-btn {
		width: 360px;
		border-radius: 40px;
		line-height: 80px;
		background-color: #000;
		color: #fff;
		font-size: 30px;
		margin: 60px auto;
	}

	.vant_popup_box {
		border-radius: 16px 16px 0 0;
	}



	.van-toast {
		width: auto;
	}

	::-webkit-input-placeholder {
		/* WebKit, Blink, Edge */
		color: #aaa;
	}

	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #aaa;
	}

	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #aaa;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #aaa;
	}

	.van-toast__loading {
		text-align: center;
		font-size: 1rem;
		padding: 0.2rem 1rem;
	}

	.van-loading__spinner {
		width: 0.6rem;
		height: 0.6rem;
	}
</style>
