<template>
	<div>
		<navbar title="设置"></navbar>
		<div style="background-color: #fff;">
			<div class="setting-item" @click="toAbout">
				<div class="user_name">关于灰豚通告</div>
				<i class="iconfont icon-you"></i>
			</div>
			<div class="setting-item" @click="toNoticeList">
				<div class="user_name">新功能通知</div>
				<i class="iconfont icon-you"></i>
			</div>
			<div class="setting-item" @click="toNoticeSetting">
				<div class="user_name">通知设置</div>
				<i class="iconfont icon-you"></i>
			</div>
			<div class="setting-item" @click="toAddress">
				<div class="user_name">收货地址</div>
				<i class="iconfont icon-you"></i>
			</div>
			<div class="setting-item" @click="toFeedback">
				<div class="user_name">意见反馈</div>
				<i class="iconfont icon-you"></i>
			</div>
			<div class="setting-item">
				<div class="user_name">当前版本</div>
				<div>2.1.4</div>
			</div>
		</div>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	export default {
		data() {
			return {
				
			}
		},
		components:{
			navbar
		},
		methods:{
			toAbout(){
				console.log("关于灰豚通告")
				this.$router.push({
					name: "aboutUs"
				});
			},
			toNoticeList(){
				console.log("新功能通知")
				this.$router.push({
					name: "newNotice"
				});
			},
			toNoticeSetting(){
				console.log("通知设置")
				this.$router.push({
					name: "noticeSetting"
				});
			},
			toAddress(){
				console.log("收货地址")
				this.$router.push({
					name: "addressList"
				});
			},
			toFeedback(){
				console.log("意见反馈")
				this.$router.push({
					name: "feedback"
				});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.setting-item {
		background-color: white;
		line-height: 110px;
		margin: 0 24px;
		display: flex;
		justify-content: space-between;
		border-bottom: 2px solid #F7F7F7;
		font-size: 30px;
	}
	.setting-item:last-child{
		border: 0;
	}
	.icon-you{
		font-size: 20px;
		color: #aaa;
	}
	
	
	.user_logout_view {
		background-color: white;
		margin-top: 20px;
		line-height: 100px;
		font-size: 30px;
		color: #262A33;
		line-height: 120px;
		text-align: center;
	}
	

</style>
