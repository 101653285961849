<template>
	<div class="mask-container" :class="show?'mask-show':''" @click="hideModel">
		<div class="area-model" :class="show?'show-model':''" @click.stop="">
			<div class="area-model-title">
				<div>请选择所在地区</div>
				<div class="iconfont icon-roundclose" @click="hideModel"></div>
			</div>
			<div class="select-result">
				<div class="select-item" :class="[province_select?'text-black':'text-gray',select_index==0?'selected':'']" @click="chooseTab(0)">{{province_select?province_select.name:"请选择"}}</div>
				<div class="select-item" :class="[city_select?'text-black':'text-gray',select_index==1?'selected':'']" @click="chooseTab(1)">{{city_select?city_select.name:"请选择"}}</div>
				<div class="select-item" v-if="showArea" :class="[area_select?'text-black':'text-gray',select_index==2?'selected':'']" @click="chooseTab(2)">{{area_select?area_select.name:"请选择"}}</div>
			</div>
			<div v-if="isLoading" class="loading-con">
				<van-loading type="spinner"/>
			</div>
			<template v-else>
				<div class="area-con" v-if="select_index==0">
					<div class="area-item" v-for="(item,index) in province_list" @click="chooseProvince(item)">{{item.name}}</div>
				</div>
				<div class="area-con" v-if="select_index==1">
					<div class="area-item" v-for="(item,index) in city_list" @click="chooseCity(item)">{{item.name}}</div>
				</div>
				<div class="area-con" v-if="select_index==2">
					<div class="area-item" v-for="(item,index) in area_list" @click="chooseArea(item)">{{item.name}}</div>
				</div>
			</template>
			
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			show:{
				type:Boolean,
				default:false
			}
		},
		data(){
			return{
				select_index:0,
				province_select:null,
				city_select:null,
				area_select:null,
				province_list:[],
				city_list:[],
				area_list:[],
				areaData:[],
				showArea:true,
				isLoading:true
			}
		},
		watch:{
			show(val){
				if(val){
					this.getProvinces()
					this.select_index = 0
				}
			},
		},
		methods:{
			getProvinces() {
				let sentData = {
					access_token: localStorage.getItem("access_token")
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/index/area",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						this.isLoading = false
						if (res.data.status == "n") {
							return
						}
						let result = JSON.parse(res.data.data)
						this.province_list = result
						// console.log("省份列表",this.province_list)
					});
			},
			hideModel(){
				this.$emit("cancel")
			},
			chooseTab(index){
				this.select_index = index
			},
			chooseProvince(item){
				this.province_select = {
					id:item.id,
					name:item.name
				}
				this.city_list = item.child
				this.city_select = null
				this.area_select = null
				this.select_index = 1
			},
			chooseCity(item){
				this.city_select = {
					id:item.id,
					name:item.name
				}
				this.area_list = item.child?item.child:[]
				this.area_select = null
				
				
				//部分城市下面没有区域需特殊处理（例：湖北神农架）
				// console.log(item)
				if(!item.child){
					this.showArea = false
					this.hideModel()
					this.$emit("change",{
						province:this.province_select,
						city:this.city_select,
						area:this.area_select
					})
					return
				}else{
					this.showArea = true
				}
				
				this.select_index = 2
			},
			chooseArea(item){
				this.area_select = {
					id:item.id,
					name:item.name
				}
				this.hideModel()
				this.$emit("change",{
					province:this.province_select,
					city:this.city_select,
					area:this.area_select
				})
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.mask-container{
		position: fixed;
		top:0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0,0,0,0);
		transition: 0.3s;
		visibility: hidden;
		z-index: 200;
	}
	.mask-show{
		background-color: rgba(0,0,0,0.5);
		visibility: visible;
	}
	.area-model{
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 70vh;
		background-color: #fff;
		border-radius: 20px 20px 0 0;
		transform: translateY(100%);
		transition: 0.3s;
		visibility: hidden;
		display: flex;
		flex-direction: column;
		z-index: 200;
		&.show-model{
			transform: translateY(0);
			visibility: visible;
		}
		.area-model-title{
			display: flex;
			justify-content: space-between;
			line-height: 100px;
			font-size: 34px;
			padding: 0 24px;
			font-weight: 700;
			.icon-roundclose{
				font-size: 40px;
				font-weight: normal;
			}
		}
		.select-result{
			line-height: 60px;
			padding-bottom: 20px;
			display: flex;
			.select-item{
				margin: 0 24px;
				font-size: 30px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
			.text-black{
				color: #000;
			}
			.text-gray{
				color: #888;
			}
			.selected{
				position: relative;
			}
			.selected::after{
				content: "";
				position: absolute;
				bottom: 0px;
				width: 100%;
				height: 4px;
				border-radius: 2px;
				left: 0;
				background-color: #FF5874;
			}
		}
		.area-con{
			flex: 1;
			overflow-y: auto;
			.area-item{
				line-height: 80px;
				padding: 0 24px;
				font-size: 28px;
			}
		}
	}
	.loading-con{
		text-align: center;
		padding: 200px 0;
	}
</style>
