<template>
	<div class="container-wrapper">
		<navbar title="编辑亲情相册"></navbar>
		<div class="edit_family_container">
			<div class="photos_main_area">
				<div v-for="(item,index) in show_album_list" :key="index" class="photo_item_wrap">
					<img class="cover-img" v-lazy="item.photo_list[0]">
					<div class="album_info_wrap" @click="showBottomModel(index)">
						<div class="album_name">{{mdTypes[item.md_type]}}相册</div>
						<i class="iconfont icon-gengduo"></i>
					</div>
					<div class="item-bottom-con">
						<div class="item-status" v-if="item.audit_status == 1">审核中</div>
						<div class="item-status" v-if="item.audit_status == 2">已通过</div>
						<div class="item-status" v-if="item.audit_status == 3">
							<div style="display: flex;">
								<i class="iconfont icon-question-circle"></i>
								<div>已拒绝</div>
							</div>
						</div>
						<div class="total-num">
							<i class="iconfont icon-tupian"></i>
							<div>{{item.photo_list.length}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		
		<van-popup class="vant_popup_box" position="bottom" v-model="isBottom">
			<div class="action-model">
				<div class="action-item-con">
					<div class="action-item" @click="editAlbum">编辑相册</div>
					<div class="action-item" @click="deleteAlbum">删除相册</div>
				</div>
				<div class="action-cancel">取消</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	import tipModel from "@/components/tip-model.vue"
	export default {
		data() {
			return {
				album: [],
				mdTypes: this.mdTypes,
				show_album_list: [],
				refuseReason: "",
				modelName: "",
				isBottom:false,
				current_index:-1,
				isRefuse:false
			}
		},
		components:{
			navbar,
			tipModel
		},
		activated() {
			this.getFamilyAlbum()
		},
		methods: {
			//获取亲情相册列表
			getFamilyAlbum() {
				let sentData = {
					access_token: localStorage.getItem("access_token")
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/familyphotolist",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						// console.log(res.data)
						if (res.data.status == "n") {
							return
						}
						let result = JSON.parse(res.data.data)
						// console.log(result)
						if (result.length != 0) {
							for (let i = 0; i < result.length; i++) {
								result[i].audit_status = parseInt(result[i].audit_status)
								result[i].photo_list = result[i].photo_list ? result[i].photo_list.split(";") : []
							}
							this.show_album_list = result
						}
					});
			},
			showBottomModel(index){
				this.current_index = index
				this.isBottom = true
			},
			hideModel() {
				this.isBottom = false
			},
			editAlbum() {
				let album_info =  this.show_album_list[this.current_index]
				localStorage.setItem("family_album_info",JSON.stringify(album_info))
				this.hideModel()
				this.$nextTick(()=>{
					this.$router.push({
						name: "createFamilyAlbum"
					});
				})
			},
			deleteAlbum() {
				let album_info = this.show_album_list[this.current_index]
				if (album_info.audit_status == 1 || album_info.audit_status == 2) {
					this.$toast("只允许删除审核拒绝的相册")
					this.hideModel()
					return
				}
				
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					mt_id: album_info.mt_id
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/delfamilyphoto",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							this.hideModel()
							this.show_album_list.splice(this.current_index, 1)
							this.$toast("删除成功")
						}else{
							this.$toast(res.data.error)
						}
					});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper{
		background-color: #f1f1f1;
		overflow-y: auto;
	}
	.photos_main_area {
		padding: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.photo_item_wrap {
		position: relative;
		width: 346px;
		height: 480px;
		margin-right: 18px;
		margin-bottom: 18px;
		border-radius: 10px;
		overflow: hidden;
	}
	.cover-img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.photo_item_wrap:nth-child(2n) {
		margin-right: 0;
	}

	.mjx_image_item {
		height: 100%;
		width: 100%;
	}

	.mjx_num_wrap {
		box-sizing: border-box;
		padding: 0 10px;
		position: absolute;
		bottom: 20px;
		right: 20px;
		height: 30px;
		width: 80px;
		background: rgba(0, 0, 0, 0.5);
		color: #fff;
		display: flex;
		justify-content: space-around;
		align-items: center;
		border-radius: 15px;
		font-size: 24px;
	}

	.mjx_num_pic {
		width: 25px;
		height: auto;
	}

	.album_info_wrap {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 80px;
		box-sizing: border-box;
		padding: 0 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		color: #fff;
		font-size: 30px;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%);
	}

	.icon-gengduo {
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		background-color: rgba(0, 0, 0, 0.6);
		color: #fff;
		border-radius: 50%;
		font-size: 24px;
	}

	.handle_mask {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 10000;
	}

	.handle_main {
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		background: #F5F5F5;
		border-radius: 20px 20px 0 0;
		overflow: hidden;

	}

	.handle_btn {
		width: 100%;
		line-height: 120px;
		background: #fff;
		text-align: center;
		font-size: 34px;
		box-sizing: border-box;
	}

	.empty_wrap {
		position: absolute;
		top: 200px;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.empty_img {
		width: 50%;
	}

	.empty_text {
		margin-top: 50px;
		color: #8F9097;
	}

	.mjx_album_status {
		position: absolute;
		bottom: 20px;
		right: 120px;
		height: 30px;
		width: 74px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 22px;
	}

	.video-mask {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.video-mask img {
		width: 60px;
		height: auto;
		display: block;
	}

	.item-video {
		width: 100%;
		height: 100%;
		display: block;
		border-radius: 10px;
	}

	.item-bottom-con {
		position: absolute;
		bottom: 15px;
		right: 15px;
		display: flex;
		align-items: center;
	}

	.item-status {
		line-height: 40px;
		height: 40px;
		font-size: 22px;
		padding: 0 15px;
		border-radius: 40px;
		background-color: rgba(0, 0, 0, 0.5);
		color: #fff;
	}
	.icon-question-circle{
		color:#fff;margin-right:3px;
		font-size: 20px;
	}
	

	.total-num {
		background-color: rgba(0, 0, 0, 0.5);
		color: #fff;
		line-height: 40px;
		height: 40px;
		border-radius: 20px;
		padding: 0 15px;
		font-size: 24px;
		margin-left: 10px;
		display: flex;
	}

	.icon-tupian {
		margin-right: 5px;
		font-size: 20px;
	}
	.vant_popup_box{
		border-radius: 20px 20px 0 0;
		background-color: #fff;
		text-align: center;
		font-size: 30px;
		.action-item-con{
			border-bottom: 15px solid #f1f1f1;
			.action-item{
				line-height: 110px;
				border-bottom: 1px solid #F1F1F1;
				&:last-child{
					border: 0;
				}
			}
		}
		.action-cancel{
			line-height: 110px;
		}
	}
</style>
