<template>
	<div>
		<video class="video" :src="url" autoplay controls></video>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				url:""
			}
		},
		activated() {
			this.url = this.$route.query.url
		}
	}
</script>

<style lang="scss" scoped>
	.video{
		position: fixed;
		width: 100vw;
		height: 100vh;
		background-color: #000;
	}
</style>
