<template>
	<div class="container-wrapper">
		<navbar title="新功能通知"></navbar>
		<scroll-y class="scroll-container" ref="iScroll" @loadMore="getNoticeList">
			<empty v-if="list.length==0" title="暂无通知"></empty>
			<div class="notice-container" v-else>
				<div class="notice-item" v-for="(item,index) in list" :key="index">
					<div class="notice-title">{{item.title}}</div>
					<div class="notice-content">
						<div v-html="item.content"></div>
					</div>
					<div class="notice-time">{{item.add_time}}</div>
					<div v-if="index!=0" class="notice-mask"></div>
				</div>
			</div>
		</scroll-y>
	</div>
</template>

<script>
	import scrollY from "@/components/app-scroll-y.vue"
	import empty from "@/components/empty.vue"
	import navbar from "@/components/navbar.vue"
	export default {
		data() {
			return {
				page: 1,
				page_size: 10,
				stopLoad: false,
				isNull: false,
				list: [],
			}
		},
		components:{
			scrollY,
			empty,
			navbar
		},
		activated() {
			this.$nextTick(()=>{
				this.$refs.iScroll.scrollToTop();
			})
			this.getNoticeList()
		},
		methods: {
			getNoticeList() {
				if (this.stopLoad) {
					return
				}
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					page: this.page++,
					page_size: this.page_size
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/affichelist",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "y") {
							let result = JSON.parse(res.data.data)
							console.log(result)
							if (result.list.length < this.page_size) {
								if (result.list.length == 0 && this.page == 2) {
									this.isNull = true
								}
								this.stopLoad = true
							}
							this.list.push(...result.list)
						}
					});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper{
		background-color: #F7F7F7;
	}
	.scroll-container{
		width: 100vw;
		height: calc(100vh - 90px);
	}
	.notice-container{
		padding: 20px 0;
	}
	.notice-item{
		background-color: #fff;
		margin: 0 24px 20px;
		border-radius: 15px;
		padding: 40px 30px;
		position: relative;
		overflow: hidden;
	}
	.notice-title{
		font-size: 32px;
		font-weight: 700;
		color: #000;
		margin-bottom: 10px;
	}
	.notice-content{
		line-height: 40px;
		font-size: 28px;
		color: #333;
		margin-bottom: 30px;
	}
	.notice-time{
		font-size: 24px;
		color: #aaa;
	}
	.notice-mask{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border-radius: 15px;
		background-color: #fff;
		opacity: 0.7;
	}
</style>
