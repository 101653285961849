<template>
	<div>
		<back-top-fixed></back-top-fixed>
		<scroll-y class="scroll-container" ref="iscroll" @loadMore="loadmore">
			<mt-detail-top :album_type="$route.query.fromPage=='other'?'detail_other':'detail_mine'"
				:mt_id="$route.query.mt_id" @shareCard="shareCard" @editMessage="editMessage"
				@detailLoadFinish="detailLoadFinish"></mt-detail-top>

			<van-tabs background="transparent" line-height="0" swipe-threshold="3">
				<van-tab v-for="(item,index) in tabs" :key="index">
					<template #title>
						<div class="tab-item-con">
							<div class="tab-item" ref="tab" @click="chooseTab(index)">
								<div :class="['tab-name',current==index?'current':'']">{{item.title}}</div>
								<div class="tab-count" :style="current==index?'color:#FF6685':''">{{item.count}}</div>
							</div>
						</div>
					</template>
				</van-tab>
			</van-tabs>

			<div class="album-container">
				<!-- 个人相册和亲情相册 -->
				<template v-if="current==0||current>3">
					<empty v-if="album_photos.length==0" title="暂无照片"></empty>
					<div v-else class="photos-container">
						<div class="photo-item" v-for="(item,index) in album_photos" :key="index"
							@click="previewImages(index)">
							<image-loader class="photo-image" :src="addWaterMark(item)"></image-loader>
							<i v-if="current==0&&showEditBtn&&mtinfo.can_update==1" class="iconfont icon-delete"
								@click.stop="showDeleteModel(index)"></i>
						</div>
					</div>
				</template>
				<!-- 买家秀 -->
				<template v-if="current==1">
					<empty v-if="mjx.length==0" title="暂无买家秀相册"></empty>
					<div v-else class="photos-container">
						<div class="photo-item" v-for="(item,index) in mjx" :key="index"
							@click="toAlbumDetail(item.id)">
							<image-loader class="photo-image" :src="addWaterMark(item.cover_image||item.cover)">
							</image-loader>
							<div class="total-num">
								<i class="iconfont icon-tupian"></i>
								<div>{{item.photos.length+item.videos.length}}</div>
							</div>
							<template v-if="showEditBtn">
								<div class="my-icon-con" @click.stop="showMask(index)">
									<i
										:class="['my-icon-item iconfont',item.is_private?'icon-Notvisible':'icon-browse']"></i>
								</div>
								<div :class="['photo-mask',item.mask_show?'mask-show':'']">
									<div class="mask-title">
										<template v-if="item.is_private">
											<i class="mask-icon iconfont icon-browse"></i>
											<div>公开展示</div>
										</template>
										<template v-else>
											<i class="mask-icon iconfont icon-Notvisible"></i>
											<div>仅自己可见</div>
										</template>
									</div>
									<div class="mask-text">{{item.is_private?'选择后该相册将会展示在您的个人主页':'选择后该相册不对其他人展示'}}
									</div>
									<div class="mask-btn-con">
										<div class="mask-btn-item" @click.stop="hideMask">否</div>
										<div class="mask-btn-item sure" @click.stop="setPrivate">是</div>
									</div>
								</div>
							</template>
						</div>
					</div>
				</template>

				<!-- 种草相册 -->
				<template v-if="current==2">
					<empty v-if="zhongcao.length==0" title="暂无种草相册"></empty>
					<div v-else class="photos-container">
						<div class="photo-item" v-for="(item,index) in zhongcao" :key="index" @click="toAlbumDetail(item.id)">
							<image-loader class="photo-image" :src="addWaterMark(item.cover)"></image-loader>
							<div class="video-mask">
								<img class="play-icon" src="../../../public/static/play.png">
							</div>
							<template v-if="showEditBtn">
								<div class="my-icon-con" @click.stop="showMask(index)">
									<i
										:class="['my-icon-item iconfont',item.is_private?'icon-Notvisible':'icon-browse']"></i>
								</div>
								<div :class="['photo-mask',item.mask_show?'mask-show':'']">
									<div class="mask-title">
										<template v-if="item.is_private">
											<i class="mask-icon iconfont icon-browse"></i>
											<div>公开展示</div>
										</template>
										<template v-else>
											<i class="mask-icon iconfont icon-Notvisible"></i>
											<div>仅自己可见</div>
										</template>
									</div>
									<div class="mask-text">{{item.is_private?'选择后该相册将会展示在您的个人主页':'选择后该相册不对其他人展示'}}
									</div>
									<div class="mask-btn-con">
										<div class="mask-btn-item" @click.stop="hideMask">否</div>
										<div class="mask-btn-item sure" @click.stop="setPrivate">是</div>
									</div>
								</div>
							</template>
						</div>
					</div>
				</template>


				<!-- 主图、详情页 -->
				<template v-if="current==3">
					<empty v-if="masterMap.length==0" title="暂无主图详情作品"></empty>
					<div v-else class="photos-container">
						<div class="photo-item" v-for="(item,index) in masterMap" :key="index"
							@click="toAlbumDetail(item.id)">
							<image-loader class="photo-image" :src="addWaterMark(item.cover_image)"></image-loader>
							<div class="total-num">
								<i class="iconfont icon-tupian"></i>
								<div>{{item.photos.length}}</div>
							</div>
							<template v-if="showEditBtn">
								<div class="my-icon-con" @click.stop="showMask(index)">
									<i
										:class="['my-icon-item iconfont',item.is_private?'icon-Notvisible':'icon-browse']"></i>
								</div>
								<div :class="['photo-mask',item.mask_show?'mask-show':'']">
									<div class="mask-title">
										<template v-if="item.is_private">
											<i class="mask-icon iconfont icon-browse"></i>
											<div>公开展示</div>
										</template>
										<template v-else>
											<i class="mask-icon iconfont icon-Notvisible"></i>
											<div>仅自己可见</div>
										</template>
									</div>
									<div class="mask-text">{{item.is_private?'选择后该相册将会展示在您的个人主页':'选择后该相册不对其他人展示'}}
									</div>
									<div class="mask-btn-con">
										<div class="mask-btn-item" @click.stop="hideMask">否</div>
										<div class="mask-btn-item sure" @click.stop="setPrivate">是</div>
									</div>
								</div>
							</template>
						</div>
					</div>
				</template>
			</div>
		</scroll-y>

		<div class="btn-container" v-if="showEditBtn&&current==0">
			<!-- 上传按钮 -->
			<van-uploader v-if="mtinfo.can_update==1" :before-read="chooseImage" multiple>
				<div class="btn-item">
					<i class="iconfont icon-add"></i>
				</div>
			</van-uploader>
			<!-- 排序按钮 -->
			<!-- <div v-if="album_photos.length!=0" class="btn-item">
				<div>排序</div>
			</div> -->
		</div>

		<album-model :show="show_album" :swiper_photos="swiper_photos" :swiper_videos="swiper_videos"
			:current="swiper_current" @hideMask="hideModel">
		</album-model>

		<tip-model :show="modelName=='deleteModel'" title="温馨提示" content="确认要删除该照片吗？" @cancel="hideModel"
			@confirm="sureDeletePhoto">
		</tip-model>

		<tip-model :show="modelName=='errorModel'" title="温馨提示" :content="errorMessage" @cancel="hideModel"
			@confirm="hideModel">
		</tip-model>

	</div>
</template>

<script>
	import empty from "@/components/empty.vue"
	import scrollY from "@/components/app-scroll-y.vue"
	import albumModel from "@/components/album-model.vue"
	import mtDetailTop from "@/components/mtdetail-top.vue"
	import backTopFixed from "@/components/back-top-fixed.vue"
	import tipModel from "@/components/tip-model.vue"
	import {
		uploadFile
	} from "../../util/uploadBaogao.js"
	import {
		ImagePreview
	} from 'vant';
	export default {
		data() {
			return {
				tabs: [{
						title: "相册",
						count: 0,
						photos: []
					},
					{
						title: "买家秀",
						count: 0,
						photos: []
					},
					{
						title: "种草相册",
						count: 0,
						photos: []
					},
					{
						title: "主图/详情页",
						count: 0,
						photos: []
					}
				],

				album_photos: [],
				//买家秀
				mjx: [],
				page_mjx: 1,
				stopload_mjx: false,
				//种草视频
				zhongcao: [],
				page_zc: 1,
				stopload_zc: false,
				//主图详情作品
				masterMap: [],
				page_ztxq: 1,
				stopload_ztxq: false,

				sort_images: [],
				page_size: 10,
				current: 0,
				mdTypes: this.mdTypes,
				auditStatus: {
					1: {
						name: "审核中",
						class: "tab-status1"
					},
					2: {
						name: "已通过",
						class: "tab-status2"
					},
					3: {
						name: "已拒绝",
						class: "tab-status3"
					}
				},
				show_album: false,
				swiper_photos: [],
				swiper_videos: [],
				swiper_current: 0,

				mt_id: 0,
				showEditBtn: false,
				mtinfo: {},
				modelName: "",
				errorMessage: "",
				select_photo_index: -1,
				previewCompressParams: "?imageView2/2/w/1000", //预览图片压缩参数
				waterMark: "|watermark/1/blogo/2/image/aHR0cDovL2ptc3RhdGljLTEyNTU0OTc5MTYuY29zLmFwLXNoYW5naGFpLm15cWNsb3VkLmNvbS9jb21tb24vd2F0ZXJtYXJrZXIucG5n/dissolve/90/dx/10/dy/10",

			}
		},
		components: {
			mtDetailTop,
			empty,
			scrollY,
			albumModel,
			backTopFixed,
			tipModel
		},
		activated() {
			if (this.$route.query && this.$route.query.fromPage === "mine") {
				this.showEditBtn = true
			} else {
				this.showEditBtn = false
			}
			if (this.$route.query && this.$route.query.mt_id) {
				this.mt_id = this.$route.query.mt_id
			}
			console.log("传递给子组件参数========", this.showEditBtn, this.mt_id)
			this.initData()
		},
		methods: {
			initData() {
				this.tabs = [{
							title: "相册",
							count: 0,
							photos: []
						},
						{
							title: "买家秀",
							count: 0,
							photos: []
						},
						{
							title: "种草相册",
							count: 0,
							photos: []
						},
						{
							title: "主图/详情页",
							count: 0,
							photos: []
						}
					]
				this.album_photos = []
				//买家秀
				this.mjx = []
				this.page_mjx = 1
				this.stopload_mjx = false
				//种草视频
				this.zhongcao = []
				this.page_zc = 1
				this.stopload_zc = false
				//主图详情作品
				this.masterMap = []
				this.page_ztxq = 1
				this.stopload_ztxq = false

				this.$nextTick(() => {
					this.$refs.iscroll.scrollToTop()
				})
			},
			//显示相册弹框
			showMjxAlbum(index, photos, videos) {
				this.swiper_current = index
				this.swiper_photos = photos
				this.swiper_videos = videos
				this.show_album = true
			},
			toAlbumDetail(album_id) {
				this.$router.push({
					name: "albumDetail",
					query: {
						mt_id:this.mt_id,
						album_id,
						album_type: this.current
					}
				});
			},
			hideMjxAlbum() {
				this.show_album = false
				this.swiper_videos = []
			},
			shareCard() {
				console.log("分享模卡")
			},
			addWaterMark(url, type) {
				if (type == "mjx") {
					var compressParams = "?imageView2/2/w/750"
				} else {
					var compressParams = "?imageView2/1/w/500/h/700"
				}
				var waterMark =
					"|watermark/1/blogo/2/image/aHR0cDovL2ptc3RhdGljLTEyNTU0OTc5MTYuY29zLmFwLXNoYW5naGFpLm15cWNsb3VkLmNvbS9jb21tb24vd2F0ZXJtYXJrZXIucG5n/dissolve/90/dx/10/dy/10"
				var newUrl = url + compressParams + waterMark
				newUrl = newUrl.replace('https://img.jimeimadou.com',
					'https://jmstatic-1255497916.cos-website.ap-shanghai.myqcloud.com')
				return newUrl
			},
			editMessage() {
				console.log("编辑资料")
				this.$router.push({
					name: "userInfo"
				});
			},
			chooseImage(files) {
				console.log("选择照片")
				let file = [];
				if (!Array.isArray(files)) {
					file.push(files)
				} else {
					file = files;
				}
				let upload_list = []
				for (let i = 0; i < file.length; i++) {
					uploadFile(file[i], "user/img/photo_album").then(res => {
						// console.log("上传结果====", res)
						if (res.status == "success") {
							upload_list.push(res.url)
						}
						if (upload_list.length == urls.length) {
							this.handlePhoto('add', upload_list)
						}
					})
				}
			},
			//选择tab
			chooseTab(index) {
				console.log(index)
				//判断当前作品列表是否有未关闭的遮罩层（是否点击过右上角眼睛且未关闭），有则先关闭遮罩再切换tab
				this.hideMask()
				this.current = index
				this.album_photos = this.tabs[index].photos
				this.$refs.iscroll.scrollToTop()
			},
			// 展示仅自己可见遮罩层
			showMask(index) {
				this.hideMask()
				switch (this.current) {
					case 1:
						this.mjx[index].mask_show = true
						break;
					case 2:
						this.zhongcao[index].mask_show = true
						break;
					case 3:
						this.masterMap[index].mask_show = true
						break;
					default:
						break;
				}
				this.$forceUpdate()
				this.mask_index = index
			},
			// 隐藏仅自己可见遮罩层
			hideMask() {
				// console.log(this.mask_index)
				if (this.mask_index != -1) {
					switch (this.current) {
						case 1:
							this.mjx[this.mask_index].mask_show = false
							break;
						case 2:
							this.zhongcao[this.mask_index].mask_show = false
							break;
						case 3:
							this.masterMap[this.mask_index].mask_show = false
							break;
						default:
							break;
					}
					this.mask_index = -1
				}
				this.$forceUpdate()
			},
			//确认设置为仅自己可见/公开展示
			setPrivate() {
				let album
				let act_type
				let act_id
				let act_val
				switch (this.current) {
					case 1:
						album = this.mjx[this.mask_index]
						act_type = 1
						break;
					case 2:
						album = this.zhongcao[this.mask_index]
						act_type = 2
						break;
					case 3:
						album = this.masterMap[this.mask_index]
						act_type = 3
						break;
					default:
						break;
				}

				let sentData = {
					access_token: localStorage.getItem("access_token"),
					act_type,
					act_id: album.id,
					act_val: album.is_private ? "0" : "1"
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/handlephotocusshow",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							console.log("操作成功")
							this.$toast("设置成功")
							this.hideMask()
							album.is_private = !album.is_private
							this.$forceUpdate()
						} else {
							this.$toast(res.data.error)
						}
					});
			},
			showDeleteModel(index) {
				this.select_photo_index = index
				this.modelName = "deleteModel"
			},
			previewImages(index) {
				let photos = this.album_photos
				let list = []
				photos.forEach(item => {
					let imageUrl = item + this.previewCompressParams + this.waterMark
					list.push(imageUrl)
				})
				// console.log(list)
				ImagePreview({
					images: list,
					startPosition: index
				})
			},
			hideModel() {
				this.modelName = ""
				this.show_album = false
			},
			sureDeletePhoto() {
				if (this.album_photos.length <= 18) {
					this.errorMessage = "个人照片不得低于18张"
					this.modelName = "errorModel"
					return
				}
				this.handlePhoto('del')
			},
			handlePhoto(act, add_list) {
				let photos = this.album_photos.length == 0 ? '' : this.album_photos.join(";")
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					act: act,
					photos
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/handlephoto",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							if (act == "del") {
								this.album_photos.splice(this.select_photo_index, 1)
							} else {
								this.album_photos = this.album_photos.concat(add_list)
							}
							this.$toast(act == "del" ? "删除成功" : "上传成功")
							this.hideModel()
							this.tabs[0].count = this.album_photos.length
						} else {
							this.errorMessage = res.data.error
							this.modelName = "errorModel"
						}
					});
			},
			detailLoadFinish(result) {
				console.log("模特主页信息加载完毕=====", result)
				this.mtinfo = result.mtinfo

				//个人相册数据处理
				if (result.mtinfo.photo_list) {
					let photos = result.mtinfo.photo_list.split(";")
					this.tabs[0].count = photos.length
					this.tabs[0].photos = photos
					this.album_photos = this.tabs[0].photos
				}

				//买家秀数量
				this.tabs[1].count = result.mjx_num
				//种草视频数量
				this.tabs[2].count = result.zhongcao_num
				//主图详情页作品数量
				this.tabs[3].count = result.zhutu_num

				//亲情相册数据处理
				if (result.album.length != 0) {
					let family_album = []
					result.album.forEach(item => {
						item.photo_list = item.photo_list ? item.photo_list.split(";") : [],
							family_album.push({
								title: item.name,
								count: item.photo_list.length,
								photos: item.photo_list
							})
					})
					this.tabs = this.tabs.concat(family_album)
				}
				console.log("处理完的数据格式", this.tabs)

				//获取买家秀第一页数据
				this.getMjxs()
				//获取种草相册第一页数据
				this.getZhongcaoVideos()
				//获取主图详情作品第一页数据
				this.getMasterMapList()
			},
			loadmore() {
				if (this.current == 1) {
					this.getMjxs()
				} else if (this.current == 2) {
					this.getZhongcaoVideos()
				} else if (this.current == 3) {
					this.getMasterMapList()
				}
			},

			getMjxs() {
				if (this.stopload_mjx) {
					return
				}
				let sentData = {
					mt_id: this.mt_id,
					page: this.page_mjx,
					page_size: this.page_size,
					is_mine: this.showEditBtn ? 1 : 0
				}
				this.page_mjx++
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/mtmjxphotolist",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							let result = JSON.parse(res.data.data)
							console.log("买家秀相册列表=====", result)
							if (result.list.length != 0) {
								let mjx_list = result.list
								for (let i = 0; i < mjx_list.length; i++) {
									mjx_list[i].is_private = mjx_list[i].cus_show == 1
									mjx_list[i].photos = mjx_list[i].photos ? mjx_list[i].photos.split(";") : []
									mjx_list[i].videos = mjx_list[i].videos ? mjx_list[i].videos.split(";") : []
									if (mjx_list[i].photos.length == 0) {
										mjx_list[i].cover_image = ""
									} else {
										mjx_list[i].cover_image = mjx_list[i].photos[0]
									}
								}
								this.mjx.push(...mjx_list)
							} else {
								this.stopload_mjx = true
							}
						}
					});
			},
			getZhongcaoVideos() {
				if (this.stopload_zc) {
					return
				}
				let sentData = {
					mt_id: this.mt_id,
					page: this.page_zc,
					page_size: this.page_size,
					is_mine: this.showEditBtn ? 1 : 0
				}
				this.page_zc++
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/mtzhongcaophotolist",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							let result = JSON.parse(res.data.data);
							console.log("种草相册列表====", result)
							if (result.list.length != 0) {
								let zhongcao = result.list
								this.zhongcao.push(...result.list)
								for (let i = 0; i < zhongcao.length; i++) {
									zhongcao[i].is_private = zhongcao[i].cus_show == 1
								}
							} else {
								this.stopload_zc = true
							}
						}
					});
			},
			getMasterMapList() {
				if (this.stopload_ztxq) {
					return
				}
				let sentData = {
					mt_id: this.mt_id,
					page: this.page_ztxq,
					page_size: this.page_size,
					is_mine: this.showEditBtn ? 1 : 0
				}
				this.page_ztxq++
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/mtzhutuphotolist",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							let result = JSON.parse(res.data.data);
							console.log("主图详情作品列表====", result)
							if (result.list.length != 0) {
								let masterMap = result.list
								result.list.forEach(item => {
									item.is_private = item.cus_show == 1
									item.photos = item.photos.split(',')
									item.cover_image = item.photos[0]
									this.masterMap.push(item)
								})
							} else {
								this.stopload_ztxq = true
							}
						}
					});
			},

		}
	}
</script>

<style lang="scss" scoped>
	.scroll-container {
		width: 100vw;
		height: 100vh;
	}

	::v-deep .van-tab {
		padding: 0;
	}

	.tab-item-con {
		width: 250px;
		display: inline-block;
		line-height: 60px;

		.tab-item {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.tab-name {
			position: relative;
			font-weight: 700;
			font-size: 32px;
		}

		.current {
			color: #FF6685;
		}

		.current::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 5px;
			border-radius: 3px;
			background-color: #FF6685;
		}

		.tab-count {
			padding-left: 10px;
		}
	}


	.photos-container {
		padding: 10px 24px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.photo-item {
			width: 344px;
			height: 480px;
			position: relative;
			margin-bottom: 16px;
			border-radius: 10px;
			overflow: hidden;

			.zhanwei {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.my-icon-con {
			position: absolute;
			right: 15px;
			top: 15px;
			width: 40px;
			height: 40px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: #fff;
			border-radius: 50%;
			background-color: rgba(0, 0, 0, 0.5);
		}

		.my-icon-item {
			font-size: 30px;
			opacity: 0.9;
		}

		.my-icon-con .icon-Notvisible {
			color: #FF6685;
		}

		.photo-mask {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			bottom: 100%;
			background-color: rgba(0, 0, 0, 0.8);
			color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			font-size: 30px;
			padding: 0 35px;
		}

		.mask-show {
			bottom: 0;
			transition: 0.2s;
		}

		.mask-title {
			line-height: 70px;
			font-size: 36px;
			display: flex;
		}

		.photo-mask .mask-icon {
			color: #FF6685;
			margin-right: 10px;
			font-size: 44px;
		}

		.mask-text {
			line-height: 40px;
			margin-bottom: 80px;
			font-size: 26px;
			color: rgba(255, 255, 255, 0.8);
		}

		.mask-btn-con {
			display: flex;
			justify-content: space-between;
		}

		.mask-btn-item {
			width: 120px;
			text-align: center;
			line-height: 60px;
			border-radius: 30px;
			color: #aaa;
			background-color: #fff;
		}

		.mask-btn-item.sure {
			background-color: #FF6685;
			color: #fff;
		}

		.photo-item .photo-image {
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 10px;
			object-fit: cover;
		}

		.video-mask {
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;

			.play-icon {
				width: 40px;
				display: block;
			}
		}


		.photo-item .item-video {
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 10px;
		}

		.photo-item .icon-delete {
			position: absolute;
			right: 0;
			top: 0;
			background-color: rgba(0, 0, 0, 0.6);
			color: #fff;
			width: 44px;
			height: 44px;
			line-height: 44px;
			text-align: center;
			font-size: 28px;
			border-radius: 0 10px 0 10px;
		}



		.total-num {
			display: flex;
			position: absolute;
			right: 15px;
			bottom: 15px;
			background-color: rgba(0, 0, 0, 0.5);
			color: #fff;
			line-height: 40px;
			border-radius: 20px;
			padding: 0 15px;
			font-size: 24px;

			.icon-tupian {
				font-size: 20px;
			}
		}

		.icon-tupian {
			margin-right: 5px;
		}
	}

	.btn-container {
		position: fixed;
		right: 30px;
		bottom: 80px;
		width: 100px;

		.btn-item {
			width: 100px;
			height: 100px;
			border-radius: 50%;
			border: 5px solid #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 30px;
			color: #fff;
			background-color: #FF6685;
			margin-bottom: 30px;

			&:last-child {
				margin-bottom: 0;
			}

			.icon-add {
				font-size: 60px;
			}
		}
	}
</style>
