<template>
	<div class="container-wrapper">
		<navbar title="新增地址"></navbar>
		<div>
			<div class="input-con">
				<div class="lebal">收货人</div>
				<input class="input" v-model="receive_name" type="text" placeholder="请填写收货人">
			</div>
			<div class="input-con">
				<div class="lebal">手机号码</div>
				<input class="input" v-model="receive_phone" type="text" placeholder="请填写手机号码">
			</div>
			<div class="input-con">
				<div class="lebal">收货地址</div>
				<div v-if="address_area" class="input province" @click="showCityModel(true)">{{address_area}}</div>
				<div v-else class="input text-gray" @click="showCityModel(true)">请选择收货地址</div>
			</div>
			<div class="input-con no-border">
				<div class="lebal">详细地址</div>
				<input class="input" v-model="address_detail" type="text" placeholder="请填写详细地址">
			</div>
			<div class="input-con no-border default-con">
				<div class="lebal" style="width: auto;">设为默认地址</div>
				<van-switch v-model="is_default" active-color="#FF6685" inactive-color="#ddd" size="20" />
			</div>
		</div>
		
		
		<div class="add-btn" @click="addAddress">+新增地址</div>
		
		<city-select :show="isShowCity" @cancel="showCityModel(false)" @change="chooseArea"></city-select>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	import citySelect from "@/components/citySelect.vue"
	export default {
		data() {
			return {
				receive_name:"",
				receive_phone:"",
				address_area:"",
				address_detail:"",
				is_default:false,
				isShowCity:false,
				province_id:0,
				city_id:0,
				area_id:0,
			}
		},
		components:{
			citySelect,
			navbar
		},
		methods: {
			addAddress() {
				if(!this.receive_name){
					this.$toast("请填写收货人")
					return
				}
				if(!this.receive_phone){
					this.$toast("请填写手机号码")
					return
				}
				if(!this.address_area){
					this.$toast("请选择收货地址")
					return
				}
				if(!this.address_detail){
					this.$toast("请填写详细地址")
					return
				}
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					act: "create",
					province_id: this.province_id,
					city_id: this.city_id,
					area_id: this.area_id,
					address: this.address_detail,
					receive_name: this.receive_name,
					receive_phone: this.receive_phone,
					is_default: this.is_default
				}
				console.log("保存地址请求参数====",sentData);
				let params = this.$addParams(sentData)
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/actaddr",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						//console.log(res.data)
						if (res.data.status == "n") {
							this.$toast(res.data.error)
							return
						}
						this.$toast("添加成功")
						setTimeout(()=>{
							this.$router.back(-1);
						},1200)
					});
			},
			showCityModel(flag){
				this.isShowCity = flag
			},
			chooseArea(e){
				console.log("我选择的地址是======",e)
				let province = e.province.name
				let city = e.city.name
				let area = e.area?e.area.name:''
				
				this.address_area = province+' '+city+' '+area
				this.address_area = this.address_area.trim()
				
				this.province_id = e.province.id
				this.city_id = e.city.id
				this.area_id = e.area?e.area.id:0
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper {
		background-color: #f5f5f5;
	}

	.input-con {
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: 110px;
		padding: 0 24px;
		font-size: 30px;
		border-bottom: 1px solid #ddd;
		background-color: #fff;

		.lebal {
			width: 160px;
		}

		.input {
			flex: 1;
		}
		
		.province{
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	
	.add-btn{
		width: 400px;
		line-height: 90px;
		border-radius: 45px;
		text-align: center;
		color: #fff;
		background-color: #000;
		font-size: 30px;
		position: fixed;
		bottom: 100px;
		left: 50%;
		transform: translateX(-50%);
		box-shadow: 0 0 15px 1px rgba(0,0,0,0.1);
	}

	.no-border {
		border: 0;
	}

	.default-con {
		margin-top: 20px;
	}

	.van-switch {
		font-size: 40px !important;
	}

	.text-gray {
		color: #ddd;
	}

	::-webkit-input-placeholder {
		/* WebKit, Blink, Edge */
		color: #ddd;
	}

	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #ddd;
	}

	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #ddd;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #ddd;
	}
</style>
