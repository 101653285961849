<template>
	<div class="container-wrapper">
		<navbar title="上传买家秀"></navbar>
		<div class="top-wrapper">
			<div class="top-container">
				<div class="top-title">上传买家秀</div>
				<div class="top-tip1">要求：每个买家秀相册请上传5张以上展示单一产品的照片</div>
				<div class="top-tip2">您上传的买家秀作品审核通过后将展示给平台商家，上传更多优质的买家秀将有利于提高被派单的机会！</div>
			</div>

			<div class="mjx-title-con">
				<div class="mjx-title">买家秀标题</div>
				<input class="mjx-title-input" type="text" v-model="mjx_title" placeholder="例:春款碎花连衣裙 纯棉T恤套装"></input>
			</div>
		</div>
		<div class="upload-container">
			<van-uploader :before-read="chooseImages" multiple>
				<div class="upload-item-con">
					<div class="upload-item upload-btn">
						<img class="upload-icon"
							src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/edit_album/upload_image%403x.png"></img>
						<div>上传图片</div>
					</div>
				</div>
			</van-uploader>
			<div class="upload-item-con" v-for="(item,index) in images" :key="index">
				<div class="upload-item">
					<img class="upload-image" :src="item.url"></img>
					<div class="image_mask" v-if="item.status=='fail'" @click="showImageDetail(index)">
						<div class="upload-status">上传失败</div>
					</div>
					<i class="iconfont icon-close-bold" @click.stop="deleteImage(index)"></i>
				</div>
			</div>
		</div>

		<div class="upload-container">
			<van-uploader :before-read="chooseVideos" accept="video/*">
				<div class="upload-item-con">
					<div class="upload-item upload-btn">
						<img class="upload-icon"
							src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/edit_album/upload_cao%403x.png"></img>
						<div>上传视频</div>
					</div>
				</div>
			</van-uploader>
			<div class="upload-item-con" v-if="video">
				<div class="upload-item">
					<video class="upload-video" :src="video"></video>
					<div class="video_mask" @click="showVideoDetail">
						<img class="play_icon" src="/pages/resource/images/play.png"></img>
					</div>
					<i class="iconfont icon-close-bold" @click.stop="deleteVideo"></i>
				</div>
			</div>
		</div>

		<div class="bottom_btn" @click="createMjxAlbum">保存</div>
	</div>
</template>

<script>
	import {
		uploadFile
	} from "../../../util/uploadBaogao.js"
	import navbar from "@/components/navbar.vue"
	import {
		ImagePreview
	} from 'vant';
	export default {
		data() {
			return {
				images: [],
				video: null,
				mjx_title: "",
				flag: false,
				previewImages: [],
				previewCurrent: 0,
				showPreview: false
			}
		},
		components: {
			navbar
		},
		methods: {
			chooseImages(files) {
				console.log("选择照片")
				let file = [];
				if (!Array.isArray(files)) {
					file.push(files)
				} else {
					file = files;
				}
				if(file.length){
					this.$toast.loading({
						message: "上传中",
						duration: 0
					});
				}
				for (let i = 0; i < file.length; i++) {
					console.log(file[i])
					uploadFile(file[i], "user/img/mjx_image").then(res => {
						console.log("上传结果====", res)
						this.images.push({
							status: res.status,
							url: res.status == "fail" ? this.getFileURL(res.url) : res.url
						});
						if(this.images.length >= file.length){
							this.$toast.clear();
						}
					})
				}
			},
			//删除图片
			deleteImage(index) {
				this.images.splice(index, 1);
			},
			//选择视频
			chooseVideos(file) {
				this.$toast.loading({
					message: '上传视频中',
					forbidClick: true,
					duration:0
				})
				uploadFile(file, "user/img/mjx_video", "mp4").then(res => {
					console.log("视频上传返回结果", res)
					this.$toast.clear()
					if (res.status == "fail") {
						this.$toast("视频上传失败，请重新选择")
					} else {
						console.log("视频上传成功！")
						this.video = res.url
					}
				})
			},
			//删除视频
			deleteVideo() {
				this.video = null
			},

			//预览照片
			showImageDetail(index) {
				let previewImages = this.images.map(item => item.url)
				ImagePreview({
					images: previewImages,
					startPosition: index
				})
			},

			//展示视频
			showVideoDetail() {
				console.log("预览视频")
			},

			//创建买家秀相册
			createMjxAlbum() {
				if (!this.mjx_title) {
					this.$toast('请输入买家秀标题')
					return
				}
				if (this.images.length == 0) {
					this.$toast('请选择照片')
					return
				}
				if (this.images.length < 5) {
					this.$toast('每个买家秀相册至少上传5张照片')
					return
				}
				for (let i = 0; i < this.images.length; i++) {
					if (this.images[i].status == "fail") {
						this.$toast('有未上传成功的图片，请重新上传或者删除后提交')
						return
					}
				}
				if (this.flag) {
					return
				}
				this.flag = true
				let photos = this.images.map(item => item.url)
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					photos: photos.join(';'),
					title: this.mjx_title
				}
				console.log("创建买家秀相册请求参数", sentData)
				let params = this.$addParams(sentData)
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/createmjx",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							this.$toast('已提交审核')
							setTimeout(() => {
								this.$router.back(-1);
							}, 1200)
						} else {
							this.flag = false
							this.$toast(res.data.error)
						}
					});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper {
		background-color: #fff;
	}

	.top-wrapper {
		padding: 0 24px 20px;
	}

	.top-container {
		padding: 20px 0 10px;
		font-size: 24px;
	}

	.top-title {
		font-size: 32px;
		line-height: 50px;
	}

	.top-tip1 {
		color: #FF6685;
		line-height: 40px;
	}

	.top-tip2 {
		color: #888;
		line-height: 32px;
	}

	.mjx-title {
		line-height: 60px;
	}

	.mjx-title-con .mjx-title-input {
		height: 70px;
		border-radius: 10px;
		padding: 0 15px;
		border: 1px solid #ddd;
		box-sizing: border-box;
		width: 100%;
	}

	.upload-container {
		padding: 0 18px;
		display: flex;
		flex-wrap: wrap;
	}

	.upload-item-con {
		width: 178.5px;
		height: 178.5px;
		padding: 6px;
		box-sizing: border-box;
	}

	.upload-item {
		width: 100%;
		height: 100%;
		border-radius: 5px;
		overflow: hidden;
		position: relative;
	}

	.upload-btn {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: #f1f1f1;
		color: #aaa;
		font-size: 24px;
	}

	.upload-icon {
		width: 40px;
		height: 40px;
		display: block;
		margin-bottom: 10px;
	}


	.upload-image,
	.upload-video {
		width: 100%;
		height: 100%;
		display: block;
	}

	.upload-image {
		object-fit: cover;
	}

	.video_mask {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.image_mask {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: rgba(0, 0, 0, 0.5);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.upload-status {
		color: #fff;
		font-size: 20px;
		line-height: 36px;
		border-radius: 18px;
		padding: 0 20px;
		border: 1px solid #fff;
	}

	.play_icon {
		width: 40px;
		height: auto;
		display: block;
	}

	.icon-close-bold {
		position: absolute;
		right: 0;
		top: 0;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		background-color: rgba(0, 0, 0, 0.8);
		color: #fff;
		border-radius: 0 5px 0 5px;
		font-size: 24px;
	}


	.bottom_btn {
		position: fixed;
		bottom: 150px;
		left: 50%;
		text-align: center;
		width: 350px;
		transform: translateX(-50%);
		background-color: #000;
		color: #fff;
		font-size: 30px;
		line-height: 80px;
		border-radius: 40px;
	}
</style>
