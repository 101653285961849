<template>
	<div class="container-wrapper">
		<back-top-fixed></back-top-fixed>
		<div class="top-icon-con">
			<img v-if="level_detail.level==1" class="vip-icon" src="https://img.jimeimadou.com/user/img/vip_images/vip02.png"
				mode="aspectFit"></img>
			<img v-if="level_detail.level==2" class="vip-icon" src="https://img.jimeimadou.com/user/img/vip_images/vip01.png"
				mode="aspectFit"></img>
			<img v-if="level_detail.level==3" class="vip-icon" src="https://img.jimeimadou.com/user/img/vip_images/vip03.png"
				mode="aspectFit"></img>
			<img v-if="level_detail.level==4" class="vip-icon" src="https://img.jimeimadou.com/user/img/vip_images/vip04.png"
				mode="aspectFit"></img>
			<div class="vip-down-con">
				<img class="vip-down" src="https://img.jimeimadou.com/user/img/vip_images/vip.png" mode="widthFix">
				</img>
				<div v-if="level_detail.level==1" class="vip-type">新手麻豆</div>
				<div v-if="level_detail.level==2" class="vip-type">初级麻豆</div>
				<div v-if="level_detail.level==3" class="vip-type">专业麻豆</div>
				<div v-if="level_detail.level==4" class="vip-type">优质麻豆</div>
			</div>
			<div class="vip-content">{{level_detail.level_desc}}</div>
		</div>
		<div class="vip-step-container">
			<div>
				<div class="type-title">
					<div class="left-con">
						<img src="https://img.jimeimadou.com/user/img/vip_images/level01.png" mode="aspectFit">
						</img>
					</div>
					<div>新手麻豆</div>
				</div>
				<div class="container-item">
					<div class="left-con">
						<div class="left-line"></div>
					</div>
					<div class="right-con">
						<div class="mission-con">
							<div class="mission-title">
								<div class="title-tag1">权限</div>
							</div>
							<div class="mission-detail">
								<div class="detail-left">
									<div class="dot-item">
										<i class="iconfont icon-yuandian"></i>
										<div>可同时进行<span class="data-num">{{level_detail.new_con_role_dan_num}}</span>单赠拍任务；</div>
									</div>
									<div class="dot-item">
										<i class="iconfont icon-yuandian"></i>
										<div>可报名<span class="data-num">{{level_detail.new_con_role_price}}</span>元以下产品价值任务。</div>
									</div>
								</div>
							</div>
						</div>
						<div class="mission-con">
							<div class="mission-title">
								<div class="title-tag2">任务</div>
							</div>
							<div class="mission-detail">
								<div class="detail-left">
									<div>完成<span class="data-num">{{level_detail.new_con_task_1_dan_num}}</span>单 + 邀请<span class="data-num">{{level_detail.new_con_task_1_invite_num}}</span>位麻豆</div>
									<div>或完成<span class="data-num">{{level_detail.new_con_task_finish_num}}</span>单任务</div>
								</div>
								<img v-if="level_detail.new_con_check==0" class="gou"
									src="https://img.jimeimadou.com/common/icon/gou01.png" mode="widthFix"></img>
								<img v-if="level_detail.new_con_check==1" class="gou"
									src="https://img.jimeimadou.com/common/icon/gou02.png" mode="widthFix"></img>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="type-title">
					<div class="left-con">
						<img v-if="level_detail.level>=2" src="https://img.jimeimadou.com/user/img/vip_images/level02.png"
							mode="aspectFit"></img>
						<img v-else src="https://img.jimeimadou.com/user/img/vip_images/no_vip02.png"
							mode="aspectFit"></img>
					</div>
					<div :style="level_detail.level>=2?'':'color:#8A8E99'">初级麻豆</div>
				</div>
				<div class="container-item">
					<div class="left-con">
						<div class="left-line" :style="level_detail.level>=2?'':'background-color:#E0E0E0'"></div>
					</div>
					<div class="right-con" :style="level_detail.level>=2?'':'color:#8A8E99'">
						<div class="mission-con">
							<div class="mission-title">
								<div class="title-tag1">权限</div>
							</div>
							<div class="mission-detail">
								<div class="detail-left">
									<div class="dot-item">
										<i class="iconfont icon-yuandian"></i>
										<div>可同时进行<span class="data-num">{{level_detail.junior_con_role_dan_num}}</span>单赠拍任务；</div>
									</div>
									<div class="dot-item">
										<i class="iconfont icon-yuandian"></i>
										<div>可报名佣金单寄拍任务；</div>
									</div>
									<div class="dot-item">
										<i class="iconfont icon-yuandian"></i>
										<div>可报名<span class="data-num">{{level_detail.junior_con_role_price}}</span>元以下产品价值任务。</div>
									</div>
								</div>
							</div>
						</div>
						<div class="mission-con">
							<div class="mission-title">
								<div class="title-tag2">任务</div>
							</div>
							<div class="mission-detail">
								<div class="detail-left">
									<div>或完成<span class="data-num">{{level_detail.junior_con_task_finish_num}}</span>单任务</div>
								</div>
								<img v-if="level_detail.junior_con_check==0" class="gou"
									src="https://img.jimeimadou.com/common/icon/gou01.png" mode="widthFix"></img>
								<img v-if="level_detail.junior_con_check==1" class="gou"
									src="https://img.jimeimadou.com/common/icon/gou02.png" mode="widthFix"></img>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="type-title">
					<div class="left-con">
						<img v-if="level_detail.level>=3" src="https://img.jimeimadou.com/user/img/vip_images/level03.png"
							mode="aspectFit"></img>
						<img v-else src="https://img.jimeimadou.com/user/img/vip_images/no_vip03.png"
							mode="aspectFit"></img>
					</div>
					<div :style="level_detail.level>=3?'':'color:#8A8E99'">专业麻豆</div>
				</div>
				<div class="container-item">
					<div class="left-con">
						<div class="left-line" :style="level_detail.level>=3?'':'background-color:#E0E0E0'"></div>
					</div>
					<div class="right-con" :style="level_detail.level>=3?'':'color:#8A8E99'">
						<div class="mission-con">
							<div class="mission-title">
								<div class="title-tag1">权限</div>
							</div>
							<div class="mission-detail">
								<div class="detail-left">
									<div class="dot-item">
										<i class="iconfont icon-yuandian"></i>
										<div>可同时进行<span class="data-num">{{level_detail.prefessional_con_role_dan_num}}</span>单任务；</div>
									</div>
									<div class="dot-item">
										<i class="iconfont icon-yuandian"></i>
										<div>可报名佣金单寄拍任务；</div>
									</div>
									<div class="dot-item">
										<i class="iconfont icon-yuandian"></i>
										<div>可报名<span class="data-num">{{level_detail.prefessional_con_role_price}}</span>元以下产品价值任务；</div>
									</div>
									<div class="dot-item">
										<i class="iconfont icon-yuandian"></i>
										<div>报名优先展示给商家。</div>
									</div>
								</div>
							</div>
						</div>
						<div class="mission-con">
							<div class="mission-title">
								<div class="title-tag2">任务</div>
							</div>
							<div class="mission-detail">
								<div class="detail-left">
									<div>完成量在<span class="data-num">{{level_detail.prefessional_con_task_finish_num}}</span>单以上且无失约违规记录</div>
								</div>
								<img v-if="level_detail.prefessional_con_check==0" class="gou"
									src="https://img.jimeimadou.com/common/icon/gou01.png" mode="widthFix"></img>
								<img v-if="level_detail.prefessional_con_check==1" class="gou"
									src="https://img.jimeimadou.com/common/icon/gou02.png" mode="widthFix"></img>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div class="type-title">
					<div class="left-con">
						<img v-if="level_detail.level>=4" src="https://img.jimeimadou.com/user/img/vip_images/level04.png"
							mode="aspectFit"></img>
						<img v-else src="https://img.jimeimadou.com/user/img/vip_images/no_vip04.png"
							mode="aspectFit"></img>
					</div>
					<div :style="level_detail.level>=4?'':'color:#8A8E99'">优质麻豆</div>
					<div v-if="level_detail.high==1" class="last-type">恭喜你！已被评为优质麻豆~</div>
				</div>
				<div class="container-item">
					<div class="left-con">
						<!-- <div class="left-line" :style="level_detail.level>=3?'':'background-color:#E0E0E0'"></div> -->
					</div>
					<div class="right-con" :style="level_detail.level>=3?'':'color:#8A8E99'">
						<div class="mission-con">
							<div class="mission-title">
								<div class="title-tag1">权限</div>
							</div>
							<div class="mission-detail">
								<div class="detail-left">
									<div class="dot-item">
										<i class="iconfont icon-yuandian"></i>
										<div>可同时进行<span class="data-num">{{level_detail.high_con_role_dan_num}}</span>单任务；</div>
									</div>
									<div class="dot-item">
										<i class="iconfont icon-yuandian"></i>
										<div>可报名佣金单寄拍任务；</div>
									</div>
									<div class="dot-item">
										<i class="iconfont icon-yuandian"></i>
										<div>可报名<span class="data-num">{{level_detail.high_con_role_price}}</span>元以下产品价值任务；</div>
									</div>
									<div class="dot-item">
										<i class="iconfont icon-yuandian"></i>
										<div>报名优先展示给商家；</div>
									</div>
									<div class="dot-item">
										<i class="iconfont icon-yuandian"></i>
										<div>平台会推送高品质任务给麻豆。</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bottom-tip">邀请好友，并完成模特认证才有效</div>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	import backTopFixed from "@/components/back-top-fixed.vue"
	export default {
		data() {
			return {
				level_detail:{}
			}
		},
		components: {
			navbar,
			backTopFixed
		},
		activated() {
			this.getLevel()
		},
		methods: {
			getLevel() {
				let sentData = {
					access_token: localStorage.getItem("access_token")
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/level",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						//console.log(res.data)
						if (res.data.status == "n") {
							return
						}
						let result = JSON.parse(res.data.data)
						console.log(result)
						this.level_detail = result
					});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper {
		background-color: #f5f5f5;
		background-repeat: no-repeat;
		background-size: 100%;
		background-image: url(https://img.jimeimadou.com/user/img/vip_images/vip_bg.png);
		position: relative;
		overflow-y: auto;
	}

	.bg {
		background-repeat: no-repeat;
		background-size: 100%;
	}

	img {
		display: block;
		height: auto;
	}

	.top-icon-con {
		padding-top: 130px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.vip-icon {
		width: 178px;
		display: block;
		object-fit: content;
	}

	.vip-down-con {
		position: relative;
		margin-top: -30px;
	}

	.vip-down {
		width: 246px;
	}

	.vip-type {
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		font-size: 30px;
		font-weight: 700;
	}

	.vip-content {
		font-size: 26px;
		margin-top: 10px;
	}

	.vip-step-container {
		padding: 100px 20px;
	}

	.type-title {
		height: 80px;
		font-size: 30px;
		font-weight: 700;
		color: #262A33;
		display: flex;
		align-items: center;
	}

	.item-title {
		color: #FF6685;
		font-weight: 700;
	}

	.type-title img {
		width: 50px;
		height: 54px;
		display: block;
	}

	.container-item {
		display: flex;
	}

	.left-con {
		width: 70px;
		display: flex;
		justify-content: center;
	}

	.right-con {
		flex: 1;
	}

	.right-con .gou {
		width: 26px;
	}

	.left-line {
		width: 8px;
		border-radius: 4px;
		background-color: #FF6685;
	}

	.last-type {
		margin-left: 20px;
		color: #FF6685;
		font-size: 26px;
	}

	.bottom-tip {
		position: fixed;
		bottom: 0;
		width: 100%;
		line-height: 80px;
		background-color: #E1E4EB;
		text-align: center;
		font-size: 30px;
		color: #333;
	}


	.mission-con {
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		margin-right: 20px;
		line-height: 40px;
		color: #000;
		box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.03);
		margin-bottom: 15px;
	}

	.mission-title {
		display: flex;
		margin-bottom: 20px;
	}

	.title-tag1 {
		border-radius: 0 20px 20px 0;
		background-image: linear-gradient(to right, rgba(252, 231, 233, 0.1), rgba(252, 231, 233, 1));
		color: #FF6685;
		font-weight: 700;
		line-height: 40px;
		padding: 0 15px;
		font-size: 30px;
	}
	
	.title-tag2 {
		border-radius: 0 20px 20px 0;
		background-image: linear-gradient(to right, rgba(231,236,255, 0.1), rgba(231,236,255, 1));
		color: #728FED;
		font-weight: 700;
		line-height: 40px;
		padding: 0 15px;
		font-size: 30px;
	}
	
	.mission-detail{
		display: flex;
		align-items: center;
		line-height: 50px;
		font-size: 26px;
	}
	
	.detail-left{
		flex: 1;
		overflow: hidden;
	}
	.icon-yuandian{
		color: #FF6685;
		font-size: 20px;
	}
	.dot-item{
		display: flex;
	}
	.data-num{
		padding: 0 5px;
		font-weight: 700;
	}
	
</style>
