<template>
	<div class="container-wrapper">
		<navbar title="上传种草视频"></navbar>
		<div class="top-container">
			<div class="top-title-con">
				上传种草视频
				<span class="title-tip" v-if="totalNum==0">(首次上传最少5个)</span>
				<span class="title-tip" v-if="totalNum>0">（已上传{{video_path_arr.length}}个）</span>
			</div>
			<div class="title-tip2">
				<div class="tip-require">要求：请上传带有产品展示或者介绍的种草视频作品，报名种草通告时会展示给商家。</div>
				<div>请上传种草口播视频或自我介绍口播视频，审核通过后将被展示给平台商家，上传更多优质种草口播视频将有利于提高被派单的机会！</div>
				<div>点击上传视频，完善标题，选择种草类型和视频后提交</div>
			</div>
		</div>
		<div class="upload-container">
			<div class="upload-item upload-item-first" @click="upload_action">
				<img class="upload-image"
					src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/edit_album/upload_cao%403x.png"
					mode="widthFix"></img>
				<div class="upload-text">上传视频</div>
			</div>
			<div class="upload-item" v-for="(item,index) in video_path_arr" :key="index">
				<video class="upload-video-item" :src="item.video"></video>
				<div class="video-title-con" @click="edit_action(index)">
					<div class="video-title">{{item.title}}</div>
					<div class="edit-con">
						<i class="iconfont icon-bianji1"></i>
					</div>
				</div>
				<i class="iconfont icon-close-bold" @click="del_video(index)"></i>
			</div>
		</div>


		<div class="save-btn" @click="sure_confirm">保存</div>

		<van-popup class="vant_popup_box" v-model="isShowModel">
			<div class="model-con">
				<div class="model-title">请输入视频标题</div>
				<div class="model-content">
					<textarea class="model-textarea" v-model="content" placeholder="例:春款碎花连衣裙 纯棉T恤套装"></textarea>
					<div class="type-container">
						<div class="type-title">选择种草类型</div>
						<div class="type-item-con">
							<div class="type-item" :class="currentType==item.type?'current':''"
								v-for="(item,index) in typeList" :key="index" @click="chooseVideoType(item.type)">
								{{item.text}}
							</div>
						</div>
					</div>
					<div class="video-container">
						<div class="type-title">选择视频</div>
						<div class="videos-con">
							
							<van-uploader :before-read="chooseVideo" accept="video/*">
								<div class="viedo-item">
									<i class="iconfont icon-upload"></i>
									<div>{{videoUrl?'重新选择':'上传视频'}}</div>
								</div>
							</van-uploader>
							<div class="video-wrap" v-if="videoUrl" @click="toVideoDetail(videoUrl)">
								<video class="viedo-item" :src="videoUrl"></video>
								<img src="../../../../public/static/play.png" />
							</div>
						</div>
					</div>
				</div>
				<div class="model-btn-con">
					<div class="model-btn-item model-btn-item-l" @click="hideModel">取消</div>
					<div class="model-btn-item model-btn-item-r" @click="setTitle">确定</div>
				</div>
			</div>
		</van-popup>


	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	import {
		uploadFile
	} from "../../../util/uploadBaogao.js"
	export default {
		data() {
			return {
				totalNum: -1,
				video_path_arr: [],
				typeList: [{
					text: "口播介绍",
					type: 1
				}, {
					text: "开箱测评",
					type: 2
				}, {
					text: "时尚穿搭",
					type: 3
				}],
				content: "",
				currentType: null,
				videoUrl: "",
				modelName: "",
				set_index: 0,
				is_edit: false,
				fromPage: null,
				flag: false,
				isShowModel: false
			}
		},
		components: {
			navbar
		},
		activated() {
			this.getTotalCount()
		},
		deactivated() {
			this.$toast.clear()
		},
		methods: {
			getTotalCount() {
				let sentData = {
					access_token: localStorage.getItem("access_token")
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/index",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "n") {
							return
						}
						let result = JSON.parse(res.data.data)
						this.totalNum = result.zc_total_num
					});
			},
			upload_action() {
				this.is_edit = false
				this.content = ""
				this.currentType = null
				this.videoUrl = ""
				this.isShowModel = true
			},
			edit_action(index) {
				this.is_edit = true
				this.set_index = index
				this.content = this.video_path_arr[index].title
				this.currentType = this.video_path_arr[index].type
				this.videoUrl = this.video_path_arr[index].video
				this.isShowModel = true
			},
			chooseVideoType(type) {
				this.currentType = type
			},
			//隐藏弹框
			hideModel() {
				this.isShowModel = false
			},
			setTitle() {
				if (!this.content) {
					this.$toast("请输入视频标题")
					return
				}
				if (!this.currentType) {
					this.$toast("请选择种草类型")
					return
				}
				if (!this.videoUrl) {
					this.$toast("请选择种草视频")
					return
				}
				let videoItem = {
					title: this.content,
					video: this.videoUrl,
					type: this.currentType
				}
				if (this.is_edit) {
					//修改已上传的
					this.video_path_arr[this.set_index] = videoItem
				} else {
					//上传新的
					this.video_path_arr.push(videoItem)
				}
				this.hideModel()
			},
			toVideoDetail(url) {
				
			},
			//选择种草视频
			chooseVideo(file) {
				this.$toast.loading({
					message: '上传视频中',
					forbidClick: true,
					duration:0
				})
				uploadFile(file, "user/video/zhongcao", "mp4").then(res => {
					console.log("视频上传返回结果", res)
					this.$toast.clear()
					if (res.status == "fail") {
						this.$toast("视频上传失败，请重新选择")
					} else {
						console.log("视频上传成功！")
						this.$toast("上传成功")
						this.videoUrl = res.url
					}
				})
			},

			del_video(index) {
				this.video_path_arr.splice(index, 1);
			},

			sure_confirm() {
				if (this.totalNum == 0 && this.video_path_arr.length < 5) {
					this.$toast("首次上传至少5个视频")
					return
				} else if (this.video_path_arr.length == 0) {
					this.$toast("请至少选择1个视频")
					return
				}
				for (let i = 0; i < this.video_path_arr.length; i++) {
					if (!this.video_path_arr[i].title) {
						this.$toast("请输入第" + (i + 1) + "个视频的标题")
						return
					}
				}
				if (this.flag) {
					return
				}
				this.flag = true
				this.ht_create_album()
			},

			/**
			 * 发送请求
			 */
			async ht_create_album() {
				this.$toast({
					 message: '创建中'
				})
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					videos_json: JSON.stringify(this.video_path_arr)
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/createzhongcaov2",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							this.$toast("创建成功，已提交审核")
							setTimeout(() => {
								this.$router.back(-1);
							}, 1200)
						} else if (res.data.status == 'n') {
							this.flag = false
							this.$toast(res.data.error)
						}
					});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper {
		background-color: #fff;
		overflow-y: auto;
	}

	.top-container {
		padding: 20px 24px;

		.top-title-con {
			line-height: 50px;
			font-size: 34px;

			.title-tip {
				font-size: 24px;
				color: #8A8E99
			}
		}

		.title-tip2 {
			font-size: 24px;
			color: #aaa;
			line-height: 32px;
		}

		.tip-require {
			margin-bottom: 8px;
			color: #FF6685;
		}
	}



	.save-btn {
		height: 90px;
		line-height: 90px;
		border-radius: 15px;
		background: #1A1F29;
		color: #fff;
		font-size: 32px;
		position: fixed;
		bottom: 30px;
		left: 24px;
		width: 702px;
		text-align: center;
		box-shadow: 0 10px 15px 1px rgba(0, 0, 0, 0.3);
		z-index: 100;
	}

	.video_item {
		width: 168px;
		height: 168px;
		border-radius: 6px;
		overflow: hidden;
		position: relative;
		background: #333;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 5px;
		margin-left: 10px;
	}

	.video_play {
		height: 100%;
		width: 100%;
		background: rgba(255, 255, 255, 0);
	}

	.video-title-con {
		position: absolute;
		bottom: 0;
		line-height: 50px;
		height: 50px;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.4);
		color: #fff;
		font-size: 28px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 10px;
	}

	.video-title-con .video-title {
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.edit-con {
		background-color: #FF6685;
		font-size: 20px;
		color: #fff;
		height: 40px;
		line-height: 40px;
		width: 40px;
		text-align: center;
		border-radius: 50%;

		.icon-bianji1 {
			font-size: 24px;
		}
	}


	.upload-item .icon-close-bold {
		position: absolute;
		right: 0;
		top: 0;
		line-height: 40px;
		width: 40px;
		text-align: center;
		color: #fff;
		font-size: 26px;
		background-color: rgba(0, 0, 0, 0.8);
		border-radius: 0 10px 0 10px;
	}

	.upload-container {
		display: flex;
		flex-wrap: wrap;
		padding: 0 24px 180px;
	}

	.upload-container .upload-item {
		width: 224px;
		height: 224px;
		margin-right: 15px;
		margin-bottom: 15px;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		overflow: hidden;
		position: relative;
	}

	.upload-container .upload-item-first {
		background-color: #F1F1F1;
	}

	.upload-item-first .upload-image {
		width: 60px;
	}

	.upload-text {
		margin-top: 10px;
		font-size: 28px;
		color: #aaa;
	}

	.upload-item:nth-child(3n) {
		margin-right: 0;
	}

	.upload-item .upload-video-item {
		width: 100%;
		height: 100%;
		display: block;
		border-radius: 10px;
		object-fit: cover;
	}
	
	.vant_popup_box{
		border-radius: 15px;
	}
	
	.model-con {
		width: 600px;
		padding: 40px 50px;
		text-align: center;
		.model-title {
			font-weight: 700;
			font-size: 32px;
			color: #000;
			line-height: 60px;
		}
		
		.model-content {
			padding: 20px 0 30px;
			font-size: 30px;
			color: #000;
			text-align: center;
		}
		
		.model-textarea {
			width: 100%;
			height: 120px;
			background-color: #F5F5F5;
			border-radius: 6px;
			padding: 20px;
			box-sizing: border-box;
			text-align: left;
			line-height: 40px;
			border: none;
		}
		::-webkit-input-placeholder {
			/* WebKit, Blink, Edge */
			color: #aaa;
		}
		
		:-moz-placeholder {
			/* Mozilla Firefox 4 to 18 */
			color: #aaa;
		}
		
		::-moz-placeholder {
			/* Mozilla Firefox 19+ */
			color: #aaa;
		}
		
		:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			color: #aaa;
		}
		
		.type-title {
			text-align: left;
			font-size: 24px;
			color: #aaa;
			line-height: 60px;
		}
		.type-item-con{
			display: flex;
			justify-content: space-between;
		}
		
		.type-item {
			width: 150px;
			text-align: center;
			line-height: 60px;
			border-radius: 10px;
			border: 1px solid #ccc;
			color: #ccc;
			font-size: 28px;
		}
		
		.type-container .current {
			color: #FF6685;
			border-color: #FF6685;
			background-color: #FFEBEF;
		}
		.videos-con{
			display: flex;
		}

		.video-wrap {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				position: absolute;
				width: 40px;
			}
		}

		.viedo-item {
			width: 160px;
			height: 160px;
			border-radius: 6px;
			margin-right: 10px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: #AAA;
			font-size: 24px;
			background-color: #F5F5F5;
			object-fit: contain;
		}

		.icon-upload {
			font-size: 40px;
			margin-bottom: 6px;
		}
		
		.model-btn-con{
			display: flex;
			justify-content: space-between;
			text-align: center;
		}
		
		.model-btn-item{
			width: 230px;
			line-height: 70px;
			border-radius: 35px;
			border: 1px solid #ddd;
			color: #aaa;
			font-size: 30px;
		}
		
		.model-btn-item-r {
			background-color: #000000;
			color: #fff;
			border-color: #000000 !important;
		}
	}



	
</style>
