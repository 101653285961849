<template>
	<div class="page-container">
		<template v-if="isLogin">
			<div class="set-con">
				<div class="iconfont icon-shezhi" @click="toSettings"></div>
			</div>
			<div class="avatar-container">
				<img class="user-avatar" :src="userInfo.head_ico" @click="toUserInfo"></img>
				<div class="user-info">
					<div class="user-nick-con">
						<div class="user-nick">{{userInfo.nick_name}}</div>
						<div class="level-con" @click="toLevel">
							<img class="level-icon" :src="levels[userInfo.level].icon"></img>
							<div>{{levels[userInfo.level].text}}</div>
						</div>
					</div>
					<div class="user-id">ID：{{userInfo.user_id}}</div>
					<div class="user-sign">{{userInfo.autograph}}</div>
				</div>
				<div class="right-btn-con" v-if="authSuccess">
					<div class="right-btn" @click="toMtDetail">个人相册</div>
					<div class="right-btn" @click="showModel('serviceModel')">专属客服</div>
				</div>
			</div>
			<div class="userinfo-bottom">
				<div class="num-item">关注数：{{userInfo.attention_num}}</div>
				<div class="num-item">接单数：{{userInfo.send_order_num}}</div>
				<div class="num-item" @click="toInform">开启通知 <i class="iconfont icon-you"></i></div>
			</div>
			<div class="money-container">
				<div class="money-wrapper">
					<div class="money-item" @click="toBalance">
						<div class="money-data">{{handleNumber(userInfo.remain_amount)}}<span class="span">￥</span>
						</div>
						<div class="money-title">
							<div>账户余额</div>
							<i class="iconfont icon-you"></i>
						</div>
					</div>
					<div class="money-item" @click="toSendGoodsDetail">
						<div class="money-data">{{handleNumber(userInfo.gift_amount)}}<span class="span">￥</span></div>
						<div class="money-title">
							<div>获得赠品</div>
							<i class="iconfont icon-you"></i>
						</div>
					</div>
					<div class="money-item" @click="toCommission">
						<div class="money-data">{{handleNumber(userInfo.task_amount)}}<span class="span">￥</span></div>
						<div class="money-title">
							<div>获得佣金</div>
							<i class="iconfont icon-you"></i>
						</div>
					</div>
				</div>
				<div class="invite-btn-con" v-if="userInfo.mt_auth_status==2||userInfo.mt_auth_status==5">
					<div class="invite-setting">
						<div class="invite-left">
							<div>可被邀请</div>
							<i class="iconfont icon-questionfill"></i>
						</div>
						<div>
							<van-switch v-model="canInvite" active-color="#FF6685" size="20"
								@change="showModel('inviteModel')" />
						</div>
					</div>
				</div>
			</div>
			<div class="invite-container">
				<div class="invite-wrapper">
					<div class="invite-item" @click="toInviteDetail('user')">
						<div class="invite-data">{{userInfo.invite_user}}<span class="span">人</span>
						</div>
						<div class="invite-title">
							<div>邀请模特</div>
							<i class="iconfont icon-you"></i>
						</div>
					</div>
					<div class="invite-item" @click="toInviteDetail('seller')">
						<div class="invite-data">{{userInfo.invite_seller}}<span class="span">人</span></div>
						<div class="invite-title">
							<div>邀请商家</div>
							<i class="iconfont icon-you"></i>
						</div>
					</div>
					<div class="invite-item" @click="toInviteDetail('money')">
						<div class="invite-data">{{handleNumber(userInfo.invite_amount)}}<span class="span">￥</span>
						</div>
						<div class="invite-title">
							<div>邀请总佣金</div>
							<i class="iconfont icon-you"></i>
						</div>
					</div>
				</div>
				<div class="invite-img-con">
					<img class="invite-img" @click="toInvite" src="https://img.jimeimadou.com/common/images/invite.gif">
				</div>
			</div>
		</template>
		<template v-else>
			<div class="avatar-container" @click="toLogin">
				<img class="user-avatar"
					src="https://img.jimeimadou.com/common/resource/mine_icon/mine_wd_icon.png"></img>
				<div class="login-text">点击登录</div>
			</div>
			<img class="invite-ad" @click="toInvite" src="https://img.jimeimadou.com/common/resource/mine_icon/mine_gg_icon.png"></img>
		</template>
		<div class="options-container" v-if="options.length!=0">
			<div class="options-item" :style="'width:'+(authSuccess?'25%':'33.333%')" v-for="(item,index) in options"
				:key="item.type" @click="toPage(item.type)">
				<div class="img-con">
					<img class="options-img" :src="item.icon">
					<template v-if="item.type==1">
						<div class="auth-status fail-auth" v-if="!authStatus[userInfo.mt_auth_status]">接单必填</div>
						<div class="auth-status"
							:class="authSuccess?'success-auth':'fail-auth'"
							v-else>{{authStatus[userInfo.mt_auth_status]}}</div>
					</template>
					<div class="auth-status" :class="userInfo.daren_status==3?'success-auth':'fail-qudao'"
						v-if="item.type==7&&darenStatus[userInfo.daren_status]">{{darenStatus[userInfo.daren_status]}}
					</div>
					<template v-if="item.type==2">
						<div class="auth-status fail-qudao" v-if="!authSuccess">提升接单率</div>
						<div class="auth-status"
								 :class="userInfo.qudao_status==1?'success-auth':'fail-auth'"
								 v-else-if="qudaoStatus[userInfo.qudao_status]">{{qudaoStatus[userInfo.qudao_status]}}</div>
					</template>
				</div>
				<div>{{item.name}}</div>
			</div>
		</div>
		<div v-if="isLogin" class="login-out" @click="loginOut">退出登录</div>
		<van-popup class="vant_popup_box" v-model="isShowModel">
			<div class="service-con" v-if="modelName=='serviceModel'">
				<div class="model-title">专属客服</div>
				<img class="service-img" :src="code_url" alt="">
				<div class="service-tip">长按图片可保存</div>
			</div>
			<div class="model-invite-btn" v-if="modelName=='inviteModel'">
				<div class="model-title">确认开启“可被邀请”</div>
				<div class="invite-set-text">
					<div>开启后，商家可主动邀请您接单。</div>
					<div>如果没时间，请关闭</div>
				</div>
				<div class="model-btn-con">
					<div class="model-btn-item-l" @click="cancelOpen">取消</div>
					<div class="model-btn-item-r" @click="sureOpen">确认</div>
				</div>
			</div>
			<div class="auth-model-con" v-if="modelName=='authModel'">
				<div class="model-title">温馨提示</div>
				<template v-if="userInfo.mt_auth_status==3||userInfo.mt_auth_status==10">
					<div class="model-content">
						<div>抱歉，您的模特认证未通过。拒绝原因：</div>
						<div class="mt_auth_msg">{{userInfo.mt_auth_msg}}</div>
					</div>
					<div class="model-btn-con">
						<div class="model-btn-item-l" @click="hideModel">取消</div>
						<div class="model-btn-item-r" @click="reAuth">重新认证</div>
					</div>
				</template>
				<template v-if="userInfo.mt_auth_status==7">
					<div class="model-content">非常抱歉,您未符合买家秀要求。您可以邀请其它模特，她每接一单您将分成0.5元！</div>
					<div class="model-btn-con">
						<div class="model-btn-item-l" @click="hideModel">取消</div>
						<div class="model-btn-item-r" @click="toInvite">去推广</div>
					</div>
				</template>
			</div>
		</van-popup>
	</div>
</template>

<script>
export default {
    data() {
        return {
            url: null,
            isLogin: false,
            userInfo: {},
            options: [],
            options1: [
                {
                    icon: "https://img.jimeimadou.com/common/resource/mine_icon/mine_mt_icon.png",
                    name: "模特认证",
                    type: 1,
                },
                {
                    icon: "https://img.jimeimadou.com/common/resource/mine_icon/mine_grsj_icon.png",
                    name: "渠道认证",
                    type: 2,
                },
                {
                    icon: "https://img.jimeimadou.com/common/resource/mine_icon/mine_xhs_icon.png",
                    name: "小红书名片",
                    type: 3,
                },
                {
                    icon: "https://img.jimeimadou.com/common/resource/mine_icon/mine_xc_icon.png",
                    name: "个人相册",
                    type: 4,
                },
                {
                    icon: "https://img.jimeimadou.com/common/resource/mine_icon/mine_jc_icon.png",
                    name: "新手教程",
                    type: 5,
                },
                {
                    icon: "https://img.jimeimadou.com/common/resource/mine_icon/mine_kf_icon.png",
                    name: "联系客服",
                    type: 6,
                },
            ],
            options2: [
                {
                    icon: "https://img.jimeimadou.com/common/resource/mine_icon/mine_mt_icon.png",
                    name: "模特认证",
                    type: 1,
                },
                {
                    icon: "https://img.jimeimadou.com/common/resource/mine_icon/cao_album.png",
                    name: "达人认证",
                    type: 7,
                },
                {
                    icon: "https://img.jimeimadou.com/common/resource/mine_icon/mine_grsj_icon.png",
                    name: "渠道认证",
                    type: 2,
                },
                {
                    icon: "https://img.jimeimadou.com/common/resource/mine_icon/mine_xhs_icon.png",
                    name: "小红书名片",
                    type: 3,
                },
                {
                    icon: "https://img.jimeimadou.com/common/resource/mine_icon/mine_xc_icon.png",
                    name: "买家秀相册",
                    type: 8,
                },
                {
                    icon: "https://img.jimeimadou.com/common/resource/mine_icon/family_album.png",
                    name: "亲情相册",
                    type: 9,
                },
                {
                    icon: "https://img.jimeimadou.com/common/resource/mine_icon/mine_jc_icon.png",
                    name: "新手教程",
                    type: 5,
                },
                {
                    icon: "https://img.jimeimadou.com/common/resource/mine_icon/mine_kf_icon.png",
                    name: "联系客服",
                    type: 6,
                },
            ],
            authStatus: {
                2: "认证通过",
                3: "二审未通过",
                4: "初审中",
                5: "认证通过",
                7: "不符合要求",
                8: "待实名认证",
                9: "二审中",
                10: "初审未通过",
            },
            darenStatus: {
                0: "提升接单率",
                1: "可种草",
                2: "可拍主图",
                3: "已认证",
            },
            qudaoStatus: {
                0: "未认证",
                1: "已认证",
            },
            canInvite: false,
            code_url: "https://img.jimeimadou.com/seller/img/madoukf.jpeg",
            modelName: "",
            isShowModel: false,
            authSuccess: false,
        };
    },
    methods: {
        toUserInfo() {
            if (!this.authSuccess) {
                this.$toast("请先完成模特认证");
                return;
            }
            this.$router.push({
                name: "userInfo",
            });
        },
        toMtDetail() {
            console.log("个人相册");
            this.$router.push({
                name: "modelDetail",
                query: {
                    mt_id: this.userInfo.user_id,
                    fromPage: "mine",
                },
            });
        },
        toSettings() {
            this.$router.push({
                name: "settings",
            });
        },
        toInform() {
            this.$router.push({
                name: "inform",
            });
        },
        toLevel() {
            this.$router.push({
                name: "level",
            });
        },
        toBalance() {
            this.$router.push({
                name: "balance",
            });
        },
        toSendGoodsDetail() {
            this.$router.push({
                name: "sendGoodsDetail",
            });
        },
        toCommission() {
            this.$router.push({
                name: "commission",
            });
        },
        toInviteDetail(type) {
            this.$router.push({
                name: "inviteDetail",
                query: {
                    type,
                },
            });
        },
        loginOut() {
            localStorage.removeItem("access_token");
            this.isLogin = false;
            this.authSuccess = false;
            this.options = this.options1;
            this.userInfo = {};
        },
        toPage(type) {
            if (
                (!localStorage.getItem("access_token") || !this.isLogin) &&
                type != 5 &&
                type != 6
            ) {
                this.toLogin();
                return;
            }
            switch (type) {
                case 1:
                    this.checkMtAuthStatus();
                    break;
                case 2:
                    if (!this.authSuccess) {
                        this.$toast("请先完成模特认证");
                        return;
                    }
                    this.$router.push({
                        name: "channelAuth",
                    });
                    break;
                case 3:
                    this.checkXhsAuthStatus();
                    break;
                case 4:
                    if (!this.authSuccess) {
                        this.$toast("请先完成模特认证");
                        return;
                    }
                    this.toMtDetail();
                    break;
                case 5:
                    this.$router.push({
                        name: "noviceCourse",
                    });
                    break;
                case 6:
                    this.toChat();
                    break;
                case 7:
                    this.$router.push({
                        name: "zhongcaoAlbum",
                    });
                    break;
                case 8:
                    this.$router.push({
                        name: "mjxAlbum",
                    });
                    break;
                case 9:
                    this.$router.push({
                        name: "familyAlbum",
                    });
                    break;
                default:
                    this.$toast(
                        "网页版功能暂未开放，请搜索小程序 灰豚通告plus"
                    );
                    break;
            }
        },
        checkMtAuthStatus() {
            let mt_auth_status = this.userInfo.mt_auth_status;
            if (mt_auth_status == 1 || !mt_auth_status) {
                // this.$toast("网页版功能暂未开放，请搜索小程序 灰豚通告plus")
                // return
                //初审未认证
                this.$router.push({
                    name: "photoAuth",
                });
            } else if (mt_auth_status == 8) {
                // this.$toast("网页版功能暂未开放，请搜索小程序 灰豚通告plus")
                // return
                //初审通过待实名认证
                this.$router.push({
                    name: "infoAuth",
                });
            } else if (mt_auth_status == 4 || mt_auth_status == 9) {
                /*
						mt_auth_status==4 初审待审核
						mt_auth_status==9 二审待审核
						点击按钮跳转等待审核页面
					*/
                this.$router.push({
                    name: "waitAuth",
                    query: {
                        title: "mtAuth",
                    },
                });
            } else if (
                mt_auth_status == 3 ||
                mt_auth_status == 10 ||
                mt_auth_status == 7
            ) {
                /*
						mt_auth_status==3 二审不通过
						mt_auth_status==10 初审不通过
						点击按钮展示不通过原因
						mt_auth_status==7 永久限制（不符合要求）
						点击按钮显示去推广
					*/
                this.modelName = "authModel";
                this.isShowModel = true;
            } else if (mt_auth_status == 2) {
                //认证通过
                console.log("认证通过");
            }
        },
        checkXhsAuthStatus() {
            switch (parseInt(this.userInfo.xhs_auth_state)) {
                case 0:
                    this.$router.push({
                        name: "editCard",
                    });
                    break;
                case 1:
                    this.$router.push({
                        name: "waitAuth",
                        query: {
                            title: "redBookAuth",
                        },
                    });
                    break;
                case 2:
                    this.$router.push({
                        name: "cardMessage",
                    });
                    break;
                case 3:
                    this.$router.push({
                        name: "editCard",
                    });
                    break;
                default:
                    break;
            }
        },
        handleNumber(money) {
            if (!money) {
                return "0.0";
            }
            money /= 100;
            return money.toFixed(1);
        },
        toLogin() {
            this.$router.push({
                name: "login",
            });
        },
        reAuth() {
            console.log("重新认证");
            this.hideModel();
            this.$nextTick(() => {
                if (this.userInfo.mt_auth_status == 3) {
                    this.$router.push({
                        name: "infoAuth",
                    });
                } else if (this.userInfo.mt_auth_status == 10) {
                    this.$router.push({
                        name: "photoAuth",
                    });
                }
            });
        },
        toInvite() {
            if (!localStorage.getItem("access_token") || !this.isLogin) {
                this.toLogin();
                return;
            }
            console.log("去推广");
            this.$router.push({
                name: "invite",
            });
        },
        showModel(name) {
            this.modelName = name;
            this.isShowModel = true;
        },
        hideModel() {
            this.isShowModel = false;
        },
        cancelOpen() {
            this.canInvite = false;
            this.changeInviteStatus();
        },
        sureOpen() {
            this.changeInviteStatus();
        },
        changeInviteStatus() {
            let sentData = {
                access_token: localStorage.getItem("access_token"),
                type: "enable_invited",
                enable_invited: this.canInvite,
            };
            let params = this.$addParams(sentData);
            // console.log(params);
            this.$axios
                .post(
                    process.env.VUE_APP_URL + "/mobi/center/edit",
                    this.$qs.stringify(params),
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                )
                .then((res) => {
                    if (res.data.status == "n") {
                        this.$toast(res.data.error);
                        return;
                    }
                    this.$toast(this.canInvite ? "开启成功" : "已关闭邀请");
                });
            this.hideModel();
        },
        getUserInfo() {
            let sentData = {
                access_token: localStorage.getItem("access_token"),
            };
            let params = this.$addParams(sentData);
            // console.log(params);
            this.$axios
                .post(
                    process.env.VUE_APP_URL + "/mobi/center/index",
                    this.$qs.stringify(params),
                    {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    }
                )
                .then((res) => {
                    //console.log(res.data)
                    if (res.data.status == "n") {
                        this.isLogin = false;
                        this.options = this.options1;
                        // this.$toast(res.data.error)
                        return;
                    }
                    let result = JSON.parse(res.data.data);
                    this.userInfo = result;

                    // 账号状态模拟测试
                    // if (this.userInfo.user_id == 1309 || this.userInfo.user_id == 152767) {
                    // 	this.userInfo.mt_auth_status = 10
                    // }

                    this.isLogin = true;

                    if (
                        this.userInfo.mt_auth_status == 2 ||
                        this.userInfo.mt_auth_status == 5
                    ) {
                        this.authSuccess = true;
                        this.options = this.options2;
                    } else {
                        this.authSuccess = false;
                        this.options = this.options1;
                    }

                    console.log(result);
                    localStorage.setItem(
                        "userInfo",
                        JSON.stringify(this.userInfo)
                    );
                });
        },
        toChat() {
            let nick = "平台客服";
            let account = "admin_1";
            // 更新会话对象
            this.$store.dispatch({
                type: "CurrentChatTo_Change",
                payload: account,
            });
            //获取当前点击的会话对象的聊天信息
            this.getMessages(account);
            this.$router.push({
                name: "chat",
                query: {
                    chatTo: account,
                    nick: nick,
                },
            });
        },
        getMessages(id) {
            if (!this.$store.state.nim) {
                return;
            }
            let self = this;
            this.$store.state.nim.getHistoryMsgs({
                scene: "p2p",
                to: id,
                limit: 10,
                asc: true,
                done: (err, data) => {
                    if (err) {
                        console.log(err);
                        return;
                    }
                    console.log(data);
                    //更新聊天信息列表
                    self.$store.dispatch("SetCurrentChatMessage", data.msgs);
                },
            });
        },
    },
    activated() {
        let token = this.$route.query.access_token;
        if (token) {
            localStorage.setItem("access_token", token);
        }
        this.getUserInfo();
    },
};
</script>

<style lang="scss" scoped>
.page-container {
    overflow-y: auto;
    width: 100vw;
    height: 100vh;
}

.set-con {
    padding: 30px 24px 0;

    .icon-shezhi {
        color: #666;
        font-size: 48px;
    }
}

.avatar-container {
    display: flex;
    padding: 30px 24px;
    justify-content: space-between;
    position: relative;

    .user-avatar {
        width: 140px;
        height: 140px;
        margin-right: 20px;
        border-radius: 50%;
    }

    .login-text {
        font-size: 34px;
        font-weight: 700;
        line-height: 140px;
        flex: 1;
    }

    .user-info {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 28px;
        margin-right: 20px;
        padding-top: 10px;

        .user-nick-con {
            display: flex;
            align-items: center;

            .user-nick {
                font-size: 34px;
                margin-right: 10px;
                max-width: 160px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            .level-con {
                display: flex;
                align-items: center;
                font-size: 22px;
                vertical-align: middle;
                color: #333;
                white-space: nowrap;

                .level-icon {
                    height: 34px;
                    margin-right: 3px;
                    display: block;
                }
            }
        }

        .user-id {
            line-height: 40px;
        }

        .user-sign {
            padding: 5px 10px;
            border-radius: 4px;
            background-color: #e8ebf2;
            line-height: 32px;
            font-size: 22px;
            color: #8a8e99;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            max-width: 360px;
        }
    }

    .right-btn {
        padding: 0 20px;
        border-radius: 30px 0 0 30px;
        line-height: 60px;
        background-color: #ff5874;
        color: #fff;
        font-size: 28px;
        margin-bottom: 10px;
    }
}

.userinfo-bottom {
    display: flex;
    justify-content: space-between;
    margin: 0 24px 15px;
    color: #aaaaaa;
    line-height: 40px;

    .num-item {
        display: flex;
        color: #8a8e99;
    }
}

.invite-ad {
    margin: 0 24px 20px;
    width: 710px;
}

.money-container {
    margin: 0 24px 20px;
    border-radius: 5px;
    background-color: #fff;
    padding: 0 20px;

    .money-wrapper {
        display: flex;
        height: 180px;

        .money-item {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .money-data {
                font-size: 36px;
                font-weight: 700;
                color: #ff6685;

                .span {
                    font-size: 28px;
                }
            }

            .money-title {
                font-size: 22px;
                color: #888;
                display: flex;
                line-height: 40px;

                .icon-you {
                    font-size: 18px;
                }
            }
        }
    }

    .invite-btn-con {
        padding-bottom: 20px;

        .invite-setting {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            background-color: #f5f6fc;
            height: 80px;
            border-radius: 5px;
            color: #666;

            .invite-left {
                display: flex;
                align-items: center;
                font-size: 28px;
                line-height: 40px;

                .icon-questionfill {
                    font-size: 24px;
                    margin-left: 5px;
                    color: #aaa;
                }
            }
        }
    }
}

.invite-container {
    margin: 0 24px 20px;
    border-radius: 5px;
    background-color: #fff;
    padding: 0 20px;

    .invite-wrapper {
        display: flex;
        height: 180px;

        .invite-item {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .invite-data {
                font-size: 36px;
                font-weight: 700;
                color: #555;

                .span {
                    font-size: 24px;
                }
            }

            .invite-title {
                display: flex;
                align-items: center;
                line-height: 40px;
                font-size: 22px;
                color: #888;

                .icon-you {
                    font-size: 18px;
                }
            }
        }
    }

    .invite-img-con {
        padding-bottom: 20px;

        .invite-img {
            width: 100%;
        }
    }
}

.options-container {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    border-radius: 5px;
    margin: 0 24px 20px;
    padding: 30px 0;

    .options-item {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        font-size: 24px;

        .img-con {
            position: relative;
        }

        .options-img {
            height: 42px;
            width: 42px;
            object-fit: contain;
            margin-bottom: 15px;
            display: block;
        }

        .auth-status {
            height: 30px;
            font-size: 20px;
            line-height: 34px;
            color: white;
            border-radius: 15px 15px 15px 0;
            padding: 0 10px;
            white-space: nowrap;
            position: absolute;
            left: 50%;
            top: -10px;
        }

        .success-auth {
            background-color: #45da90;
        }

        .fail-auth {
            background-color: #ff4e33;
        }

        .fail-qudao {
            background-color: #ff6685;
        }
    }
}

.login-out {
    line-height: 80px;
    border-radius: 5px;
    background-color: #fff;
    margin: 0 24px;
    text-align: center;
    font-size: 30px;
    color: #666;
    margin-bottom: 200px;
}

.van-switch {
    font-size: 32px !important;
}

.vant_popup_box {
    border-radius: 15px;

    .model-title {
        line-height: 90px;
        font-size: 36px;
        font-weight: 700;
        text-align: center;
    }

    .model-content {
        font-size: 28px;
        line-height: 36px;
        padding: 20px;
        .mt_auth_msg {
            white-space: pre-wrap;
        }
    }

    .model-btn-con {
        display: flex;
        justify-content: space-around;
        width: 540px;
        padding: 30px 0 20px;

        div {
            width: 200px;
            line-height: 70px;
            border-radius: 35px;
            font-size: 30px;
            text-align: center;
        }

        .model-btn-item-l {
            border: 1px solid #ddd;
            color: #888;
        }

        .model-btn-item-r {
            border: 1px solid #000;
            background-color: #000;
            color: #fff;
        }
    }
}

.service-con {
    padding: 20px 60px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .service-img {
        width: 360px;
    }

    .service-tip {
        line-height: 40px;
        margin-top: 20px;
        font-size: 28px;
        color: #888;
    }
}

.model-invite-btn {
    padding: 20px 40px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .invite-set-text {
        height: 160px;
        width: 540px;
        background: #fff5f8;
        color: #ff6685;
        font-size: 26px;
        line-height: 36px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.auth-model-con {
    padding: 20px 40px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
