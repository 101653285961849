<template>
	<div style="overflow-y: auto;background-color: #f5f5f5;">
		<navbar title="个人中心"></navbar>
		<div>
			<div class="container-item">
				<van-uploader :before-read="changeAvatar">
					<div class="msg-item top-item avatar-con">
						<div class="msg-item-left">点击更换头像</div>
						<div class="msg-item-right">
							<img class="top-img" :src="head_ico">
							<i class="iconfont icon-you"></i>
						</div>
					</div>
				</van-uploader>
				<van-uploader :before-read="changeBgcover">
					<div class="msg-item top-item avatar-con">
						<div class="msg-item-left">点击更换主页背景</div>
						<div class="msg-item-right">
							<img class="top-img" :src="bg_cover">
							<i class="iconfont icon-you"></i>
						</div>
					</div>
				</van-uploader>
				<div class="msg-item top-item" @click="showVantModel('nickModel')">
					<div class="msg-item-left">昵称</div>
					<div class="msg-item-right">
						<div>{{nick_name}}</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
				<div class="msg-item top-item" @click="showVantModel('signModel')">
					<div class="msg-item-left">个人简历</div>
					<div class="msg-item-right">
						<div class="right-text sign-text">{{autograph}}</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
			</div>

			<div class="container-item">
				<div class="msg-item" @click="$toast('模特类型暂不可修改')">
					<div class="msg-item-left">模特类型</div>
					<div class="msg-item-right">
						<div class="right-text">{{mdTypes[select_mt_type]}}</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>

				<div class="msg-item" v-if="select_mt_type==103">
					<div class="msg-item-left">生日</div>
					<div class="msg-item-right">
						<div class="right-text">{{birthday}}</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>

				<div class="msg-item" @click="showVantModel('heightModel')">
					<div class="msg-item-left">身高</div>
					<div class="msg-item-right">
						<div class="right-text">{{height}}</div>
						<div class="unit">cm</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
				<div class="msg-item" @click="showVantModel('weightModel')">
					<div class="msg-item-left">体重</div>
					<div class="msg-item-right">
						<div class="right-text">{{weight}}</div>
						<div class="unit">kg</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
				<div class="msg-item" @click="showModel('sizeModel','coat',select_coat_size)">
					<div class="msg-item-left">上衣</div>
					<div class="msg-item-right">
						<div class="right-text">{{select_coat_size}}</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
				<div class="msg-item" @click="showModel('sizeModel','trousers',select_trousers_size)">
					<div class="msg-item-left">裤子</div>
					<div class="msg-item-right">
						<div class="right-text">{{select_trousers_size}}</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
				<div class="msg-item" @click="showModel('sizeModel','shoes',select_shoes_size)">
					<div class="msg-item-left">鞋子</div>
					<div class="msg-item-right">
						<div class="right-text">{{select_shoes_size}}</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
				<div class="msg-item" v-if="select_mt_type==102" @click="showModel('sizeModel','cup',select_cup_size)">
					<div class="msg-item-left">罩杯</div>
					<div class="msg-item-right">
						<div class="right-text">{{select_cup_size}}</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
			</div>

			<div class="container-item">
				<div class="msg-item" @click="showVantModel('weixinModel')">
					<div class="msg-item-left">微信号</div>
					<div class="msg-item-right">
						<div class="right-text">{{weixin}}</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
				<div class="msg-item" @click="$toast('手机号暂不可修改')">
					<div class="msg-item-left">手机号</div>
					<div class="msg-item-right">
						<div class="right-text">{{phone}}</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
			</div>

			<div class="container-item">
				<div class="msg-item" @click="showModel('cityModel')">
					<div class="msg-item-left">认证地址</div>
					<div class="msg-item-right">
						<div class="right-text">{{address}}</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
			</div>
		</div>

		<div class="save-btn" @click="commitMsg">保存并提交</div>

		<van-popup class="vant_popup_box" v-model="isShowModel">
			<div class="model-con">
				<template v-if="modelName=='nickModel'">
					<div class="model-title">请输入您的昵称</div>
					<textarea class="textarea" v-model="nickname_new"></textarea>
					<div class="model-btn-con">
						<div class="model-btn-item-l" @click="hideModel">取消</div>
						<div class="model-btn-item-r" @click="changeMsg('nick')">确认</div>
					</div>
				</template>
				<template v-if="modelName=='signModel'">
					<div class="model-title">请填写个人简历</div>
					<textarea class="textarea" v-model="autograph_new"></textarea>
					<div class="model-btn-con">
						<div class="model-btn-item-l" @click="hideModel">取消</div>
						<div class="model-btn-item-r" @click="changeMsg('sign')">确认</div>
					</div>
				</template>
				<template v-if="modelName=='heightModel'">
					<div class="model-title">请填写身高</div>
					<div class="model-input-con">
						<input class="model-input" v-model="height_new"></input>
						<div class="input-unit">cm</div>
					</div>
					<div class="model-btn-con">
						<div class="model-btn-item-l" @click="hideModel">取消</div>
						<div class="model-btn-item-r" @click="changeMsg('height')">确认</div>
					</div>
				</template>
				<template v-if="modelName=='weightModel'">
					<div class="model-title">请填写体重</div>
					<div class="model-input-con">
						<input class="model-input" v-model="weight_new"></input>
						<div class="input-unit">kg</div>
					</div>
					<div class="model-btn-con">
						<div class="model-btn-item-l" @click="hideModel">取消</div>
						<div class="model-btn-item-r" @click="changeMsg('weight')">确认</div>
					</div>
				</template>
				<template v-if="modelName=='weixinModel'">
					<div class="model-title">请输入您的微信号</div>
					<textarea class="textarea" v-model="weixin_new"></textarea>
					<div class="model-btn-con">
						<div class="model-btn-item-l" @click="hideModel">取消</div>
						<div class="model-btn-item-r" @click="changeMsg('weixin')">确认</div>
					</div>
				</template>
			</div>
		</van-popup>

		<city-select :show="modelName=='cityModel'" @cancel="hideModel" @change="chooseArea"></city-select>

		<size-model :show="modelName=='sizeModel'" :type="size_type" :value="select_size" :isChild="select_mt_type==103" @cancel="hideModel"
			@confirm="chooseSize"></size-model>

	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	import citySelect from "@/components/citySelect.vue"
	import sizeModel from "@/components/size-model.vue"
	import {
		uploadFile
	} from "../../../util/uploadBaogao.js"
	export default {
		data() {
			return {
				head_ico: "",
				bg_cover: "",
				nick_name: "",
				autograph: "",
				select_mt_type: 0,
				birthday: "",
				height: "",
				weight: "",
				select_coat_size: "",
				select_trousers_size: "",
				select_shoes_size: "",
				select_cup_size: "",
				weixin: "",
				phone: "",
				province_id: 0,
				city_id: 0,
				area_id: 0,
				address: "",
				can_edit_prov: true,
				mdTypes: this.mdTypes,
				nickname_new: "",
				autograph_new: "",
				height_new: "",
				weight_new: "",
				weixin_new: "",
				isShowModel: false,
				modelName: "",
				size_type: "",
				select_size: ""
			}
		},
		components: {
			navbar,
			citySelect,
			sizeModel
		},
		activated() {
			this.getUserInfo()
		},
		methods: {
			changeAvatar(file) {
				console.log(file)
				uploadFile(file, "user/img/head_ico").then(res => {
					console.log("上传结果====", res)
					if (res.status == "success") {
						this.head_ico = res.url
					} else {
						this.$toast("上传失败,请重新选择")
					}
				})
			},
			changeBgcover(file) {
				uploadFile(file, "user/img/bg_image").then(res => {
					console.log("上传结果====", res)
					if (res.status == "success") {
						this.bg_cover = res.url
					} else {
						this.$toast("上传失败,请重新选择")
					}
				})
			},
			getUserInfo() {
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					type: "enable_invited",
					enable_invited: this.canInvite
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/info",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							let result = JSON.parse(res.data.data)
							console.log("个人认证信息===", result)
							this.head_ico = result.head_ico
							this.bg_cover = result.bg_cover ||
								"https://img.jimeimadou.com/common/resource/mine_icon/default_bg01.jpg"
							this.nick_name = result.nick_name
							this.autograph = result.autograph || ""
							this.select_mt_type = result.md_type
							this.birthday = result.birthday || "请选择日期"
							this.height = result.height
							this.weight = result.weight
							this.select_coat_size = result.coat
							this.select_trousers_size = result.trousers
							this.select_shoes_size = result.shoes
							this.select_cup_size = result.cups
							this.weixin = result.weixin
							this.phone = result.phone
							this.address = (result.province_name + " " + result.city_name + " " + result.area_name)
								.trim()
							this.can_edit_prov = result.can_edit_prov
						}
					});
			},
			showVantModel(name) {
				this.modelName = name
				switch (name) {
					case "nickModel":
						this.nickname_new = this.nick_name
						break;
					case "signModel":
						this.autograph_new = this.autograph
						break;
					case "heightModel":
						this.height_new = this.height
						break;
					case "weightModel":
						this.weight_new = this.weight
						break;
					case "weixinModel":
						this.weixin_new = this.weixin
						break;
					default:
						break;
				}
				this.isShowModel = true
			},
			hideModel() {
				this.isShowModel = false
				if (this.modelName == "sizeModel" || this.modelName == "cityModel") {
					this.modelName = ""
				}
			},
			showModel(model_name, size_type, select_size) {
				if (model_name == 'sizeModel') {
					this.size_type = size_type
					this.select_size = select_size
				}
				if (model_name == 'cityModel') {
					if (this.can_edit_prov == 0) {
						this.$toast("认证地址三个月内只可修改一次")
						return
					}
				}
				this.modelName = model_name
			},
			//选择尺码或者模特类型
			chooseSize(e) {
				this.hideModel()
				// console.log(`选中的 ${e.type} 是 ${e.value}`)
				switch (e.type) {
					case "mdType":
						this.select_mt_type = e.value
						this.select_coat_size = ""
						this.select_trousers_size = ""
						this.select_shoes_size = ""
						this.select_cup_size = ""
						break;
					case "coat":
						this.select_coat_size = e.value
						break;
					case "trousers":
						this.select_trousers_size = e.value
						break;
					case "shoes":
						this.select_shoes_size = e.value
						break;
					case "cup":
						this.select_cup_size = e.value
						break;
					default:
						break;
				}
			},
			chooseArea(e) {
				console.log("我选择的地址是======", e)
				let province = e.province.name
				let city = e.city.name
				let area = e.area ? e.area.name : ''

				this.address = province + ' ' + city + ' ' + area
				this.address = this.address.trim()

				this.province_id = e.province.id
				this.city_id = e.city.id
				this.area_id = e.area ? e.area.id : 0
			},
			changeMsg(type) {
				switch (type) {
					case 'nick':
						this.nick_name = this.nickname_new
						break;
					case 'sign':
						this.autograph = this.autograph_new
						break;
					case 'height':
						this.height = this.height_new
						break;
					case 'weight':
						this.weight = this.weight_new
						break;
					case 'weixin':
						this.weixin = this.weixin_new
						break;
					default:
						break;
				}
				this.hideModel()
				this.commitMsg()
			},
			commitMsg() {
				if (!this.nick_name) {
					this.$toast("请填写昵称")
					return
				}
				if (!this.height) {
					this.$toast("请输入身高")
					return
				};
				if (!this.weight) {
					this.$toast("请输入体重")
					return
				};
				if (!this.select_coat_size) {
					this.$toast("请选择上衣类型")
					return
				};
				if (!this.select_trousers_size) {
					this.$toast("请选择裤子类型")
					return
				};
				if (!this.select_shoes_size) {
					this.$toast("请选择鞋子类型")
					return
				};
				if (this.select_mt_type === 102) {
					if (!this.select_cup_size) {
						this.$toast("请选择罩杯类型")
						return
					}
				}
				if (!this.weixin) {
					this.$toast("请输入微信号")
					return
				}
				if (!this.phone) {
					this.$toast("请输入手机号")
					return
				}

				let sentData = {
					access_token: localStorage.getItem("access_token"),
					head_ico: this.head_ico,
					bg_cover: this.bg_cover,
					nick_name: this.nick_name,
					autograph: this.autograph,
					md_type: this.select_mt_type,
					birthday: this.birthday,
					height: this.height,
					weight: this.weight,
					coat: this.select_coat_size,
					trousers: this.select_trousers_size,
					shoes: this.select_shoes_size,
					cups: this.select_cup_size,
					weixin: this.weixin,
					phone: this.phone
				}
				if (this.province_id) {
					sentData.province_id = this.province_id
					sentData.city_id = this.city_id
					sentData.area_id = this.area_id
				}
				console.log("修改资料请求参数====", sentData)
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/savemydetail",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							this.$toast("修改成功")
						} else if (res.data.status == 'n') {
							this.$toast(res.data.error)
						}
					});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper {
		background-color: #F5F5F5;
		overflow-y: auto;
	}

	.container-item {
		background-color: #fff;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.msg-item {
		height: 100px;
		line-height: 100px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 24px;
		font-size: 30px;
		border-bottom: 1px solid #F5F5F5;

		&:last-child {
			border: 0;
		}

		&.top-item {
			height: 88px;
			line-height: 88px;
			border: 0;

			&.avatar-con {
				height: 120px;
				line-height: 120px;
				width: 702px;
			}
		}

		.msg-item-right {
			display: flex;
			align-items: center;

			.right-input {
				text-align: right;
			}

			.unit {
				margin-left: 10px;
			}
		}


		.top-img {
			width: 100px;
			height: 100px;
			object-fit: cover;
			border-radius: 50%;
		}

		.sign-text {
			max-width: 400px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			color: #888;
		}

		.icon-you {
			margin-left: 10px;
			color: #DDD;
			font-size: 20px;
		}
	}

	.addr-tip {
		padding: 0 24px 200px;
		line-height: 60px;
		font-size: 24px;
		color: #FF6685;
	}

	.save-btn {
		height: 90px;
		line-height: 90px;
		border-radius: 15px;
		background: #1A1F29;
		color: #fff;
		font-size: 32px;
		position: fixed;
		bottom: 30px;
		left: 24px;
		width: 702px;
		text-align: center;
		box-shadow: 0 10px 15px 1px rgba(0, 0, 0, 0.3);
		z-index: 100;
	}


	.vant_popup_box {
		border-radius: 15px;

		.model-con {
			padding: 20px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}

		.model-title {
			line-height: 90px;
			font-size: 34px;
			font-weight: 700;
		}

		.textarea {
			width: 500px;
			height: 200px;
			background-color: #f5f5f5;
			border: 0;
			border-radius: 20px;
			padding: 20px;
			line-height: 40px;
			margin: 10px;
			font-size: 28px;
		}

		.model-input-con {
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 30px 0;
			border-bottom: 4px solid #aaa;
			width: 400px;

			.model-input {
				width: 200px;
				height: 80px;
				font-size: 36px;
				text-align: center;
			}

			.input-unit {
				font-size: 40px;
			}
		}


		.model-input::-webkit-input-placeholder {
			/* WebKit, Blink, Edge */
			font-size: 30px;
			color: #aaa;
		}

		.model-input:-moz-placeholder {
			/* Mozilla Firefox 4 to 18 */
			font-size: 30px;
			color: #aaa;
		}

		.model-input::-moz-placeholder {
			/* Mozilla Firefox 19+ */
			font-size: 30px;
			color: #aaa;
		}

		.model-input:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			font-size: 30px;
			color: #aaa;
		}


		.model-btn-con {
			display: flex;
			justify-content: space-around;
			width: 540px;
			padding: 30px 0 20px;

			div {
				width: 200px;
				line-height: 70px;
				border-radius: 35px;
				font-size: 30px;
				text-align: center;
			}

			.model-btn-item-l {
				border: 1px solid #ddd;
				color: #888;
			}

			.model-btn-item-r {
				border: 1px solid #000;
				background-color: #000;
				color: #fff;
			}
		}
	}
</style>
