<template>
	<div class="container-wrapper">
		<navbar :title="title"></navbar>
		<div class="wait-container">
			<img class="wait-img" src="https://img.jimeimadou.com/common/resource/wait_auth/wait_auth@1x.png" >
			<div class="wait-text">
				<div>已收到您的认证信息</div>
				<div>预计一个工作日内完成审核</div>
			</div>
			<div class="service-btn">联系客服</div>
		</div>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	export default {
		data() {
			return {
				title: ""
			}
		},
		components: {
			navbar
		},
		activated() {
			let title = this.$route.query.title
			switch (title) {
				case "mtAuth":
					this.title = "模特认证"
					break;
				case "redBookAuth":
					this.title = "小红书名片"
					break;
				default:
					break;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.wait-container{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		height: 60vh;
		padding-top: 200px;
		.wait-img{
			width: 70%;
			object-fit: contain;
		}
		.wait-text{
			line-height: 50px;
			margin-top: 40px;
			font-size: 28px;
			color: #8a8e99;
		}
		.service-btn{
			line-height: 100px;
			border-radius: 50px;
			width: 250px;
			text-align: center;
			font-size: 32px;
			color: #666;
			background-color: #f1f1f1;
			margin-top: 30px;
			border: 1px solid #AAAAAA;
		}
	}

</style>
