<template>
	<div class="container-wrapper">
		<navbar title="短信通知设置"></navbar>
		<div class="setting-container">
			<div class="item-con">
				<div class="left-text">短信通知开关</div>
				<van-switch v-model="isOpen" active-color="#FF6685" inactive-color="#f1f1f1" size="50" />
			</div>
			<div :class="isOpen?'':'open-css'">
				<div class="item-con">
					<div>通知接收频率</div>
					<div class="item-border" @click="showFrequencyModel">{{select_frequency.text}}</div>
					<i class="iconfont icon-question-circle"></i>
				</div>
				<div class="item-con">
					<div>接收通知时间段</div>
					<div class="item-border time" @click="showTimeModel('start')">
						{{startTime}}
					</div>
					<div>至</div>
					<div class="item-border time" @click="showTimeModel('end')">
						{{endTime}}
					</div>
				</div>
				<div class="item-con">
					<div class="left-text"><span class="red-color">*</span>通知内容</div>
					<van-checkbox-group v-model="result" direction="horizontal" @change="checkboxGroupChange">
						<van-checkbox v-for="(item,index) in list" :key="index" v-model="item.checked" shape="square" checked-color="#FF6685" :name="item.type">{{item.name}}</van-checkbox>
					</van-checkbox-group>
				</div>
				<div class="item-con">
					<div><span class="red-color">*</span>添加接收人</div>
					<input class="phone-input" v-model="phone1" placeholder="请输入接收人的手机号码" />
				</div>
			</div>
			<div class="save-btn" @click="commitSettings">保存</div>
		</div>



		<van-popup class="vant_popup_box" position="bottom" v-model="isShow">
			<template v-if="modelName=='timeModel'">
				<van-datetime-picker v-model="currentTime" item-height="1.2rem" type="time" title="选择时间" :min-hour="0" :max-hour="23" @cancel="hideModel" @confirm="chooseTime"/>
			</template>
			<template v-if="modelName=='frequencyModel'">
				<van-picker show-toolbar :columns="frequencys" item-height="1.2rem" :default-index="2" @cancel="hideModel"
					@confirm="chooseFrequencys" />
			</template>
		</van-popup>

	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	export default {
		data() {
			return {
				isOpen: false,
				list: [{
						name: '已派单',
						type: 1,
						checked: true,
						disabled: false
					},
					{
						name: '审核拒绝',
						type: 2,
						checked: true,
						disabled: false
					},
					{
						name: '被邀请',
						type: 3,
						checked: true,
						disabled: false
					}
				],
				hours: [],
				minutes: [],
				modelName: "",
				startTime: "00:00",
				endTime: "00:00",
				currentTime:"12:00",
				setTypes: [],
				select_frequency: {
					text: "每半小时一次",
					type: '1'
				},
				frequencys: [{
					text: "每半小时一次",
					type: '1'
				}, {
					text: "每一小时一次",
					type: '2'
				}, {
					text: "每两小时一次",
					type: '3'
				}],
				phone1: "",
				phone2: "",
				result: [],
				isShow: false,
				time_type:""
			}
		},
		components: {
			navbar
		},
		methods: {
			showFrequencyModel() {
				this.modelName = "frequencyModel"
				this.isShow = true
			},
			hideModel() {
				this.isShow = false
			},
			chooseFrequencys(value) {
				console.log(value)
				this.select_frequency = value
				this.hideModel()
			},
			checkboxGroupChange(value) {
				this.setTypes = value
			},

			getSettings() {
				let sentData = {
					access_token: localStorage.getItem("access_token")
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/getsmsconf",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "y") {
							if (res.data.data) {
								// 有配置短信设置
								let result = JSON.parse(res.data.data)
								console.log(result)
								//初始化开关
								if (result.sms_switch == 1) {
									this.isOpen = true
								} else {
									this.isOpen = false
								}
								//初始化接收频率
								for (let i = 0; i < this.frequencys.length; i++) {
									if (this.frequencys[i].type == result.frequency) {
										this.select_frequency = this.frequencys[i]
									}
								}
								//初始化通知时间段
								let tz_stime = result.tz_stime.split(':')
								let tz_etime = result.tz_etime.split(':')

								//初始化通知内容
								let tz_type = result.tz_type
								this.list.forEach(item => {
									if (tz_type.indexOf(item.type) != -1) {
										item.checked = true
									} else {
										item.checked = false
									}
								})
								this.setTypes = tz_type ? tz_type.split(",") : []
								//初始化接收人手机号
								this.phone1 = result.tz_phone1
							} else {
								//没有设置过短信设置
								this.isOpen = true
								this.select_frequency = {
									text: "每半小时一次",
									type: '1'
								}
								this.list.forEach(item => {
									if (item.checked) {
										this.setTypes.push(item.type)
									}
								})
							}
						}
					});
			},
			commitSettings() {
				console.log(this.setTypes)
				if (this.isOpen == 1) {
					if (this.setTypes.length == 0) {
						this.$toast('请选择通知内容')
						return
					}
					if (!this.phone1) {
						this.$toast('请输入接收人的手机号码')
						return
					}
				}
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					sms_switch: this.isOpen ? 1 : 0,
					frequency: this.select_frequency.type,
					tz_stime: this.startTime,
					tz_etime: this.endTime,
					tz_type: this.setTypes.join(','),
					tz_phone1: this.phone1
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/setsmsconf",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							this.$toast("设置成功")
							setTimeout(() => {
								this.$router.back(-1);
							}, 1200)
						} else {
							this.$toast(res.data.error)
						}
					});
			},
			showTimeModel(type) {
				this.time_type = type
				this.modelName = "timeModel"
				this.isShow = true
			},
			chooseTime(val) {
				console.log(val)
				switch (this.time_type){
					case "start":
					this.startTime = val
						break;
					case "end":
					this.endTime = val
						break;
					default:
						break;
				}
				this.hideModel()
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper {
		background-color: #fff;
	}

	.item-con {
		display: flex;
		align-items: center;
		height: 120px;
		padding: 0 24px;
		font-size: 30px;

		.red-color {
			color: red;
			margin-right: 2px;
		}

		.left-text {
			margin-right: 20px;
		}

		.icon-question-circle {
			font-size: 28px;
		}
	}

	.label {
		margin: 0 5px;
	}


	.item-border {
		border-radius: 30px;
		background-color: #f1f1f1;
		text-align: center;
		color: #333;
		font-size: 26px;
		width: 360px;
		height: 60px;
		line-height: 60px;
		margin: 0 20px;
	}

	.time {
		width: 150px;
	}

	.phone-input {
		border-radius: 35px;
		background-color: #f1f1f1;
		color: #333333;
		width: 420px;
		height: 70px;
		line-height: 70px;
		margin: 0 20px;
		padding: 0 30px;
		font-size: 28px;
	}

	.save-btn {
		width: 360px;
		line-height: 80px;
		background-color: #000;
		color: #fff;
		border-radius: 40px;
		text-align: center;
		margin: 60px auto;
		font-size: 32px;
	}

	.open-css {
		position: relative;
	}

	.open-css:after {
		content: "";
		background-color: rgba(255, 255, 255, 0.8);
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
	}
</style>
