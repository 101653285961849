<template>
	<div class="container-wrapper">
		<back-top-fixed></back-top-fixed>
		<scroll-y class="scroll-container" ref="iscroll" @loadMore="loadmore">

			<mt-detail-top album_type="zhongcao" :is_edit="is_edit" @uploadPhoto="uploadPhoto" @editPhoto="editPhoto">
			</mt-detail-top>
			<div class="tab-container">
				<div class="tab-item" v-for="(item,index) in productions" :key="index" @click="chooseProduction(index)">
					<div class="tab-name" :class="current_production==index?'current':''">{{item.title}}</div>
					<div class="tab-count" :style="current_production==index?'color:#FF6685':''">({{item.count}})
					</div>
				</div>
			</div>

			<div v-if="current_production==0" class="photo-container">
				<empty v-if="productions[0].isNull" title="暂无种草视频"></empty>
				<template v-else>
					<div class="photo-wrapper">
						<div class="photo-item" v-for="(item,index) in zhongcao" :key="index" @click="toAlbumDetail(item.zc_id,item.mt_id)">
							<image-loader class="photo-image" :src="addWaterMark(item.cover)" ></image-loader>
							<div class="video-mask">
								<img src="../../../../public/static/play.png">
							</div>

							<div class="my-icon-con" @click.stop="showMask(index)">
								<i
									:class="['my-icon-item','iconfont',item.is_private?'icon-Notvisible':'icon-browse']"></i>
							</div>

							<div class="item-bottom-con">
								<div class="status-con">
									<div class="item-status" v-if="item.state == 1">审核中</div>
									<div class="item-status" v-if="item.state == 2">已通过</div>
									<div class="item-status" v-if="item.state == 3">
										<div class="status-flex" @click.stop="showRefuseModel(item.refuse_reason)">
											<i class="iconfont icon-question-circle"></i>
											<div>已拒绝</div>
										</div>
									</div>
								</div>
								<div v-if="is_edit" class="bottom-delete" @click.stop="showDeleteModel(index)">
									<i class="iconfont icon-delete"></i>
								</div>
							</div>

							<div :class="['photo-mask',item.mask_show?'mask-show':'']">
								<div class="mask-title">
									<template v-if="item.is_private">
										<i class="mask-icon iconfont icon-browse"></i>
										<div>公开展示</div>
									</template>
									<template v-else>
										<i class="mask-icon iconfont cuIcon-attentionforbid"></i>
										<div>仅自己可见</div>
									</template>
								</div>
								<div class="mask-text">{{item.is_private?'选择后该相册将会展示在您的个人主页':'选择后该相册不对其他人展示'}}</div>
								<div class="mask-btn-con">
									<div class="mask-btn-item" @click.stop="hideMask">否</div>
									<div class="mask-btn-item sure" @click.stop="setPrivate">是</div>
								</div>
							</div>
						</div>
					</div>
					<div class="nomore" v-if="productions[0].stopLoad">没有更多了~</div>
				</template>
			</div>


			<div v-if="current_production==1" class="photo-container">
				<empty v-if="productions[1].isNull" title="暂无主图详情"></empty>
				<template v-else>
					<div class="photo-wrapper">
						<div class="photo-item" v-for="(item,index) in masterMapProducts" :key="index"
							@click="toAlbumDetail(item.zt_id,item.mt_id)">
							<image-loader class="photo-image" :src="addWaterMark(item.photos[0])" ></image-loader>
							<div class="my-icon-con" @click.stop="showMask(index)">
								<i
									:class="['my-icon-item','iconfont',item.is_private?'icon-Notvisible':'icon-browse']"></i>
							</div>

							<div class="item-bottom-con">
								<div class="status-con">
									<div class="item-status" v-if="item.state == 1">审核中</div>
									<div class="item-status" v-if="item.state == 2">已通过</div>
									<div class="item-status" v-if="item.state == 3">
										<div class="status-flex" @click.stop="showRefuseModel(item.refuse_reason)">
											<i class="iconfont icon-question-circle"></i>
											<div>已拒绝</div>
										</div>
									</div>
									<div class="total-num">
										<i class="iconfont icon-tupian"></i>
										<div>{{item.photos.length}}</div>
									</div>
								</div>
								<div v-if="is_edit" class="bottom-delete" @click.stop="showDeleteModel(index)">
									<i class="iconfont icon-delete"></i>
								</div>
							</div>

							<div :class="['photo-mask',item.mask_show?'mask-show':'']">
								<div class="mask-title">
									<template v-if="item.is_private">
										<i class="mask-icon iconfont icon-browse"></i>
										<div>公开展示</div>
									</template>
									<template v-else>
										<i class="mask-icon iconfont cuIcon-attentionforbid"></i>
										<div>仅自己可见</div>
									</template>
								</div>
								<div class="mask-text">{{item.is_private?'选择后该相册将会展示在您的个人主页':'选择后该相册不对其他人展示'}}</div>
								<div class="mask-btn-con">
									<div class="mask-btn-item" @click.stop="hideMask">否</div>
									<div class="mask-btn-item sure" @click.stop="setPrivate">是</div>
								</div>
							</div>
						</div>
					</div>
					<div class="nomore" v-if="productions[1].stopLoad">没有更多了~</div>
				</template>
			</div>
		</scroll-y>

		<tip-model :show="show_reason" title="拒绝原因" :content="refuseReason" :showCancelBtn="false" confirmText="我知道了"
			@confirm="hideModel"></tip-model>

		<tip-model :show="show_delete" title="温馨提示" content="确认要删除该相册吗？" @cancel="hideModel" @confirm="deleteAlbum">
		</tip-model>

		<album-model :show="show_album" :swiper_photos="swiper_photos" :current="swiper_current"
			@hideMask="hideMjxAlbum"></album-model>

		<div class="mask-container" v-if="isShowModel">
			<div class="choose-model">
				<i class="iconfont icon-close-bold" @click="hideModel"></i>
				<div class="model-title">选择上传作品类型</div>
				<div class="options-item" @click="to_upload_zhongcao">
					<img class="option-image"
						src="https://img.jimeimadou.com/common/seller_xcx_image/plaza/plaza_mjx_cao.png"
						mode="widthFix"></img>
					<div class="text_wrap">
						<div class="title">种草/口播视频</div>
						<div class="content">用于主图视频、微淘、小红书、抖音等平台。</div>
					</div>
				</div>
				<div class="options-item" @click="to_upload_zhutu">
					<img class="option-image" src="https://img.jimeimadou.com/seller/img/images/publish02.png"
						mode="widthFix"></img>
					<div class="text_wrap">
						<div class="title">主图/详情页</div>
						<div class="content">拍摄产品照片用于主图详情页展示，可指定拍摄场景。</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import empty from "@/components/empty.vue"
	import scrollY from "@/components/app-scroll-y.vue"
	import albumModel from "@/components/album-model.vue"
	import mtDetailTop from "@/components/mtdetail-top.vue"
	import backTopFixed from "@/components/back-top-fixed.vue"
	import tipModel from "@/components/tip-model.vue"
	export default {
		data() {
			return {
				productions: [{
					title: "种草视频作品",
					count: 0,
					stopLoad: false,
					isNull: false,
					page: 1,
				}, {
					title: "主图详情作品",
					count: 0,
					stopLoad: false,
					isNull: false,
					page: 1,
				}],
				page_size: 10,
				zhongcao: [],
				masterMapProducts: [],
				current_production: 0,

				swiper_photos: [],
				swiper_current: 0,
				mask_index: -1,
				is_edit: false,
				delete_index: -1,
				refuseReason: "",
				show_album: false,
				show_reason: false,
				show_delete: false,
				isShowModel: false
			}
		},
		components: {
			mtDetailTop,
			empty,
			scrollY,
			albumModel,
			backTopFixed,
			tipModel
		},
		activated() {
			this.initData()
			this.getZhongcaoVideos()
			this.getMasterMapList()
		},
		methods: {
			initData() {
				this.productions = [{
					title: "种草视频作品",
					count: 0,
					stopLoad: false,
					isNull: false,
					page: 1,
				}, {
					title: "主图详情作品",
					count: 0,
					stopLoad: false,
					isNull: false,
					page: 1,
				}]
				this.zhongcao = []
				this.masterMapProducts = []
			},
			loadmore() {
				if (this.current_production == 0) {
					this.getZhongcaoVideos()
				} else {
					this.getMasterMapList()
				}
			},
			toVideoDetail(url) {
				this.$router.push({
					name: "videoDetail",
					query: {
						url
					}
				});
			},

			//获取种草视频列表
			getZhongcaoVideos() {
				if (this.productions[0].stopLoad) {
					return
				}
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					page: this.productions[0].page,
					page_size: this.page_size,
					is_mine: 1
				}
				this.productions[0].page++
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/zhongcaophotolist",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							let result = JSON.parse(res.data.data);
							console.log("种草列表====", result)
							this.productions[0].count = result.total_nums
							let zhongcao = result.list

							if (zhongcao.length < this.page_size) {
								if (zhongcao.length == 0 && this.productions[0].page == 2) {
									this.productions[0].isNull = true
								}
								this.productions[0].stopLoad = true
								console.log(this.productions)
							}

							if (zhongcao && zhongcao.length != 0) {
								for (let i = 0; i < zhongcao.length; i++) {
									zhongcao[i].is_private = zhongcao[i].cus_show == 1
								}
								this.zhongcao.push(...zhongcao)
							}
						}
					});
			},
			//获取主图详情页列表
			getMasterMapList() {
				if (this.productions[1].stopLoad) {
					return
				}
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					page: this.productions[1].page,
					page_size: this.page_size,
					is_mine: 1
				}
				this.productions[1].page++
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/zhutudetaillist",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							let result = JSON.parse(res.data.data);
							console.log("主图列表====", result)
							this.productions[1].count = result.total_nums
							let masterMapProducts = result.list

							if (masterMapProducts.length < this.page_size) {
								if (masterMapProducts.length == 0 && this.productions[1].page == 2) {
									this.productions[1].isNull = true
								}
								this.productions[1].stopLoad = true
							}

							if (masterMapProducts && masterMapProducts.length != 0) {
								masterMapProducts.forEach(item => {
									item.is_private = item.cus_show == 1
									item.photos = item.photos.split(",")
									this.masterMapProducts.push(item)
								})
							}
						}
					});
			},
			uploadPhoto() {
				console.log("上传作品")
				this.isShowModel = true
			},
			editPhoto() {
				console.log("编辑作品")
				this.hideMask()
				this.is_edit = !this.is_edit
			},
			//切换导航栏选择作品类型
			chooseProduction(index) {
				//切换另一种作品列表时判断当前作品列表是否有未关闭的遮罩层（是否点击过右上角眼睛），有则关闭遮罩
				this.hideMask()
				this.current_production = index
			},
			// 展示仅自己可见遮罩层
			showMask(index) {
				this.hideMask()
				if (this.current_production == 0) {
					this.zhongcao[index].mask_show = true
				} else {
					this.masterMapProducts[index].mask_show = true
				}
				this.$forceUpdate()
				this.mask_index = index
			},
			// 隐藏仅自己可见遮罩层
			hideMask() {
				if (this.mask_index != -1) {
					if (this.current_production == 0) {
						this.zhongcao[this.mask_index].mask_show = false
					} else {
						this.masterMapProducts[this.mask_index].mask_show = false
					}
					this.$forceUpdate()
					this.mask_index = -1
				}
			},
			//确认设置为仅自己可见/公开展示
			setPrivate() {
				let album = []
				let act_type
				let act_id
				let act_val
				if (this.current_production == 0) {
					act_type = 2
					album = this.zhongcao[this.mask_index]
					act_id = album.zc_id
				} else {
					act_type = 3
					album = this.masterMapProducts[this.mask_index]
					act_id = album.zt_id
				}
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					act_type,
					act_id,
					act_val: album.is_private ? "0" : "1"
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/handlephotocusshow",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							console.log("操作成功")
							this.$toast("设置成功")
							this.hideMask()
							album.is_private = !album.is_private
						} else {
							this.$toast(res.data.error)
						}
					});
			},
			//显示相册弹框
			showMjxAlbum(photos) {
				this.swiper_photos = photos
				this.show_album = true
			},
			toAlbumDetail(album_id,mt_id){
				this.$router.push({
					name: "albumDetail",
					query: {
						mt_id,
						album_id,
						album_type: this.current_production+2
					}
				});
			},
			hideMjxAlbum() {
				this.show_album = false
			},
			showRefuseModel(reason) {
				this.refuseReason = reason
				this.show_reason = true
			},
			hideModel() {
				this.show_reason = false
				this.show_delete = false
				this.isShowModel = false
			},
			showDeleteModel(index) {
				this.hideMask()
				this.delete_index = index
				this.show_delete = true
			},
			addWaterMark(url) {
				let compressParams = "?imageView2/1/w/500/h/700"
				let waterMark =
					"|watermark/1/blogo/2/image/aHR0cDovL2ptc3RhdGljLTEyNTU0OTc5MTYuY29zLmFwLXNoYW5naGFpLm15cWNsb3VkLmNvbS9jb21tb24vd2F0ZXJtYXJrZXIucG5n/dissolve/90/dx/10/dy/10"
				let newUrl = url + compressParams + waterMark
				newUrl = newUrl.replace('https://img.jimeimadou.com',
					'https://jmstatic-1255497916.cos-website.ap-shanghai.myqcloud.com')
				return newUrl
			},
			deleteAlbum() {
				this.hideModel()
				console.log("确认删除相册")
				switch (this.current_production) {
					case 0:
						this.deleteZhongcao()
						break;
					case 1:
						this.deleteZhutu()
						break;
					default:
						break;
				}
			},
			//删除种草视频
			deleteZhongcao() {
				let deleteItem = this.zhongcao[this.delete_index]
				if (deleteItem.state != 3) {
					this.$toast("只允许删除审核拒绝的相册")
					this.hideModel()
					return
				}
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					zc_id: deleteItem.zc_id
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/delzhongcaophoto",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							this.$toast("删除成功")
							this.hideModel()
							this.productions[this.current_production].count--
							this.zhongcao.splice(this.delete_index, 1)
							if (this.zhongcao < this.page_size) {
								this.getZhongcaoVideos()
							}
						} else {
							this.$toast(res.data.error)
						}
					});
			},
			//删除主图/详情作品
			deleteZhutu() {
				let deleteItem = this.masterMapProducts[this.delete_index]
				if (deleteItem.state != 3) {
					this.$toast("只允许删除审核拒绝的相册")
					this.hideModel()
					return
				}

				let sentData = {
					access_token: localStorage.getItem("access_token"),
					zt_id: deleteItem.zt_id
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/delzhongcaophoto",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							this.$toast("删除成功")
							this.hideModel()
							this.productions[this.current_production].count--
							this.masterMapProducts.splice(this.delete_index, 1)
							if (this.masterMapProducts < this.page_size) {
								this.getMasterMapList()
							}
						} else {
							this.$toast(res.data.error)
						}
					});
			},
			to_upload_zhongcao() {
				this.isShowModel = false
				this.$nextTick(() => {
					this.$router.push({
						name: "uploadZhongcao"
					});
				})
			},
			to_upload_zhutu() {
				this.isShowModel = false
				this.$nextTick(() => {
					this.$router.push({
						name: "uploadZhutu"
					});
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.scroll-container {
		width: 100vw;
		height: 100vh;
		background-color: #f7f7f7;
	}

	.tab-container {
		line-height: 100px;
		height: 100px;
		position: sticky;
		z-index: 100;
		display: flex;

		.tab-item {
			flex: 1;
			padding: 0 24px;
			display: flex;
			justify-content: center;
		}

		.tab-name {
			position: relative;
			font-weight: 700;
			font-size: 32px;
		}

		.current {
			color: #FF6685;
		}

		.current::after {
			content: "";
			position: absolute;
			bottom: 20px;
			left: 0;
			width: 100%;
			height: 5px;
			border-radius: 3px;
			background-color: #FF6685;
		}
	}

	.photo-container {
		padding: 0 24px;

		.photo-wrapper {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.photo-item {
			width: 344px;
			height: 480px;
			position: relative;
			margin-bottom: 15px;
			border-radius: 10px;
			overflow: hidden;
		}

		.my-icon-con {
			position: absolute;
			right: 15px;
			top: 15px;
			width: 40px;
			height: 40px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: #fff;
			border-radius: 50%;
			background-color: rgba(0, 0, 0, 0.5);
		}

		.my-icon-item {
			font-size: 30px;
			opacity: 0.9;
		}

		.my-icon-con .icon-Notvisible {
			color: #FF6685;
		}

		.photo-mask {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			bottom: 0;
			background-color: rgba(0, 0, 0, 0.8);
			color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			font-size: 30px;
			padding: 0 35px;
			opacity: 0;
			visibility: hidden;
		}

		.mask-show {
			opacity: 1;
			visibility: initial;
		}

		.mask-title {
			line-height: 70px;
			font-size: 36px;
			display: flex;
		}

		.photo-mask .mask-icon {
			color: #FF6685;
			margin-right: 10px;
			font-size: 44px;
		}

		.mask-text {
			line-height: 40px;
			margin-bottom: 80px;
			font-size: 26px;
			color: rgba(255, 255, 255, 0.8);
		}

		.mask-btn-con {
			display: flex;
			justify-content: space-between;
		}

		.mask-btn-item {
			width: 120px;
			text-align: center;
			line-height: 60px;
			border-radius: 30px;
			color: #aaa;
			background-color: #fff;
		}

		.mask-btn-item.sure {
			background-color: #FF6685;
			color: #fff;
		}

		.photo-item .photo-image {
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 10px;
			object-fit: cover;
		}

		.photo-item .item-video {
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 10px;
		}

		.item-bottom-con {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
		}

		.status-con {
			padding: 15px;
			display: flex;
			justify-content: flex-end;
		}

		.item-status {
			line-height: 40px;
			font-size: 22px;
			padding: 0 15px;
			border-radius: 40px;
			background-color: rgba(0, 0, 0, 0.5);
			color: #fff;

			.status-flex {
				display: flex;

				.icon-question-circle {
					color: #fff;
					margin-right: 3px;
					font-size: 20px;
				}
			}
		}

		.bottom-delete {
			display: flex;
			justify-content: center;
			line-height: 70px;
			color: #fff;
			background-color: #FF6685;
		}

		.icon-delete {
			font-size: 34px;
		}

		.total-num {
			background-color: rgba(0, 0, 0, 0.5);
			color: #fff;
			line-height: 40px;
			height: 40px;
			border-radius: 20px;
			padding: 0 15px;
			font-size: 24px;
			margin-left: 10px;
			display: flex;
		}

		.icon-tupian {
			margin-right: 5px;
			font-size: 20px;
		}

		.video-mask {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
		}

		.video-mask img {
			width: 60px;
			height: auto;
			display: block;
		}

		.nomore {
			text-align: center;
			line-height: 40px;
			padding: 0;
			padding-bottom: 50px;
			color: #aaa;
			font-size: 26px;
		}
	}

	.vant_popup_box {
		border-radius: 15px;

	}

	.choose-model {
		background-color: #fff;
		width: 640px;
		padding: 40px 50px 50px;
		position: relative;
		border-radius: 20px;

		.model-title {
			margin-bottom: 20px;
			line-height: 60px;
			font-weight: 700;
			font-size: 32px;
			text-align: center;
		}

		.icon-close-bold {
			position: absolute;
			right: 20px;
			top: 20px;
			font-size: 36px;
		}

		.options-item {
			display: flex;
			justify-content: space-between;
			padding: 25px 0;
		}

		.option-image {
			width: 110px;
			height: auto;
			display: flex;
		}

		.text_wrap {
			flex: 1;
			margin-left: 20px;
			text-align: left;
			font-size: 24px;
			line-height: 34px;
		}

		.text_wrap .title {
			font-size: 28px;
			font-weight: 700;
			line-height: 40px;
			margin-bottom: 5px;
			color: #333;
		}
	}
	
	.mask-container{
		width: 100vw;
		height: 100vh;
		position: fixed;
		left: 0;
		top: 0;
		background-color: rgba(0,0,0,0.6);
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}
</style>
