<template>
	<div>
		<navbar title="微信扫码"></navbar>
		<div class="mine_wrap">
			<div class="invite_container"
				style="background-image:url('https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/h5_png/mine_bg%403x.png')">
				<img class="img" alt="" src="https://img.jimeimadou.com/common/logo/httg_xcx_code.jpg" />
			</div>
		</div>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue";
	export default {
		data() {
			return {
				
			};
		},
		components:{
			navbar
		},
		created() {},
	};
</script>

<style lang="scss" scoped>
	.mine_wrap {
		position: absolute;
		top: 0;
		bottom: 80px;
		left: 0;
		right: 0;
		background: #f2f3f7;
		display: flex;
		justify-content: center;
		align-items: center;

		.invite_container {
			position: relative;
			height: 770px;
			width: 600px;
			background-size: 100% 100%;
		}

		.img {
			height: 340px;
			width: 340px;
			border-radius: 50%;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: 170px;
		}
	}
</style>
