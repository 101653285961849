<template>
	<div class="container-wrapper">
		<navbar title="关注我们"></navbar>
		<div class="up-container">
			<div class="title">请关注我们的公众号</div>
			<div class="sub-title">第一时间掌握通告动态，第一时间获取活动资讯</div>
			<img class="code-img" src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/mine_icon/weixin_gzh_20210202170536.jpg" >
			<div class="code-tip">长按图片可保存</div>
		</div>
		<div class="down-container">
			<div class="down-title">我已关注公众号，为什么没有提醒？</div>
			<div class="down-tip">请按如下步骤进行操作，即可恢复</div>
			<div class="tip-container">
				<div class="tip-item">
					<i class="iconfont icon-yuandian"></i>
					<div class="num">1</div>
					<div>、</div>
					<div>重新关注公众号</div>
				</div>
				<div class="tip-item">
					<i class="iconfont icon-yuandian"></i>
					<div class="num">2</div>
					<div>、</div>
					<div>长按小程序图标删除</div>
				</div>
				<div class="tip-item">
					<i class="iconfont icon-yuandian"></i>
					<div class="num">3</div>
					<div>、</div>
					<div>重新搜索登录小程序</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	export default{
		data(){
			return{
				
			}
		},
		components:{
			navbar
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper{
		background-color: #F7F7F7;
		overflow-y: auto;
	}
	.up-container{
		background-color: #fff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 40px;
		.title{
			font-size: 50px;
			font-weight: 700;
		}
		.sub-title{
			font-size: 30px;
			color: #666;
			padding: 50px 0;
		}
		.code-img{
			width: 400px;
			display: block;
			margin-bottom: 40px;
		}
		.code-tip{
			font-size: 36px;
			color: #fff;
			width: 400px;
			line-height: 80px;
			border-radius: 40px;
			text-align: center;
			background-color: #222;
		}
	}
	.down-container{
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 40px 0;
		.down-title{
			font-size: 40px;
		}
		.down-tip{
			padding: 40px 0 60px;
			font-size: 30px;
			color: #888;
		}
		.tip-container{
			width: 400px;
			.tip-item{
				display: flex;
				align-items: center;
				line-height: 50px;
				font-size: 28px;
				
				.icon-yuandian{
					font-size: 24px;
				}
				.num{
					width: 34px;
					height: 34px;
					text-align: center;
					line-height: 34px;
					color: #fff;
					background-image: url(https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/mine_icon/inform_bg%403x.png);
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
				}
			}
		}
	}
</style>
