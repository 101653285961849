<template>
	<div class="container-wrapper">
		<navbar title="邀请明细"></navbar>
		<div class="tab-container">
			<div class="tab-item" :class="tab_index==0?'select-tab':''" @click="chooseTab(0)">邀请明细</div>
			<div class="tab-item" :class="tab_index==1?'select-tab':''" @click="chooseTab(1)">收益明细</div>
		</div>
		<div v-if="tab_index==0" class="subtitle-container">
			<div :style="'width:'+widthList1[0]+';text-align:left;'">被邀请人</div>
			<div :style="'width:'+widthList1[1]+';text-align:center;'">时间</div>
			<div :style="'width:'+widthList1[2]+';text-align:right;'">状态</div>
		</div>
		<div v-if="tab_index==1" class="subtitle-container">
			<div :style="'width:'+widthList2[0]+';text-align:left;'">被邀请人</div>
			<div :style="'width:'+widthList2[1]+';text-align:center;'">奖励额</div>
			<div :style="'width:'+widthList2[2]+';text-align:center;'">原因</div>
			<div :style="'width:'+widthList2[3]+';text-align:right;'">时间</div>
		</div>
		<scroll-y v-if="tab_index==0" class="scroll-container" ref="iScroll">
			<empty v-if="isNull" title="暂无邀请明细哦，快去邀请吧~"></empty>
			<div v-else class="invite-container">
				<div class="invite-item" v-for="(item,index) in inviteList" :key="index">
					<div class="name-con" :style="'width:'+widthList1[0]+';text-align:left;'">
						<div class="nickname">{{item.nick_name}}</div>
						<div class="user-type">{{item.user_type == 'seller' ? '商家' : '模特'}}</div>
					</div>
					<div :style="'width:'+widthList1[1]+';text-align:center;'">{{item.reg_time}}</div>
					<div :style="'width:'+widthList1[2]+';text-align:right;'">
						<template
							v-if="item.user_type == 'user'">{{item.order_num == '0' ? '未认证' : '已认证'}}</template>
						<template
							v-if="item.user_type == 'seller'">{{item.order_num == '0' ? '未放单' : '已放单'}}</template>
					</div>
				</div>
				<div class="nomore" v-if="stopLoad">没有更多了~</div>
			</div>
		</scroll-y>
		<scroll-y v-if="tab_index==1" class="scroll-container" ref="iScroll">
			<empty v-if="isNull" title="暂无收益明细哦~"></empty>
			<div v-else class="invite-container">
				<div class="invite-item" v-for="(item,index) in amountList" :key="index">
					<div class="name-con" :style="'width:'+widthList2[0]+';text-align:left;'">
						<div class="nickname">{{item.user_name}}</div>
					</div>
					<div :style="'width:'+widthList2[1]+';text-align:center;'">{{item.change_amount/100}}</div>
					<div class="remark" :style="'width:'+widthList2[2]+';text-align:center;'">{{item.remark}}</div>
					<div :style="'width:'+widthList2[3]+';text-align:right;'">{{item.add_time}}</div>
				</div>
				<div class="nomore" v-if="stopLoad">没有更多了~</div>
			</div>
		</scroll-y>
		
	</div>
</template>

<script>
	import scrollY from "@/components/app-scroll-y.vue"
	import empty from "@/components/empty.vue"
	import navbar from "@/components/navbar.vue"
	export default {
		data() {
			return {
				tab_index:0,
				widthList1: ["50%", "30%", "20%"],
				widthList2:["30%","15%","30%","25%"],
				inviteList: [],
				amountList:[],
				isNull: false,
				stopLoad: false,
				page:1,
				page_size: 30,
				invite_type:""
			}
		},
		components: {
			scrollY,
			empty,
			navbar
		},
		activated() {
			console.log(this.$route.query)
			this.invite_type = this.$route.query.type
			this.initData()
			if(this.invite_type=='money'){
				this.tab_index = 1
				this.getAmountlist()
			}else{
				this.tab_index = 0
				this.getInviteList()
			}
		},
		methods: {
			initData(){
				this.page = 1
				this.inviteList = []
				this.amountList = []
				this.stopLoad = false
				this.isNull = false
				this.$refs.iScroll.scrollToTop();
			},
			chooseTab(index){
				this.tab_index = index
				this.initData()
				switch (index){
					case 0:
					this.getInviteList()
						break;
					case 1:
					this.getAmountlist()
						break;
					default:
						break;
				}
			},
			getInviteList() {
				if (this.stopLoad) {
					return
				}
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					// type: this.invite_type,
					page: this.page++,
					page_size: this.page_size
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/invitelist",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "n") {
							return
						}
						let result = JSON.parse(res.data.data)
						console.log("邀请列表",result)
						if(result.list.length!=0){
							this.inviteList.push(...result.list)
						}
						if (result.list.length < this.page_size) {
							this.stopLoad = true
							if (result.list.length == 0 && this.page == 2) {
								this.isNull = true
							}
						}
					});
			},
			getAmountlist() {
				if (this.stopLoad) {
					return
				}
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					type: 21,
					page: this.page++,
					page_size: this.page_size
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/amountlist",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "n") {
							return
						}
						let result = JSON.parse(res.data.data)
						console.log("收益列表",result)
						if(result.list.length!=0){
							this.amountList.push(...result.list)
						}
						if (result.list.length < this.page_size) {
							this.stopLoad = true
							if (result.list.length == 0 && this.page == 2) {
								this.isNull = true
							}
						}
					});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper {
		background-color: #f1f1f1;
	}

	.tab-container {
		line-height: 100px;
		padding: 0 24px;
		display: flex;
		justify-content: space-around;
		font-size: 32px;

		.tab-item {
			color: #888;
			position: relative;
		}
		.select-tab{
			color: #000;
			font-weight: 700;
		}
		.select-tab::after{
			content: "";
			position: absolute;
			bottom: 20px;
			left: 0;
			height: 6px;
			border-radius: 3px;
			background-color: #FF6685;
			width: 100%;
		}
	}
	
	.scroll-container{
		height: calc(100vh - 270px);
	}

	
	.subtitle-container {
		display: flex;
		background-color: #F7F7F7;
		font-size: 26px;
		color: #333;
		line-height: 80px;
		padding: 0 20px;
	}

	.invite-container {
		.invite-item {
			display: flex;
			height: 100px;
			line-height: 32px;
			align-items: center;
			font-size: 24px;
			padding: 0 20px;
			border-bottom: 1px solid #F1F1F1;

			.name-con {
				display: flex;
				align-items: center;

				.user-type {
					font-size: 18px;
					border: 1px solid royalblue;
					padding: 0 5px;
					color: royalblue;
					height: 32px;
					line-height: 32px;
				}

				.nickname {
					flex: 1;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					max-width: 240px;
				}
			}
			.remark{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
</style>
