<template>
  <div class="channel-auth-wrap">
    <div class="channel-title">
      <div class="back_img">
        <img class="back_img"
             src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/Back%403x.png"
             @click="back_list"
             alt/>
      </div>
      渠道认证
    </div>

    <div class="top-container">
      <div class="top-title">我的渠道</div>
      <div :class="['edit-btn', isEdit ? 'edit' : '']" @click="edit">
        编辑<van-icon name="cross" v-if="isEdit" class="cuIcon-close"/>
      </div>
    </div>

    <div class="channel-container">
      <template v-for="(item, index) in channels">
        <div class="channel-item" v-if="item.type != 3" :key="index">
          <img class="channel-icon" :src="item.icon"/>
          <div class="right-con">
            <div class="right-title">{{item.name}}</div>
            <div v-if="item.status==0" class="flex" @click="showAuth(index)">
              去认证<van-icon name="arrow" class="cuIcon-right"/>
            </div>
            <div v-if="item.status==1 && !isEdit" class="status status1">审核中</div>
            <div v-if="item.status==2 && !isEdit" class="status status2">已认证</div>
            <div class="flex align-center" v-if="item.status==3 && !isEdit">
              <div class="reason-btn"  @click="showReasonModel(index)">查看原因</div>
              <div class="status status3">认证拒绝</div>
            </div>
            <div class="un-bind" v-if="item.status!=0 && isEdit" @click="showunBind(index)">解绑</div>
          </div>
        </div>
      </template>
    </div>

    <van-popup v-model="showAuthPopup" round position="bottom">
      <div class="cu-dialog bg-white auth-model">
        <van-icon name="cross" class="cuIcon-close" @click="hideModel"/>
        <div class="model-title">填写认证信息</div>
        <div class="model-channel-title">
          <img class="channel-icon" :src="current_image"/>
          <div class="channel-title-sub">{{current_name}}</div>
        </div>
        <div class="link-area-con">
          <input class="link-area" type="text" v-model="link_url" :placeholder="`请粘贴${current_name}个人主页链接`"/>
          <div class="copy-btn" @click="copyToInput" style="display: none">点击粘贴</div>
        </div>
        <div class="upload-con">
          <div v-if="!homePage_image" class="upload-btn">
            <img src="https://img.jimeimadou.com/common/resource/edit_album/upload_image%403x.png"/>
            <div class="sub_tip">点击上传主页截图</div>
            <input class="image-picker" ref="imagePicker" type="file" @change="afterReadImage" accept="image/*"/>
          </div>
          <div v-if="homePage_image" class="upload-btn">
            <img class="homepage-image" :src="homePage_image"/>
            <div class="reUpload">重新上传</div>
            <input class="image-picker" ref="imagePicker" type="file" @change="afterReadImage" accept=".jpg, .jpeg, .png, .gif, .bmp"/>
          </div>
          <div class="upload-right-con">
            <div class="right_title"><span style="color: #FF6685;">注：</span>主页截图仅用于平台审核！</div>
            <div class="flex justify-end">
              <div class="teach-btn" @click="toCourse">
                <van-icon name="question" class="cuIcon-questionfill"/>
                <div>认证教程</div>
              </div>
            </div>
          </div>
        </div>
        <div class="fans-con">
          <div class="fans-label">粉丝数量</div>
          <input class="fans-input" type="number" v-model="fans_count" placeholder="请输入粉丝数量"/>
        </div>
        <div class="auth-btn" @click="sure_auth">认证</div>
      </div>
    </van-popup>

    <van-dialog v-model="showUnBind" title="确定解绑" show-cancel-button @confirm="unBind">
      <div class="unbind-text">解绑后将清除当前渠道绑定数据</div>
    </van-dialog>

    <van-dialog v-model="showReason" title="拒绝原因" confirm-button-text="我知道了" @confirm="hideModel">
      {{reason}}
    </van-dialog>

    <van-popup v-model="showImage" position="right" :style="{ width: '80%', height: '100%' }">
      <img :src="image_url" style="width: 100%"/>
    </van-popup>

  </div>
</template>

<script>
  import {uploadFile} from '../../util/uploadBaogao'
  export default {
    name: "ChannelAuth",
    data(){
      return {
        channels: {
          "1": {
            icon: "https://img.jimeimadou.com/common/icon/channel01.png",
            teach_image: "https://img.jimeimadou.com/user/img/auth_course/jc_tb.png",
            name: "淘宝逛逛",
            type: 1,
            status: 0
          },
          "2": {
            icon: "https://img.jimeimadou.com/common/icon/channel07.png",
            teach_image: "https://img.jimeimadou.com/user/img/auth_course/jc_jd.png",
            name: "京东plus",
            type: 2,
            status: 0
          },
          "3": {
            icon: "https://img.jimeimadou.com/common/icon/channel02.png",
            teach_image: "https://img.jimeimadou.com/user/img/auth_course/jc_xhs.png",
            name: "小红书",
            type: 3,
            status: 0
          },
          "4": {
            icon: "https://img.jimeimadou.com/common/icon/channel03.png",
            teach_image: "https://img.jimeimadou.com/user/img/auth_course/jc_dy.png",
            name: "抖音",
            type: 4,
            status: 0
          },
          "5": {
            icon: "https://img.jimeimadou.com/common/icon/channel04.png",
            teach_image: "https://img.jimeimadou.com/user/img/auth_course/jc_ks.png",
            name: "快手",
            type: 5,
            status: 0
          },
          "6": {
            icon: "https://img.jimeimadou.com/common/icon/channel06.png",
            teach_image: "https://img.jimeimadou.com/user/img/auth_course/jc_wb.png",
            name: "微博",
            type: 6,
            status: 0
          },
          "7": {
            icon: "https://img.jimeimadou.com/common/icon/channel05.png",
            teach_image: "https://img.jimeimadou.com/user/img/auth_course/jc_bilibili.png",
            name: "哔哩哔哩",
            type: 7,
            status: 0
          }
        },
        modelName: "",
        current_channel: -1,
        isEdit: false,
        homePage_image: "",
        link_url: "",
        fans_count: "",
        reason: "",
        showAuthPopup: false,
        showUnBind: false,
        showReason: false,
        showImage: false,
        image_url: "",
      }
    },
    computed:{
      current_name(){
        if(this.current_channel > 0){
          return this.channels[this.current_channel].name
        }
        return ''
      },
      current_image(){
        if(this.current_channel > 0){
          return this.channels[this.current_channel].icon
        }
        return ''
      }
    },
    methods:{
      back_list(){
        this.$router.go(-1);
      },

      getAuthStatus() {
        let sendData = {
          access_token:localStorage.getItem('access_token'),
        };
        let params = this.$addParams(sendData);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/mote/channellist",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((res)=>{
          if (res.data.status == "y") {
            let result = JSON.parse(res.data.data);
            for (let i = 0; i < result.length; i++) {
              let type = result[i].auth_type;
              this.channels[type].status = result[i].auth_state;
              this.channels[type].channel_id = result[i].channel_id;
              this.channels[type].refuse = result[i].refuse
            }
            console.log(this.channels)
          }
        })
      },

      afterReadImage(e){
        let file = [];
        if (e.target.files[0]) {
          file.push(e.target.files[0])
        }
        this.$toast.loading({
          message: "上传中",
          duration: 0,
        });
        for (let i = 0; i < file.length; i++) {
          uploadFile(file[i], "user/img/channel_homePage").then(res => {
            if(res.status === 'fail'){
              this.$toast.clear();
              this.$toast('上传失败，请重新上传');
              return;
            }
            if (res.url) {
              this.homePage_image = res.url;
            }
            if(this.homePage_image){
              this.$toast.clear();
            }
          });
        }
      },

      copyToInput() {
      },

      showReasonModel(index){
        this.current_channel = index;
        this.reason = this.channels[index].refuse;
        this.showReason = true;
      },

      showAuth(index){
        this.current_channel = index;
        this.showAuthPopup = true;
      },

      showunBind(index){
        this.current_channel = index;
        this.showUnBind = true;
      },

      hideModel() {
        this.showAuthPopup = false;
        this.current_channel = -1;
        this.showUnBind = false;
        this.showReason = false;
      },

      edit() {
        this.isEdit = !this.isEdit
      },

      unBind() {
        let initparams = {
          access_token:localStorage.getItem('access_token'),
          channel_id: this.channels[this.current_channel].channel_id
        };
        let params = this.$addParams(initparams);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/mote/delchannel",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((res)=>{
          if (res.data.status == "y") {
            this.$toast("解绑成功");
            this.channels[this.current_channel].status = 0;
            this.isEdit = false;
            this.hideModel();
          } else {
            this.$toast(res.data.error);
          }
        })
      },

      sure_auth() {
        if (!this.link_url) {
          this.$toast("请输入个人主页链接");
          return
        }
        if (!this.homePage_image) {
          this.$toast("请上传个人主页截图");
          return
        }
        if (!this.fans_count || this.fans_count == 0) {
          this.$toast("请输入粉丝数量");
          return
        }
        let initparams = {
          access_token: localStorage.getItem('access_token'),
          auth_type: this.channels[this.current_channel].type,
          auth_url: this.link_url,
          auth_attach: this.homePage_image,
          fans_num: parseInt(this.fans_count)
        };
        let params = this.$addParams(initparams);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/mote/createchannel",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((res)=> {
          if (res.data.status == "y") {
            this.$toast("提交成功");
            this.channels[this.current_channel].status = 1;
            this.link_url = "";
            this.homePage_image = "";
            this.fans_count = "";
            this.hideModel();
          } else {
            this.$toast(res.data.error);
          }
        })
      },

      toCourse() {
        let url = this.channels[this.current_channel].teach_image;
        this.image_url = url;
        this.showImage = true;
      },
    },
    activated(){
      this.getAuthStatus()
    }
  }
</script>

<style lang="scss" scoped>

  .channel-auth-wrap {
    .channel-title {
      width: 100%;
      height: 80px;
      display: flex;
      font-size: 35px;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 20px;
      background: #fff;

      .back_img {
        position: absolute;
        left: 20px;
        width: 50px;
        height: 50px;
        background-color: rgba(220, 220, 220, 0.5);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 80%;
          width: 80%;
          margin-left: -10px;
          opacity: 0.6;
        }
      }
    }

    .top-container {
      display: flex;
      justify-content: space-between;
      padding: 0 24px;
      height: 100px;
      align-items: center;
      .top-title {
        font-size: 32px;
        font-weight: 700;
      }
      .edit-btn {
        line-height: 50px;
        border-radius: 25px;
        padding: 0 20px;
        background-color: #fff;
        color: #888;
        border: 1px solid #fff;
        display: flex;
        font-size: 28px;
      }
      .edit {
        border-color: #FF6685;
        color: #FF6685;
        padding: 0 10px 0 20px;
      }
      .cuIcon-close {
        color: #FF6685;
        margin-left: 5px;
        line-height: 50px;
      }
    }

    .channel-container {
      .channel-item {
        margin: 0 24px 20px 24px;
        background-color: #fff;
        border-radius: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        .channel-icon {
          width: 50px;
          height: auto;
          display: flex;
          margin-right: 15px;
        }
        .right-con {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1;
          height: 100px;
          line-height: 100px;
          .right-title {
            font-size: 30px;
          }
          .flex {
            display: flex;
            font-size: 28px;
            align-items: center;
          }
          .un-bind {
            font-size: 28px;
            color: #FF6685;
          }
          .reason-btn {
            margin-right: 20px;
            font-size: 24px;
            color: #FF6685;
          }
          .status {
            padding: 0 15px;
            line-height: 40px;
            height: 40px;
            font-size: 24px;
            border-radius: 5px;
          }

          .status2 {
            background-color: #8AE6A8;
            color: #fff;
          }

          .status1 {
            background-color: #FFE6EB;
            color: #FF6685;
          }

          .status3 {
            background-color: #1A1F29;
            color: #fff;
          }
        }
        .cuIcon-right {
          color: #aaa;
          font-size: 20px;
        }
      }
    }

    ::v-deep .van-overlay {
      background-color: rgba(0, 0, 0, .4);
    }

    .van-dialog {
      width: 570px;
      font-size: 30px;
      padding: 30px 40px;

      ::v-deep .van-dialog__header {
        font-size: 34px;
        font-weight: 600;
        padding-top: 15px;
      }

      ::v-deep .van-dialog__content {
        margin-top: 40px;
        text-align: center;
        line-height: 40px;
        .unbind-text {
          background-color: #FFF5F8;
          color: #FF6685;
          text-align: center;
          line-height: 80px;
          margin: 20px 0 30px;
          font-size: 30px;
        }
      }

      ::v-deep .van-dialog__footer {
        margin-top: 50px;
        justify-content: center;

        .van-button {
          font-size: 32px;
          height: 68px;
          border-radius: 50px;
          flex: none;
          width: 220px;

          &.van-dialog__confirm {
            background-color: #000;
            color: #fff;
          }

          &.van-dialog__cancel {
            border: 1px solid #8c8c8c;
            margin-right: 40px;
          }
        }
      }
    }

    .van-popup {
      padding: 20px;
      text-align: center;
      .auth-model {
        padding: 0 24px;
        position: relative;
        .cuIcon-close{
          position:absolute;
          right: 0;
          top: 5px;
          font-size:44px;
          color: #aaa;
        }
        .model-title {
          font-size: 34px;
          font-weight: 700;
          color: #000;
          line-height: 60px;
        }
        .model-channel-title {
          display: flex;
          align-items: center;
          line-height: 80px;
          color: #000;
          .channel-icon {
            width: 80px;
            margin-right: 10px;
          }
          .channel-title-sub {
            font-size: 34px;
          }
        }
        .link-area-con{
          display: flex;
          justify-content: space-between;
          height: 70px;
          line-height: 70px;
          background-color: #f5f5f5;
          border-radius: 10px;
          margin-top: 20px;
          .link-area {
            flex: 1;
            height: 70px;
            text-align: left;
            padding: 0 15px;
            background-color: #f5f5f5;
            font-size: 28px;
            &::-webkit-input-placeholder {
              color: #8A8E99;
              font-size: 28px;
            }
          }
          .copy-btn{
            padding: 0 25px;
            color: #FF6685;
          }
        }
        .upload-con {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          .upload-btn {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #f5f5f5;
            border-radius: 10px;
            width: 240px;
            height: 180px;
            position: relative;
            .image-picker {
              position: absolute;
              width: 100%;
              height: 100%;
              opacity: 0;
            }
            img {
              width: 50px;
            }
            .homepage-image {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
            }
            .sub_tip{
              font-size: 24px;
              margin-top: 10px;
              color: #8a8e99;
            }
            .reUpload {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              text-align: center;
              color: #fff;
              background-color: rgba(0, 0, 0, 0.8);
              line-height: 60px;
              height: 60px;
            }
          }
          .upload-right-con {
            flex: 1;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            text-align: left;
            .right_title {
              font-size: 26px;
            }
            .justify-end{
              display: flex;
              justify-content: flex-end;
              .teach-btn {
                color: #FF6685;
                height: 60px;
                line-height: 60px;
                border-radius: 30px;
                border: 2px solid #eeeeee;
                padding: 0 20px;
                font-size: 26px;
                display: flex;
                box-sizing: content-box;
                align-items: center;
                .cuIcon-questionfill {
                  color: #FF6685;
                  margin-right: 5px;
                  font-size: 28px;
                }
              }
            }
          }
        }
        .fans-con {
          display: flex;
          align-items: center;
          background-color: #f5f5f5;
          border-radius: 10px;
          padding: 0 20px;
          margin-top: 20px;
          height: 80px;
          .fans-label {
            font-size: 27px;
          }
          .fans-input {
            flex: 1;
            padding-left: 20px;
            text-align: left;
            margin: 10px 0;
            font-size: 28px;
            background-color: #f5f5f5;
            &::-webkit-input-placeholder{
              color: #8A8E99;
            }
          }
        }
        .auth-btn {
          line-height: 80px;
          border-radius: 40px;
          background-color: #000;
          text-align: center;
          font-size: 32px;
          color: #fff;
          margin: 60px auto 40px;
          width: 400px;
        }
      }
    }
  }
</style>