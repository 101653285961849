<template>
	<div class="container-wrapper">
		<navbar title="模特认证"></navbar>
		<div class="container-item">
			<div class="con-title">
				<div class="title-text">请选择模特类型</div>
			</div>
			<div class="mote-type-con">
				<div class="mote-type-item-wrap" v-for="(item,index) in mdTypeList" :key="index">
					<div class="mote-type-item" :class="mdtype_select==item.type?'item-select':''"
						@click="chooseMdType(item.type)">{{item.name}}</div>
				</div>
			</div>
		</div>
		<div class="container-item">
			<div class="con-title">
				<div class="title-text">请上传您的视频</div>
			</div>
			<div class="video-item-con">
				<div v-if="!video" class="upload-video">
					<img class="upload-icon"
						src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/edit_album/upload_cao%403x.png"/>
					<div class="upload-text">上传视频</div>
					<input class="input-video" type="file" id='video' capture="camera" accept="video/*"
						@change="chooseVideo">
				</div>
				<div v-else class="upload-video">
					<video class="video" :src="video" controls></video>
					<div class="video-mask">
						<img src="/pages/resource/images/play.png" style="width: 50px;"/>
					</div>
					<i class="iconfont icon-close-bold" @click="deleteVideo"></i>
				</div>
				<div class="upload-tip">
					<div class="tip-title">注意事项：</div>
					<div>1、请上传您的露脸视频、不低于5秒</div>
					<div>2、视频需要清晰，至少露出上半身</div>
					<div>3、视频仅为核实身份，视频不会公开</div>
				</div>
			</div>
		</div>
		<div class="container-item">
			<div class="con-title">
				<div class="title-text">请选择至少{{min_photo_num}}张照片</div>
				<div style="color: #FF6685;display: flex;">
					<i class="iconfont icon-questionfill"></i>
					<div class="require-btn" @click="showModel">认证要求</div>
				</div>
			</div>
			<div class="tip">PS: 点击提交认证后图片开始上传。照片认证通过后作为个人主页相册展示且不可进行修改，请谨慎上传！</div>
			<div class="video-item-con">
				<van-uploader :before-read="chooseImage" multiple>
					<div class="upload-item">
						<img class="upload-icon"
							src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/edit_album/upload_image%403x.png"
							/>
						<div class="upload-text">上传图片</div>
					</div>
				</van-uploader>

				<div class="upload-item" v-for="(item,index) in photos" :key="index">
					<img class="upload-image" :src="item.url"/>
					<div class="fail-mask" v-if="item.status!='success'">
						<div class="fail-text" v-if="item.status=='wait'">等待上传</div>
						<div class="fail-con" v-if="item.status=='fail'">
							<div class="fail-status">上传失败</div>
							<div class="fail-text" @click="reUpload(index,item.file)">重新上传</div>
						</div>
					</div>
					<i class="iconfont icon-close-bold" @click="deletePhotos(index)"></i>
				</div>
				<div class="upload-item" v-if="photos.length%3==1"></div>
			</div>
		</div>
		<div class="submit-btn" @click="submit">提交认证</div>

		<van-dialog v-model="showRequire" title="认证要求" confirm-button-text="我知道了" @confirm="()=>showRequire = false">
			<p>1、近一年的露脸生活照，能清晰看到五官，不能使用相机贴纸。</p>
			<p>2、只允许上传本人图片，半身图和全身图都可以。</p>
			<p>3、图片必须清晰，无水印文字，不要过度美颜、P图。</p>
			<p>4、光线昏暗、背景杂乱的图片不要上传。</p>
			<p>5、不能上传艺术照、写真、婚纱照等，生活照即可。</p>
			<p>6、照片类型不能同质化。</p>
			<p>7、照片自拍不能超过5张。</p>
			<p>8、未满18岁的用户只开放童模认证。</p>
			<p>9、上传照片只有一次机会，审核通过后不能修改。</p>
		</van-dialog>
	</div>
</template>

<script>
	import {
		uploadFile
	} from "../../util/uploadBaogao.js"
	import navbar from "@/components/navbar.vue"
	export default {
		data() {
			return {
				mdTypeList: [
					{
					name: "男模",
					type: 101
				}, {
					name: "女模",
					type: 102
				}, {
					name: "童模",
					type: 103
				}, {
					name: "中老年女模",
					type: 104
				}, {
					name: "中老年男模",
					type: 105
				}, {
					name: "情侣模特",
					type: 106
				}, {
					name: "亲子模特",
					type: 107
				}, {
					name: "宠模",
					type: 108
				}],
				mdtype_select: 0,
				video: "",
				video_cloud_url: "",
				photos: [],
				min_photo_num: 18,
				modelName: "reasonModel",
				showRequire: false
			}
		},
		components:{
			navbar
		},
		methods: {
			showModel(){
				this.showRequire = true;
			},
			chooseMdType(type) {
				this.mdtype_select = type
			},
			chooseVideo(e) {
				console.log("选择视频");
				let video_file = e.target.files[0];
				console.log(video_file);
				if(video_file){
					this.$toast.loading({
						message: "上传中",
						duration: 0,
					});
				}
				uploadFile(video_file, "user/video/auth_video", "mp4").then(res => {
					console.log("视频上传返回结果", res);
					if (res.status == "fail") {
						this.$toast("视频上传失败，请重新选择")
					} else {
						console.log("视频上传成功！");
						this.video = res.url
					}
					this.$toast.clear();
				})
			},
			chooseImage(files) {
				console.log("选择照片");
				let file = [];
				if (!Array.isArray(files)) {
					file.push(files)
				} else {
					file = files;
				}
				if(file.length){
					this.$toast.loading({
						message: "上传中",
						duration: 0,
					});
				}
				for (let i = 0; i < file.length; i++) {
					console.log(file[i]);
					uploadFile(file[i], "user/img/photo_album").then(res => {
						console.log(res);
						this.photos.push({
							status: res.status,
							url: res.status=="fail"?this.getFileURL(res.url):res.url,
							file:res.status=="fail"?res.url:""
						});
						if(this.photos.length >= file.length){
							this.$toast.clear();
						}
					})
				}
			},
			reUpload(index,file) {
				console.log("重新上传");
				if(file){
					this.$toast.loading({
						message: "上传中",
						duration: 0,
					});
				}
				uploadFile(file, "user/img/photo_album").then(res => {
					console.log(res);
					this.photos[index] = {
						status: res.status,
						url: res.status=="fail"?this.getFileURL(res.url):res.url
					};
					this.$toast.clear();
				})
			},
			deletePhotos(index) {
				console.log("删除照片");
				this.photos.splice(index,1)
			},
			deleteVideo(){
				this.video = "";
			},
			//提交信息
			commitMassage() {
				return new Promise(resolve => {
					console.log("保存提交信息===============");
					let photo_list = this.photos.map(item => item.url);
					let sentData = {
						access_token: localStorage.getItem("access_token"),
						md_type: this.mdtype_select,
						auth_video: this.video,
						photo_list: photo_list.join(";")
					};
					let params = this.$addParams(sentData);
					console.log("提交初审认证请求参数", sentData);
					this.$axios.post(
							process.env.VUE_APP_URL + "/mobi/mote/mtauthcommitone",
							this.$qs.stringify(params), {
								headers: {
									"Content-Type": "application/x-www-form-urlencoded",
								},
							}
						)
						.then((res) => {
							if (res.data.status == "y") {
								this.$router.replace({
									name: "waitAuth",
									query: {
										title: "mtAuth"
									}
								});
							} else {
								this.$toast(res.data.error)
							}
						});

				})
			},
			async submit() {
				if (!this.mdtype_select) {
					this.$toast("请选择认证模特类型");
					return
				}
				if (!this.video) {
					this.$toast("请选择视频");
					return
				}
				if (this.photos.length < this.min_photo_num) {
					this.$toast("最少上传" + this.min_photo_num + "张照片");
					return
				}

				for (let i = 0; i < this.photos.length; i++) {
					if (this.photos[i].status == "fail") {
						this.$toast("有未上传成功的图片，请重新上传或删除后再提交");
						return
					}
				}

				await this.commitMassage()
			}
		},
		activated(){
			this.showRequire = true;
		}
	}
</script>

<style lang="scss" scoped>
	.new-page {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: #000;
	}

	@keyframes slide-in {
		0% {
			transform: translateX(100%);
		}

		100% {
			transform: translateX(0);
		}
	}

	@keyframes slide-out {
		0% {
			transform: translateX(0);
		}

		100% {
			transform: translateX(100%);
		}
	}

	.slide_in {
		animation: slide-in 300ms ease-in;
	}

	.slide_out {
		animation: slide-out 300ms ease-in;
	}

	.container-wrapper {
		position: fixed;
		width: 100vw;
		height: 100vh;
		background-color: #fff;
		overflow-y: auto;
	}

	.container-item {
		padding-top: 20px;
	}

	.mote-type-con {
		display: flex;
		flex-wrap: wrap;
		padding: 0 16px;
	}

	.mote-type-item-wrap {
		width: 25%;
		padding: 8px;
	}

	.mote-type-item {
		border: 2px solid #F1F1F1;
		border-radius: 10px;
		text-align: center;
		line-height: 64px;
		background-color: #F1F1F1;
		color: #888888;
		font-size: 28px;
	}

	.item-select {
		background-color: #FFEBEF;
		color: #FF6685;
		border-color: #FF6685;
	}

	.con-title {
		display: flex;
		justify-content: space-between;
		line-height: 60px;
		font-size: 30px;
		padding: 0 24px;

		.icon-questionfill {
			font-size: 24px;
			margin-right: 3px;
		}
	}

	.title-text {
		font-size: 32px;
	}

	.require-btn {
		font-size: 28px;
	}

	.video-item-con {
		display: flex;
		justify-content: space-between;
		padding: 10px 24px 0;
		flex-wrap: wrap;
	}

	.upload-video {
		width: 224px;
		height: 180px;
		margin-right: 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	  border: 1px solid #ddd;
		border-radius: 10px;
		overflow: hidden;
		background-color: #f1f1f1;
		position: relative;
	}

	.input-video {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;
		-ms-filter: 'alpha(opacity=0)';
	}

	.upload-video .video {
		width: 100%;
		height: 100%;
		display: block;
		background-color: #000;
	}

	.video-mask {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.upload-tip {
		flex: 1;
		padding: 10px 20px;
		box-sizing: border-box;
		overflow: hidden;
		font-size: 24px;
		line-height: 40px;
		color: #888;
		background-color: #FFEBEF;
		border-radius: 10px;
	}

	.tip-title {
		color: #FF6685;
		font-weight: 700;
		font-size: 26px;
	}

	.upload-item {
		width: 224px;
		height: 224px;
		margin-right: 15px;
		margin-bottom: 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 1px solid #ddd;
		border-radius: 10px;
		overflow: hidden;
		position: relative;
	}

	.upload-item:first-child {
		background-color: #F1F1F1;
	}

	.upload-image {
		width: 100%;
		height: 100%;
		display: block;
		border-radius: 10px;
		overflow: hidden;
		object-fit: cover;
	}

	.upload-item:nth-child(3n) {
		margin-right: 0;
	}

	.upload-icon {
		width: 50px;
		height: 50px;
		display: block;
		margin-bottom: 10px;
	}

	.upload-text {
		color: #888;
		font-size: 24px;
	}

	.fail-mask {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: rgba(0, 0, 0, 0.5);
	}
	.fail-con{
		text-align: center;
	}

	.fail-status {
		font-size: 24px;
		margin-bottom: 10px;
		color: #fff;
	}

	.fail-mask .fail-text {
		padding: 0 20px;
		line-height: 38px;
		border-radius: 20px;
		font-size: 24px;
		color: #fff;
		border: 1px solid #fff;
	}

	.icon-close-bold {
		position: absolute;
		right: 0;
		top: 0;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		border-radius: 0 10px 0 10px;
		background-color: rgba(0, 0, 0, 0.8);
		color: #fff;
	}

	.submit-btn {
		position: fixed;
		width: 300px;
		text-align: center;
		bottom: 100px;
		line-height: 80px;
		border-radius: 40px;
		color: #fff;
		font-size: 32px;
		background-color: #000;
		left: 50%;
		transform: translateX(-50%);
		box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, 0.5);
	}

	.content {
		text-align: left;
		line-height: 36px;
		padding: 30px 10px;
	}

	.content div {
		margin-bottom: 8px;
	}

	.tip {
		font-size: 24px;
		color: #aaa;
		padding: 0 24px 10px;
		line-height: 34px;
	}

	.van-overlay {
		background-color: rgba(0, 0, 0, .4);
	}

	.van-dialog {
		width: 600px;
		font-size: 30px;
		padding: 30px 40px;

		.van-dialog__header {
			font-size: 34px;
			font-weight: 600;
			padding-top: 5px;
		}

		.van-dialog__content {
			margin-top: 30px;
			text-align: center;
			line-height: 40px;
			p {
				font-size: 28px;
				text-align: left;
				color: #8A8E99;
			}
		}

		.van-dialog__footer {
			margin-top: 30px;
			justify-content: center;

			.van-button {
				font-size: 32px;
				height: 68px;
				border-radius: 50px;
				flex: none;
				width: 220px;

				&.van-dialog__confirm {
					background-color: #000;
					color: #fff;
				}

				&.van-dialog__cancel {
					border: 1px solid #8c8c8c;
					margin-right: 40px;
				}
			}
		}
	}
</style>
