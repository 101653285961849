<template>
	<div class="mask" :class="show?'mask-show':''" @click="hideMask">
		<div class="album-model" :class="show?'model-show':''" @click.stop="preventEvent">
			<van-swipe class="album-swipe" :initial-swipe="current">
				<van-swipe-item v-for="(item,index) in swiper_photos" :key="item">
					<div class="swiper-img-con">
						<img class="swiper-img" :src="addWaterMark(item,'mjx')">
					</div>
				</van-swipe-item>
				
				<template v-if="swiper_videos.length!=0">
					<van-swipe-item v-for="(item,index) in swiper_videos" :key="item">
						<div style="position: relative;">
							<video class="swiper-video" :src="item"></video>
							<div class="video-mask" @click.stop="toVideoDetail(item)">
								<img class="play-icon" src="../../public/static/play.png"></img>
							</div>
						</div>
					</van-swipe-item>
				</template>
			</van-swipe>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			show: {
				type: Boolean,
				default: false
			},
			swiper_photos: {
				type: Array,
				default: ()=>[]
			},
			swiper_videos: {
				type: Array,
				default: ()=>[]
			},
			current: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {}
		},
		methods: {
			addWaterMark(url,type) {
				if (type == "mjx") {
					var compressParams = "?imageView2/2/w/750"
				} else {
					var compressParams = "?imageView2/1/w/500/h/700"
				}
				var waterMark =
					"|watermark/1/blogo/2/image/aHR0cDovL2ptc3RhdGljLTEyNTU0OTc5MTYuY29zLmFwLXNoYW5naGFpLm15cWNsb3VkLmNvbS9jb21tb24vd2F0ZXJtYXJrZXIucG5n/dissolve/90/dx/10/dy/10"
				var newUrl = url + compressParams + waterMark
				newUrl = newUrl.replace('https://img.jimeimadou.com',
						'https://jmstatic-1255497916.cos-website.ap-shanghai.myqcloud.com')
				return newUrl
			},
			hideMask() {
				this.$emit('hideMask', false)
			},
			preventEvent() {
				return
			},
			toVideoDetail(url) {
				this.$router.push({
					name: "videoDetail",
					query:{
						url
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.mask {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		z-index: 101;
		background-color: rgba(0, 0, 0, 0);
		transition: 0.3s;
		visibility: hidden;
	}

	.mask-show {
		background-color: rgba(0, 0, 0, 0.5);
		visibility: visible;
	}

	.album-model {
		width: auto;
		position: relative;
		visibility: hidden;
		opacity: 0;
		border: 10px solid #fff;
		border-radius: 5px;
	}

	.model-show {
		visibility: visible;
		opacity: 1;
		transform: scale(1);
		transition: 0.3s;
	}
	
	.album-swipe{
		width: 600px;
		height: 900px;
		background-color: #000;
	}
	.swiper-img-con{
		width: 600px;
		height: 900px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.swiper-img{
		width: 600px !important;
	}
	.swiper-video{
		width: 600px !important;
		height: 900px;
	}

	.video-mask {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.video-mask .play-icon {
		width: 60px;
		height: 60px;
		object-fit: content;
	}
</style>
