<template>
	<div class="container-wrapper">
		<navbar title="亲情相册"></navbar>
		<div class="progress-container">
			<div class="progress-item">
				<img v-if="current_step==1" class="icon"
					src="https://img.jimeimadou.com/common/resource/step_icon/step2%403x.png" mode="aspectFit"></img>
				<img v-if="current_step==2" class="icon"
					src="https://img.jimeimadou.com/common/resource/step_icon/step3%403x.png" mode="aspectFit"></img>
				<div>填写基本信息</div>
			</div>
			<div class="progress-item">
				<img v-if="current_step==1" class="icon"
					src="https://img.jimeimadou.com/common/resource/step_icon/step1%403x.png" mode="aspectFit"></img>
				<img v-if="current_step==2" class="icon"
					src="https://img.jimeimadou.com/common/resource/step_icon/step2%403x.png" mode="aspectFit"></img>
				<div>上传照片</div>
			</div>
		</div>

		<div v-if="current_step==1" class="containerA">
			<div class="msg-container">
				<div class="msg-item" @click="showModel('sizeModel','mdType',select_mt_type)">
					<div class="msg-name">模特类型</div>
					<div class="msg-right">
						<div v-if="select_mt_type">{{mdTypes[select_mt_type]}}</div>
						<div v-else class="no-select">请选择</div>
						<i class="iconfont icon-you"></i>
					</div>
				</div>
				<template v-if="select_mt_type==108">
					<div class="msg-item">
						<div class="msg-name">宠物类别</div>
						<div class="msg-right">
							<input class="msg-input" v-model="pet_type" placeholder="请输入宠物类别" />
						</div>
					</div>
				</template>
				<template v-else>
					<div class="msg-item" @click="showDatePikcer">
						<div class="msg-name">生日</div>
						<div class="msg-right">
							<div v-if="birthday">{{birthday}}</div>
							<div v-else class="no-select">请选择</div>
							<i class="iconfont icon-you"></i>
						</div>
					</div>
					<div class="msg-item">
						<div class="msg-name">身高</div>
						<div class="msg-right">
							<input class="msg-input" v-model="height" placeholder="请输入身高" />
							<div class="unit">CM</div>
						</div>
					</div>
					<div class="msg-item">
						<div class="msg-name">体重</div>
						<div class="msg-right">
							<input class="msg-input" v-model="weight" placeholder="请输入体重" />
							<div class="unit">KG</div>
						</div>
					</div>
					<div class="msg-item" @click="showModel('sizeModel','coat',select_coat_size)">
						<div class="msg-name">上衣</div>
						<div class="msg-right">
							<div v-if="select_coat_size">{{select_coat_size}}</div>
							<div v-else class="no-select">请选择</div>
							<i class="iconfont icon-you"></i>
						</div>
					</div>
					<div class="msg-item" @click="showModel('sizeModel','trousers',select_trousers_size)">
						<div class="msg-name">裤子</div>
						<div class="msg-right">
							<div v-if="select_trousers_size">{{select_trousers_size}}</div>
							<div v-else class="no-select">请选择</div>
							<i class="iconfont icon-you"></i>
						</div>
					</div>
					<div class="msg-item" @click="showModel('sizeModel','shoes',select_shoes_size)">
						<div class="msg-name">鞋子</div>
						<div class="msg-right">
							<div v-if="select_shoes_size">{{select_shoes_size}}</div>
							<div v-else class="no-select">请选择</div>
							<i class="iconfont icon-you"></i>
						</div>
					</div>
					<div class="msg-item" v-if="select_mt_type==102" @click="showModel('sizeModel','cup',select_cup_size)">
						<div class="msg-name">罩杯</div>
						<div class="msg-right">
							<div v-if="select_cup_size">{{select_cup_size}}</div>
							<div v-else class="no-select">请选择</div>
							<i class="iconfont icon-you"></i>
						</div>
					</div>
				</template>
			</div>
			<div class="btn-container">
				<div class="next-btn" @click="toNextStep">确认并上传照片</div>
			</div>
		</div>
		<div v-if="current_step==2" class="containerB">
			<div class="msg-container msg-container-B">
				<div class="upload-title">
					<div class="title-l">上传图片</div>
					<div class="title-r"><i class="iconfont icon-question-circle"></i>不合格照片说明</div>
				</div>
				<div class="tips">您上传的亲情相册待平台审核通过后将被展示给商家，上传越多接单率越高</div>
				<div class="upload-con">
					<van-uploader :before-read="chooseImage" multiple>
						<div class="upload-item">
							<img class="upload-icon"
								src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/edit_album/upload_image%403x.png"
								mode="aspectFit"></img>
							<div>上传图片</div>
						</div>
					</van-uploader>

					<div class="upload-item" v-for="(item,index) in images" :key="index">
						<img class="upload-image" :src="item" />
						<i class="iconfont icon-close-bold" @click="delete_image(index)"></i>
					</div>
				</div>
				<div class="btn-container">
					<div class="last-btn" @click="toLastStep">上一步</div>
					<div class="last-btn" @click="createAlbum">确认完成</div>
				</div>
			</div>
		</div>

		<van-popup class="vant_popup_box" position="bottom" v-model="isShowPicker">
			<van-datetime-picker v-model="currentDate" type="date" item-height="1.2rem" title="选择年月日" :min-date="minDate"
				:max-date="maxDate" @confirm="getDate" @cancel="hideModel">
			</van-datetime-picker>
		</van-popup>

		<size-model :show="modelName=='sizeModel'" :type="size_type" :value="select_size" :isChild="select_mt_type==103" @cancel="hideModel"
			@confirm="chooseSize"></size-model>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	import sizeModel from "@/components/size-model.vue"
	import {
		uploadFile
	} from "../../../util/uploadBaogao.js"
	export default {
		data() {
			return {
				mt_id: 0,
				current_step: 1,
				mdTypes: this.mdTypes,
				mdTypeList: [{
					name: "男模",
					type: 101
				}, {
					name: "女模",
					type: 102
				}, {
					name: "童模",
					type: 103
				}, {
					name: "中老年女模",
					type: 104
				}, {
					name: "中老年男模",
					type: 105
				}, {
					name: "情侣模特",
					type: 106
				}, {
					name: "亲子模特",
					type: 107
				}, {
					name: "宠模",
					type: 108
				}],
				modelName: "",
				select_mt_type:0,
				select_coat_size:"",
				select_trousers_size:"",
				select_shoes_size:"",
				select_cup_size:"",
				
				birthday: null,
				height: "",
				weight: "",
				pet_type: "",
				images: [],
				min_length: 10,
				has_created: false,
				isShowPicker: false,
				minDate: new Date(1960, 1, 1),
				maxDate: new Date(),
				currentDate: new Date(),
				size_type:"",
				select_size:""
			}
		},
		components: {
			navbar,
			sizeModel
		},
		activated() {
			this.has_created = false
			let family_album_info = localStorage.getItem("family_album_info")
			if (family_album_info) {
				family_album_info = JSON.parse(family_album_info)
				console.log("编辑的亲情相册信息", family_album_info)
				this.select_mt_type = parseInt(family_album_info.md_type)
				this.height = family_album_info.height
				this.weight = family_album_info.weight
				this.select_coat_size = family_album_info.coat
				this.select_trousers_size = family_album_info.trousers
				this.select_shoes_size = family_album_info.shoes
				this.select_cup_size = family_album_info.cups
				this.images = family_album_info.photo_list
				this.mt_id = family_album_info.mt_id
				this.birthday = family_album_info.birthday
			}
		},
		deactivated() {
			localStorage.removeItem('family_album_info')
		},
		methods: {
			chooseImage(files) {
				console.log("选择照片")
				let file = [];
				if (!Array.isArray(files)) {
					file.push(files)
				} else {
					file = files;
				}
				if(file.length){
					this.$toast.loading({
						message: "上传中",
						duration: 0
					});
				}
				for (let i = 0; i < file.length; i++) {
					console.log(file[i])
					uploadFile(file[i], "user/img/mjx_image").then(res => {
						console.log("上传结果====", res)
						if (res.status == "success") {
							this.images.push(res.url)
						}
						if(this.images.length >= file.length){
							this.$toast.clear();
						}
					})
				}
			},
			createAlbum() {
				if (this.images.length < this.min_length) {
					this.$toast(`最少上传${this.min_length}张图片`)
					return
				}
				if (this.has_created) {
					return
				}
				this.has_created = true


				let sentData = {
					access_token: localStorage.getItem("access_token"),
					md_type: this.select_mt_type,
					birthday: this.birthday,
					height: this.height,
					weight: this.weight,
					coat: this.select_coat_size,
					trousers: this.select_trousers_size,
					shoes: this.select_shoes_size,
					cups: this.select_cup_size,
					photo_list: this.images.join(";")
				}
				if (this.select_mt_type == 108) {
					sentData.pat_breeds = this.pet_type
				}
				if (this.mt_id) {
					sentData.mt_id = this.mt_id
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/createfamily",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "y") {
							if (this.mt_id) {
								this.$toast("相册已修改，等待审核中")
								setTimeout(() => {
									this.$router.back(-2);
								}, 1200)
							} else {
								this.$toast("创建成功，等待审核中")
								setTimeout(() => {
									this.$router.back(-1);
								}, 1200)
							}
						} else {
							this.has_created = false
							this.$toast(res.data.error)
						}
					});
			},
			delete_image(index) {
				this.images.splice(index, 1)
			},
			chooseBirthday(e) {
				this.setData({
					birthday: e.detail.value
				})
			},
			showDatePikcer() {
				this.isShowPicker = true
			},
			showModel(model_name, size_type, select_size) {
				if (model_name == 'sizeModel') {
					this.size_type = size_type
					this.select_size = select_size
				}
				this.modelName = model_name
			},
			hideModel() {
				this.modelName = ""
				this.isShowPicker = false
			},
			//选择尺码或者模特类型
			chooseSize(e) {
				this.hideModel()
				// console.log(`选中的 ${e.type} 是 ${e.value}`)
				switch (e.type) {
					case "mdType":
						this.select_mt_type = e.value
						this.select_coat_size = ""
						this.select_trousers_size = ""
						this.select_shoes_size = ""
						this.select_cup_size = ""
						break;
					case "coat":
						this.select_coat_size = e.value
						break;
					case "trousers":
						this.select_trousers_size = e.value
						break;
					case "shoes":
						this.select_shoes_size = e.value
						break;
					case "cup":
						this.select_cup_size = e.value
						break;
					default:
						break;
				}
			},
			getDate(e) {
				console.log(this.formatDate(e))
				this.birthday = this.formatDate(e)
				this.hideModel()
			},
			toNextStep() {
				if (!this.select_mt_type) {
					this.$toast("请选择模特类型")
					return
				}
				if (this.select_mt_type!=108) {
					if (!this.birthday) {
						this.$toast("请选择出生日期")
						return
					}
					if (!this.height) {
						this.$toast("请输入身高")
						return
					}
					if (!this.weight) {
						this.$toast("请输入体重")
						return
					}
					if (!this.select_coat_size) {
						this.$toast("请选择上衣尺寸")
						return
					}
					if (!this.select_trousers_size) {
						this.$toast("请选择裤子尺寸")
						return
					}
					if (!this.select_shoes_size) {
						this.$toast("请选择鞋子尺寸")
						return
					}
					if (this.select_mt_type == 102 && !this.select_cup_size) {
						this.$toast("请选择罩杯尺寸")
						return
					}
				}else if (!this.pet_type) {
					this.$toast("请输入宠物类别")
					return
				}
				this.current_step = 2
			},
			toLastStep() {
				this.current_step = 1
			},

			formatDate(date) {
				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				month = month.toString().padStart(2, '0')
				var day = date.getDate();
				day = day.toString().padStart(2, '0')
				return year + "-" + month + "-" + day;
			}
		},
	}
</script>

<style lang="scss" scoped>
	.container-wrapper{
		overflow-y: auto;
		background-color: #f1f1f1;
	}
	.progress-container {
		padding: 0 40px;
		display: flex;
		justify-content: space-around;
		align-items: center;
		height: 160px;
	}

	.progress-item {
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #333;
	}

	.progress-item .icon {
		width: 50px;
		height: 50px;
		display: block;
		margin-bottom: 10px;
	}

	.msg-container {
		background-color: #fff;
	}

	.msg-container-B {
		padding: 20px 24px;
	}

	.msg-item {
		display: flex;
		justify-content: space-between;
		margin: 0 24px;
		line-height: 100px;
		border-bottom: 1px solid #f5f5f5;
	}

	.msg-item:last-child {
		border: 0;
	}

	.msg-item .msg-name {
		font-size: 30px;
	}

	.msg-item .msg-right {
		display: flex;
		align-items: center;
		font-size: 26px;

		.no-select {
			color: #aaa;
		}
	}

	.msg-item .msg-input {
		height: 100px;
		text-align: right;
	}

	.unit {
		font-size: 26px;
		margin-left: 10px;
	}

	.msg-item .icon-you {
		color: #aaa;
		font-size: 20px;
		margin-left: 5px;
		line-height: normal;
	}

	.placeholder {
		color: #aaa;
		font-size: 24px;
	}

	.sku-model {
		border-radius: 20px 20px 0 0 !important;
		overflow: hidden;
	}

	.sku-model .model-title {
		font-size: 34px;
		font-weight: 700;
		line-height: 100px;
		color: #000;
	}

	.sku-model .sku-con {
		padding: 0 33px;
		display: flex;
		flex-wrap: wrap;
	}

	.sku-item-con {
		width: 25%;
		padding: 10px;
	}

	.sku-item {
		line-height: 70px;
		border-radius: 15px;
		border: 1px solid #eee;
		background-color: #eee;
		color: #333;
		font-size: 26px;
	}

	.sku-sel {
		background-color: #FFEBEF;
		color: #FF6685;
		border-color: #FF6685;
	}

	.model-btn {
		width: 360px;
		border-radius: 40px;
		line-height: 80px;
		background-color: #000;
		color: #fff;
		font-size: 30px;
		margin: 60px auto;
	}

	.next-btn {
		border-radius: 40px;
		line-height: 80px;
		background-color: #000;
		color: #fff;
		font-size: 30px;
		width: 350px;
	}

	.last-btn {
		border-radius: 40px;
		line-height: 80px;
		background-color: #fff;
		color: #333;
		font-size: 30px;
		width: 250px;
	}

	.last-btn:last-child {
		background-color: #000;
		color: #fff;
		margin-left: 50px;
	}

	.btn-container {
		position: fixed;
		bottom: 150px;
		left: 0;
		text-align: center;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.upload-title {
		display: flex;
		justify-content: space-between;
		line-height: 45px;

		.title-l {
			font-size: 32px;
		}

		.title-r {
			font-size: 24px;
			color: #aaa;
		}

		.icon-question-circle {
			font-size: 24px;
		}
	}

	.tips {
		color: #aaa;
		font-size: 24px;
		line-height: 32px;
		margin-top: 6px;
	}

	.cuIcon-questionfill {
		font-size: 24px;
		color: rgba(0, 0, 0, 0.2);
	}

	.upload-con {
		display: flex;
		flex-wrap: wrap;
		padding-top: 20px;
	}

	.upload-item {
		width: 165px;
		height: 165px;
		border-radius: 5px;
		margin-right: 14px;
		margin-bottom: 14px;
		overflow: hidden;
		position: relative;
	}

	.upload-item:first-child {
		background-color: #f1f1f1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #aaa;
		font-size: 24px;
	}

	.upload-item:nth-child(4n) {
		margin-right: 0;
	}

	.upload-icon {
		width: 40px;
		height: 40px;
		margin-bottom: 10px;
	}

	.upload-image {
		width: 100%;
		height: 100%;
		display: block;
		object-fit: cover;
	}

	.upload-item .icon-close-bold {
		position: absolute;
		right: 0;
		top: 0;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		color: #fff;
		background-color: rgba(0, 0, 0, 0.8);
		border-radius: 0 5px 0 5px;
		font-size: 24px;
	}

	::-webkit-input-placeholder {
		/* WebKit, Blink, Edge */
		color: #aaa;
	}

	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #aaa;
	}

	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #aaa;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #aaa;
	}

	.vant_popup_box {
		border-radius: 20px 20px 0 0;
		text-align: center;

		.sku-model .model-title {
			font-size: 34px;
			font-weight: 700;
			line-height: 100px;
			color: #000;
		}

		.sku-model .sku-con {
			padding: 0 33px;
			display: flex;
			flex-wrap: wrap;
		}

		.sku-item-con {
			width: 25%;
			padding: 10px;
		}

		.sku-item {
			line-height: 70px;
			border-radius: 15px;
			border: 1px solid #eee;
			background-color: #eee;
			color: #333;
			font-size: 26px;
		}

		.sku-sel {
			background-color: #FFEBEF;
			color: #FF6685;
			border-color: #FF6685;
		}

		.model-btn {
			width: 360px;
			border-radius: 40px;
			line-height: 80px;
			background-color: #000;
			color: #fff;
			font-size: 30px;
			margin: 60px auto;
		}
	
	}
</style>
