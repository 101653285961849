<template>
  <div class="edit-card-wrap">
    <div class="card-title">
      <div class="back_img">
        <img class="back_img"
             src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/Back%403x.png"
             @click="back_list"
             alt/>
      </div>
      小红书名片
    </div>

    <div class="card-container">
      <div class="top-container" v-if="showOptions">
        <div class="type-container">
          <img class="type-icon" src="https://img.jimeimadou.com/common/icon/channel02.png" />
          <div>小红书</div>
        </div>

        <div class="link-container">
          <input class="link-input" type="text" v-model="link_url" placeholder="请输入小红书个人主页链接"/>
          <van-icon name="clear" v-if="link_url" class="cuIcon-roundclosefill" @click="clearInput"/>
          <div class="copy-btn" @click="copyToInput">粘贴</div>
        </div>
        <div class="upload-con">
          <div v-if="!homePage_image" class="upload-btn">
            <img class="upload-icon" src="https://img.jimeimadou.com/common/resource/edit_album/upload_image%403x.png" />
            <div class="upload-tip">点击上传主页截图</div>
            <input class="image-picker" ref="imagePicker" type="file" @change="afterReadImage" accept="image/*"/>
          </div>
          <div v-if="homePage_image" class="upload-btn">
            <img class="homepage-image" :src="homePage_image"/>
            <div class="reUpload">重新上传</div>
            <input class="image-picker" ref="imagePicker" type="file" @change="afterReadImage" accept="image/*"/>
          </div>
          <div class="upload-right-con">
            <div><span style="color: #FF6685;">注：</span>链接截图仅用于平台审核！</div>
            <div class="flex justify-end">
              <div class="teach-btn" @click="toCourse(1)">
                <van-icon name="question" class="cuIcon-questionfill"/>
                <div>认证教程</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="xhs-msg-wrap">
        <div class="sub-title" v-if="showOptions">请补充以下信息</div>
        <div class="msg-container">
          <div class="msg-item">
            <div class="msg-label">粉丝数</div>
            <input class="msg-input" type="number" v-model="fans_num"  placeholder="请输入粉丝数量"/>
          </div>
          <div class="msg-item">
            <div class="msg-label">点赞数</div>
            <input class="msg-input" type="number" v-model="good_num" placeholder="请输入点赞数量"/>
          </div>
          <div class="msg-item">
            <div class="msg-label">收藏数</div>
            <input class="msg-input" type="number" v-model="collect_num" placeholder="请输入收藏数量"/>
          </div>
          <div class="msg-item">
            <div class="msg-label">微信号</div>
            <input class="msg-input" type="text" v-model="wechat" placeholder="请输入微信号"/>
          </div>
          <div class="msg-item">
            <div class="msg-label">手机号</div>
            <input class="msg-input" type="number" v-model="phone" placeholder="请输入手机号"/>
          </div>
          <div class="msg-item">
            <div class="msg-label">写简介</div>
            <textarea class="msg-textarea" v-model="introduce" placeholder="请填写个人简介,商家也可以看到哦~"/>
            <div :class="['font-num', introduce.length>100 ? 'text-red' : '']">{{introduce.length}}/100</div>
          </div>
        </div>
        <div class="msg-tip" v-if="showOptions">
          <div>点赞与收藏数请点击小红书“获赞与收藏”，查看后填入对应数据。</div>
          <div class="msg-tip-bottom">
            <div style="color: #FF6685;">请填入真实数据，否则将会封号处理。</div>
            <div style="color: #66B3FF;" @click="toCourse(2)">如何查看</div>
          </div>
        </div>
      </div>

      <div class="spec-wrap">
        <div class="sub-title">账号内容领域</div>
        <div class="field-box">
          <div class="field-container">
            <div class="field-item-con" v-for="(item,index) in fieldList" :key="index">
              <div :class="['field-item', item.checked ? 'field-select' : '']" @click="chooseFieldItem(index)">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="spec-wrap">
        <div class="sub-title">特色标签</div>
        <div class="field-box">
          <div class="field-container">
            <div class="field-item-con" v-for="(item,index) in tagList" :key="index">
              <div :class="['field-item', item.checked ? 'field-select' : '' ]" @click="chooseTagItem(index)">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>

      <div style="height: 150px;"></div>

    </div>

    <div class="save-btn" @click="saveMessage">保存</div>

    <van-popup v-model="showImage" position="right" :style="{ width: '80%', height: '100%' }">
      <img :src="image_url" style="width: 100%"/>
    </van-popup>

  </div>
</template>

<script>
  import {uploadFile} from '../../util/uploadBaogao'
  export default {
    name: "EditCard",
    data(){
      return {
        fieldList: [],
        tagList: [],
        link_url: "",
        homePage_image: "",
        fans_num: "",
        good_num: "",
        collect_num: "",
        wechat: "",
        phone: "",
        introduce: "",
        tag_ids: "",
        spec_ids: "",
        showOptions:true,
        flag:false,
        showImage: false,
        image_url: "",
      }
    },
    methods:{
      back_list(){
        this.$router.go(-1);
      },

      getFieldList() {
        let sentData = {
          access_token: localStorage.getItem("access_token")
        };
        let params = this.$addParams(sentData);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/xhs/xhscate",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then(res => {
          let result = JSON.parse(res.data.data);
          result.forEach(item=>{
            item.checked = false;
          });
          if(this.tag_ids){
            let tag_ids = this.tag_ids.split(",");
            result.forEach(item=>{
              item.checked = tag_ids.includes(item.id.toString())
            })
          }
          this.fieldList = result
        })
      },

      getTagList() {
        let sentData = {
          access_token: localStorage.getItem("access_token")
        };
        let params = this.$addParams(sentData);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/xhs/xhsspeclable",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then(res => {
          let result = JSON.parse(res.data.data);
          result.forEach(item=>{
            item.checked = false;
          });
          if(this.spec_ids){
            let spec_ids = this.spec_ids.split(",");
            result.forEach(item=>{
              item.checked = spec_ids.includes(item.id.toString())
            })
          }
          this.tagList = result
        })
      },

      toCourse(type) {
        let url = "";
        switch (type) {
          case 1:
            url = "https://img.jimeimadou.com/user/img/auth_course/jc_xhs.png";
            break;
          case 2:
            url = "https://img.jimeimadou.com/user/img/auth_course/look_jc_xhs.png";
            break;
          default:
            break;
        }
        this.image_url = url;
        this.showImage = true;
      },

      saveMessage() {
        if(this.showOptions){
          this.createCard()
        }else{
          this.upDateCard()
        }
      },

      createCard(){
        if (!this.link_url) {
          this.$toast("请填写个人主页链接");
          return
        }
        if (!this.homePage_image) {
          this.$toast("请上传个人主页截图");
          return
        }
        if (!this.wechat) {
          this.$toast("请填写微信号");
          return
        }
        let tag_ids = [];
        this.fieldList.forEach(item=>{
          if(item.checked){
            tag_ids.push(item.id)
          }
        });
        let spec_ids = [];
        this.tagList.forEach(item=>{
          if(item.checked){
            spec_ids.push(item.id)
          }
        });

        if(this.flag){
          return
        }
        this.flag = true;
        let sendData = {
          auth_type: 3,
          auth_url: this.link_url,
          auth_attach: this.homePage_image,
          fans_num: this.fans_num,
          like_count: this.good_num,
          collected_count: this.collect_num,
          weixin: this.wechat,
          phone: this.phone,
          desc: this.introduce,
          tag_ids: tag_ids.join(","),
          spec_ids: spec_ids.join(","),
          access_token: localStorage.getItem('access_token')
        };
        console.log("提交小红书认证请求参数", sendData);
        let params = this.$addParams(sendData);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/mote/createchannel",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then(res => {
          if(res.data.status=="n"){
            this.$toast(res.data.error);
            this.flag = false;
            return
          }
          // wx.redirectTo({
          //   url: '/packageA/wait_auth/wait_auth?title=redBookAuth',
          // })
          this.back_list();
        })
      },

      upDateCard(){
        let tag_ids = [];
        this.fieldList.forEach(item=>{
          if(item.checked){
            tag_ids.push(item.id)
          }
        });
        let spec_ids = [];
        this.tagList.forEach(item=>{
          if(item.checked){
            spec_ids.push(item.id)
          }
        });
        let sendData = {
          fans_num: this.fans_num,
          like_count: this.good_num,
          collected_count: this.collect_num,
          weixin: this.wechat,
          phone: this.phone,
          desc: this.introduce,
          tag_ids: tag_ids.join(","),
          spec_ids: spec_ids.join(","),
          access_token: localStorage.getItem('access_token')
        };
        console.log("提交小红书认证请求参数", sendData);
        let params = this.$addParams(sendData);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/mote/editxhschannel",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then(res => {
          if(res.data.status=="n"){
            this.$toast(res.data.error);
            this.flag = false;
            return
          }
          this.$toast("编辑成功");
          // 刷新上个页面
          this.back_list();
          // getApp().reloadPage(1)
          // setTimeout(() => {
          //   wx.navigateBack()
          // }, 1200)
        });
      },

      clearInput() {
        this.link_url = "";
      },

      copyToInput(){
      },

      afterReadImage(e){
        let file = [];
        if (e.target.files[0]) {
          file.push(e.target.files[0])
        }
        this.$toast.loading({
          message: "上传中",
          duration: 0,
        });
        for (let i = 0; i < file.length; i++) {
          uploadFile(file[i], "user/img/channel_homePage").then(res => {
            if(res.status === 'fail'){
              this.$toast('上传失败，请重新上传');
              return;
            }
            if (res.url) {
              this.homePage_image = res.url;
            }
            this.$toast.clear();
          });
        }
      },

      chooseFieldItem(index) {
        this.fieldList[index].checked = this.fieldList[index].checked ? false : true;
      },

      chooseTagItem(index) {
        this.tagList[index].checked = this.tagList[index].checked ? false : true;
      }
    },

    created() {
      if(this.$route.query.type=="changeMsg"){
        let detail = this.$ls.get('redBookInfo');
        this.showOptions = false;
        this.wechat = detail.weixin;
        this.phone = detail.phone;
        this.introduce = detail.user_text;
        this.fans_num = detail.fans_count;
        this.good_num = detail.like_count;
        this.collect_num = detail.collected_count;
        this.tag_ids = detail.tag_ids;
        this.spec_ids = detail.spec_ids;
      }
      //获取内容领域标签列表
      this.getFieldList();
      //获取特色标签领域列表
      this.getTagList();
    },
  }
</script>

<style lang="scss" scoped>
  .edit-card-wrap{
    height: 100vh;
    .card-container {
      height: 93vh;
      overflow-y: auto;
    }
    .card-title {
      width: 100%;
      height: 80px;
      display: flex;
      font-size: 35px;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 20px;
      background: #F2F3F7;

      .back_img {
        position: absolute;
        left: 20px;
        width: 50px;
        height: 50px;
        background-color: rgba(220, 220, 220, 0.5);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 80%;
          width: 80%;
          margin-left: -10px;
          opacity: 0.6;
        }
      }
    }

    .xhs-msg-wrap {
      width: 100vw;
      .msg-container{
        width: 100%;
        background-color: #fff;
        padding: 0 30px;
        .msg-item{
          display: flex;
          border-bottom: 2px solid #f5f5f5;
          padding: 20px 0;
          position: relative;
          .msg-label{
            width: 125px;
            font-size: 30px;
            color: #262A33;
            line-height: 60px;
          }
          .msg-input{
            flex: 1;
            height: 60px;
            font-size: 28px;
            &::-webkit-input-placeholder{
              color: #ddd;
            }
          }
          .msg-textarea{
            flex: 1;
            height: 160px;
            line-height: 40px;
            border: none;
            font-size: 28px;
            &::-webkit-input-placeholder{
              color: #ddd;
            }
          }
          .font-num{
            position: absolute;
            right: 0;
            bottom: 10px;
            line-height: 36px;
            font-size: 22px;
            color: #aaa;
          }
        }
      }
      .msg-tip{
        padding: 20px 30px 0;
        line-height: 40px;
        font-size: 24px;
        color: #8A8E99;
        background-color: #F2F3F7;
        .msg-tip-bottom{
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .spec-wrap {
      .field-box{
        padding: 20px;
        background-color: #fff;
        .field-container{
          display: flex;
          flex-wrap: wrap;
          .field-item-con{
            width: 25%;
            padding: 10px;
            .field-item{
              border-radius: 15px;
              background-color: #F6F6F6;
              font-size: 26px;
              color: #262A33;
              text-align: center;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              line-height: 70px;
              border: 2px solid #F6F6F6;
            }
            .field-select{
              border-color: #FF6685;
              background-color: #FFEBEF;
              color:#FF6685;
            }
          }
        }
      }
    }

    .save-btn{
      position: fixed;
      bottom: 50px;
      left: 50%;
      transform: translateX(-50%);
      width: 690px;
      line-height: 90px;
      background-color: #FF6685;
      color: #fff;
      font-size: 32px;
      text-align: center;
      border-radius: 15px;
      box-shadow: 0 0 20px 1px rgba(0,0,0,0.1);
    }

    .top-container{
      background-color: #fff;
      padding: 30px;
      .type-container{
        display: flex;
        line-height: 70px;
        font-size: 34px;
        font-weight: 700;
        color: #000;
        margin-bottom: 20px;
        .type-icon{
          width: 70px;
          height: 70px;
          margin-right: 15px;
        }
      }
      .link-container{
        display: flex;
        justify-content: space-between;
        line-height: 80px;
        background-color: #F7F8FA;
        border-radius: 10px;
        margin-bottom: 20px;
        .link-input{
          flex: 1;
          height: 80px;
          padding: 0 20px;
          background-color: #F7F8FA;
          font-size: 28px;
          &::-webkit-input-placeholder{
            color:#8A8E99;
          }
        }
        .cuIcon-roundclosefill{
          color: #ccc;
          padding: 0 10px;
          font-size: 34px;
          line-height: 80px;
        }
        .copy-btn{
          color: #FF6685;
          padding: 0 30px;
          font-size: 28px;
          display: none;
        }
      }
      .upload-con {
        display: flex;
        justify-content: space-between;
        .upload-btn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: #F7F8FA;
          border-radius: 10px;
          width: 240px;
          height: 180px;
          position: relative;
          .upload-icon{
            width: 50px;
            height: 50px;
            display: block;
          }
          .upload-tip {
            font-size: 24px;
            margin-top: 10px;
            color: #8A8E99;
          }
          .homepage-image {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }
          .reUpload {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.8);
            line-height: 60px;
            height: 60px;
          }
          .image-picker {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
        .upload-right-con {
          flex: 1;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          text-align: left;
          .justify-end {
            display: flex;
            justify-content: flex-end;
            .teach-btn {
              color: #FF6685;
              height: 60px;
              line-height: 60px;
              border-radius: 30px;
              border: 2px solid #eee;
              padding: 0 20px;
              font-size: 26px;
              display: flex;align-items: center;
              box-sizing: content-box;
              .cuIcon-questionfill {
                color: #FF6685;
                margin-right: 5px;
                font-size: 28px;
              }
            }
          }
        }
      }
    }

    .sub-title{
      font-weight: 700;
      font-size: 34px;
      color: #262A33;
      padding: 20px 30px 0;
      line-height: 80px;
      background-color: #F2F3F7;
    }
  }
</style>