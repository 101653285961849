<template>
  <div class="card-message-wrap">
    <div class="card-title">
      <div class="back_img">
        <img class="back_img"
             src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/Back%403x.png"
             @click="back_list"
             alt/>
      </div>
      小红书名片
    </div>

    <div class="scroll_wrap">
      <div class="avatar-container">
        <div style="position: relative;">
          <img class="rbook-avatar" :src="detail.head_url"/>
          <img class="rbook-icon" src="https://img.jimeimadou.com/common/resource/mine_icon/rbook-icon.png"/>
        </div>
        <div class="rbook-right">
          <div class="nickname">{{detail.nick}}</div>
          <div class="rbook-no">小红书号：{{detail.red_id}}</div>
          <div class="rbook-city">
            <div class="city-item" v-if="detail.location">
              <span class="iconfont icon-dingwei"></span>
              <div>{{detail.location}}</div>
            </div>
            <div class="city-item">已接单：{{detail.order_count}}</div>
          </div>
        </div>
        <div class="real-status real-status1" v-if="detail.real_status==1">已实名</div>
        <div class="real-status real-status2" v-if="detail.real_status==0" @click="toRealnameAuth">未实名</div>
      </div>

      <div>
        <div class="rbook-data-container">
          <div class="data-item">
            <div class="data-number">{{detail.note_num_text}}</div>
            <div class="data-title">笔记</div>
          </div>
          <div class="data-item">
            <div class="data-number">{{detail.fans_num_text}}</div>
            <div class="data-title">粉丝</div>
          </div>
          <div class="data-item">
            <div class="data-number">{{detail.like_num_text}}</div>
            <div class="data-title">点赞</div>
          </div>
          <div class="data-item">
            <div class="data-number">{{detail.collect_num_text}}</div>
            <div class="data-title">收藏</div>
          </div>
        </div>
        <div class="data-tip" v-if="tip_show">
          上方粉丝及赞藏数可在 <span style="color: #262A33;">编辑资料</span> 中更新
          <van-icon class="cuIcon-roundclosefill" name="clear" @click="hideTip"/>
        </div>
      </div>

      <div class="intro-container">
        <div class="intro-item">
          <div class="intro-label">个人简介：</div>
          <div class="intro-right">{{detail.user_text||"暂无个人简介"}}</div>
        </div>
        <div class="intro-item">
          <div class="intro-label">内容标签：</div>
          <div class="intro-right" v-if="!con_tags_show.length">暂无内容标签</div>
          <div class="intro-right tag-con" v-else>
            <div class="tag-item tag-pink" v-for="(item,index) in con_tags_show" :key="index">{{item}}</div>
            <div class="tag-item tag-pink more-btn" v-if="con_tags_show.length>=max_showtag_num" @click="showAllConTag">
              <template v-if="show_all_con">
                <div>展开</div>
                <text class="cuIcon-unfold"></text>
              </template>
              <template v-else>
                <div>收起</div>
                <text class="cuIcon-fold"></text>
              </template>
            </div>
          </div>
        </div>
        <div class="intro-item">
          <div class="intro-label">特色标签：</div>
          <div class="intro-right" v-if="spec_tags_show.length==0">暂无特色标签</div>
          <div class="intro-right tag-con" v-else>
            <div class="tag-item tag-blue" v-for="(item,index) in spec_tags_show" :key="index">{{item}}</div>
            <div class="tag-item tag-blue more-btn" v-if="spec_tags_show.length>=max_showtag_num" @click="showAllSpecTag">
              <template v-if="show_all_spec">
                <div>展开</div>
                <text class="cuIcon-unfold"></text>
              </template>
              <template v-else>
                <div>收起</div>
                <text class="cuIcon-fold"></text>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="nav-container">
        <div :class="['nav-item', currentNav==0 ? 'current' : '']" @click="chooseNav(0)">小红书笔记</div>
        <div :class="['nav-item', currentNav==1 ? 'current' : '']" @click="chooseNav(1)">平台案例</div>
      </div>

      <div class="xhs-note-wrap" v-if="photo_list.length">
        <div class="note-item" v-for="(item,index) in photo_list" :key="index">
          <img :src="item.image_url"/>
          <div class="bottom-info">
            {{item.title}}
          </div>
        </div>
      </div>

      <div :style="{height: `${globalBottom+120}px`}"></div>
    </div>
    <div class="bottom-btn-con" :style="{paddingBottom: `${globalBottom}px`}">
      <div class="bottom-btn">
        <div class="bottom-btn-item" @click="toEditCard">
          <div class="iconfont icon-bianji1"></div>
          <div>编辑资料</div>
        </div>
        <div class="item-line"></div>
        <div class="bottom-btn-item" @click="upDateNote">
          <span class="iconfont icon-ruanjianbanbengengxin"></span>
          <div>更新笔记</div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "CardMessage",
    data(){
      return {
        globalBottom: 0,
        currentNav: 0,
        detail: {},
        max_showtag_num: 3,
        con_tags: [],
        con_tags_show: [],
        show_all_con: true,
        spec_tags: [],
        spec_tags_show: [],
        show_all_spec: true,
        tip_show: true,
        photo_list: [],
        page: 1,
        page_size: 10,
        stopLoad: false,
      }
    },
    methods:{
      getRedBookDetail() {
        let sentData = {
          access_token: localStorage.getItem("access_token")
        };
        let params = this.$addParams(sentData);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/mote/xhschanneldetail",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then((res) => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return
          }
          let result = JSON.parse(res.data.data);
          //console.log(result);

          result.note_num_text = this.handleCount(result.total_note_count);
          result.fans_num_text = this.handleCount(result.fans_count);
          result.like_num_text = this.handleCount(result.like_count);
          result.collect_num_text = this.handleCount(result.collected_count);

          if (result.tag_names) {
            let con_tags = result.tag_names.split(",");
            this.con_tags = con_tags;
            this.con_tags_show = con_tags.length > this.max_showtag_num ? con_tags.slice(0, this.max_showtag_num) : con_tags;
            this.show_all_con = con_tags.length > this.max_showtag_num;
          }
          if (result.spec_tags) {
            let spec_tags = result.spec_tags.split(",");
            this.spec_tags = spec_tags;
            this.spec_tags_show = spec_tags.length > this.max_showtag_num ? spec_tags.slice(0, this.max_showtag_num) : spec_tags;
            this.show_all_spec = spec_tags.length > this.max_showtag_num;
          }
          this.detail = result;
        })
      },
      //数量过万时将保留一位小数转换成多少万
      handleCount(data){
        if(data && parseFloat(data)>9999){
          return (parseFloat(data)/10000).toFixed(1)+"万"
        }else{
          return data
        }
      },

      showAllConTag() {
        this.con_tags_show = this.show_all_con ? this.con_tags : this.con_tags.slice(0, this.max_showtag_num);
        this.show_all_con = !this.show_all_con;
      },
      showAllSpecTag() {
        this.spec_tags_show = this.show_all_spec ? this.spec_tags : this.spec_tags.slice(0, this.max_showtag_num);
        this.show_all_spec = !this.show_all_spec;
      },

      chooseNav(type) {
        this.currentNav = type;
        this.page = 1;
        this.photo_list = [];
        this.stopLoad = false;
        switch (type) {
          case 0:
            this.getRedBookNotes();
            break;
          case 1:
            this.getPlatformCase();
            break;
          default:
            break;
        }
      },

      hideTip() {
        this.tip_show = false;
      },

      toEditCard() {
        this.$ls.set('redBookInfo', this.detail);
        this.$router.push({
          name: 'editCard',
          query:{
            type: 'changeMsg'
          }
        })
      },

      upDateNote(){
        let sentData = {
          access_token: localStorage.getItem("access_token"),
        };
        let params = this.$addParams(sentData);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/mote/xhsnoteupdate",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then(res => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return
          }
          this.$toast("已提交更新请求，请稍后查看");
        })
      },

      toRealnameAuth(){
        this.$router.push({name: 'xhsRename'})
      },

      getRedBookNotes() {
        if (this.stopLoad) {
          return
        }
        let sentData = {
          access_token: localStorage.getItem("access_token"),
          page: this.page++,
          page_size: 10
        };
        let params = this.$addParams(sentData);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/mote/xhsnotelist",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then(res => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return
          }
          let result = JSON.parse(res.data.data);
          //console.log("小红书笔记", result);
          let list = result.list;
          this.photo_list = list;
          if (list.length < 10) {
            this.stopLoad = true;
            return
          }
        })
      },

      getPlatformCase() {
        if (this.stopLoad) {
          return
        }
        let sentData = {
          access_token: localStorage.getItem("access_token"),
          page: this.page++,
          page_size: 10
        };
        let params = this.$addParams(sentData);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/mote/xhscaselist",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then(res => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return
          }
          let result = JSON.parse(res.data.data);
          //console.log("平台案例", result);
          let list = result.list;
          for (let i = 0; i < list.length; i++) {
            list[i].image_url = list[i].photos.split(";")[0]
          }
          this.photo_list = list;
          if (list.length < 10) {
            this.stopLoad = true;
            return
          }
        });
      },

      back_list(){
        this.$router.go(-1);
      }
    },
    activated(){
      this.getRedBookDetail();
      this.getRedBookNotes()
    }

  }
</script>

<style lang="scss" scoped>

  .card-message-wrap {
    .card-title {
      width: 100%;
      height: 80px;
      display: flex;
      font-size: 35px;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 20px;
      background: #fff;

      .back_img {
        position: absolute;
        left: 20px;
        width: 50px;
        height: 50px;
        background-color: rgba(220, 220, 220, 0.5);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 80%;
          width: 80%;
          margin-left: -10px;
          opacity: 0.6;
        }
      }
    }

    .scroll_wrap {
      height: 93vh;
      overflow-y: auto;
    }

    .avatar-container{
      display: flex;
      padding: 40px 30px 15px;
      position: relative;
      .rbook-avatar{
        width: 140px;
        height: 140px;
        border-radius: 50%;
      }

      .rbook-icon{
        position: absolute;
        width: 90px;
        height: 40px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .rbook-right{
        flex: 1;
        margin-left: 20px;
        overflow: hidden;
        .nickname{
          font-size: 36px;
          font-weight: 700;
          max-width: 500px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          line-height: 50px;
        }
        .rbook-no{
          margin-bottom: 10px;
          font-size: 24px;
          color: #888;
          line-height: 40px;
        }
        .rbook-city{
          display: flex;
          .city-item{
            margin-right: 10px;
            background-color: #E8EBF2;
            color: #888;
            font-size: 24px;
            line-height: 36px;
            border-radius: 8px;
            padding: 0 10px;
            display: flex;
            .icon-dingwei{
              font-size: 24px;
              margin-right: 6px;
            }
          }
        }
      }

      .real-status{
        position: absolute;
        right: 0;
        top: 40px;
        padding: 0 30px;
        line-height: 50px;
        border-radius: 25px 0 0 25px;
        font-size: 28px;
      }
      .real-status1{
        background-color: #FF6685;
        color: #fff;
      }
      .real-status2{
        background-color: #ddd;
        color: #fff;
      }
    }

    .rbook-data-container{
      display: flex;
      padding: 0 30px;
      .data-item{
        width: 25%;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .data-number{
          font-size: 40px;
          font-weight: 800;
          color: #262A33;
          margin-bottom: 5px;
        }
        .data-title{
          font-size: 22px;
          color: #8A8E99;
        }
      }
    }

    .data-tip{
      background-color: #E8EBF2;
      margin: 0 30px;
      line-height: 42px;
      border-radius: 8px;
      text-align: center;
      color: #8A8E99;
      font-size: 22px;
      position: relative;

      .cuIcon-roundclosefill{
        position: absolute;
        right: 0;
        width: 42px;
        text-align: center;
        line-height: 42px;
        color: #ccc;
        font-size: 26px;
      }
    }

    .intro-container{
      margin: 30px 30px 15px;
      border-radius: 20px;
      background-color: #fff;
      padding: 20px;
      box-shadow: 0 0 15px 1px rgba(0,0,0,0.05);
      .intro-item{
        display: flex;
        line-height: 40px;
        padding: 10px 0;
        color: #8A8E99;
        font-size: 26px;
        .intro-label{
          padding-right: 10px;
        }
        .intro-right{
          flex: 1;
          overflow: hidden;
        }
        .tag-con{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .tag-item{
            padding: 0 10px;
            border-radius: 6px;
            font-size: 22px;
            line-height: 40px;
            height: 40px;
            margin-right: 8px;
            margin-bottom: 8px;
          }
          .tag-pink{
            background-color: #FFF0F3;
            color: #FF6685;
          }
          .tag-blue{
            background-color: #F0F4FF;
            color: #6694FF;
          }
          .more-btn{
            display: flex;
            color: #888;
            background-color: #F7F7F7;
          }
          .cuIcon-unfold,.cuIcon-fold{
            font-size: 16px;
          }
        }
      }
    }

    .nav-container {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: sticky;
      top: 0;
      z-index: 100;
      background-color: #F2F3F7;
      .nav-item {
        color: #000;
        font-size: 32px;
        font-weight: 700;
        width: 300px;
        text-align: center;
      }
      .current {
        color: #FF6685 !important;
        position: relative;
      }
      .current::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -15px;
        width: 100px;
        height: 8px;
        border-radius: 4px;
        background-image: linear-gradient(to right, rgba(255, 179, 200, 1), rgba(255, 102, 133, 1));
      }
    }

    .xhs-note-wrap {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .note-item{
        width: 49%;
        margin-bottom: 20px;
        img {
          width: 100%;
          height: 454px;
          object-fit: cover;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
        .bottom-info {
          background-color: #fff;
          padding: 20px;
          font-size: 28px;
          line-height: 36px;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }

    .bottom-btn-con{
      position: fixed;
      width: 100%;
      bottom: 0;
      z-index: 100;
      box-shadow: 0 0 20px 1px rgba(0,0,0,0.06);
      background-color: #fff;
      .bottom-btn{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .bottom-btn-item{
          flex: 1;
          line-height: 100px;
          display: flex;
          justify-content: center;
          color: #222;
          font-size: 32px;
        }
        .item-line{
          width:2px;
          height: 30px;
          background-color: #ddd;
        }
        .iconfont{
          margin-right: 10px;
          font-size:30px;
        }
      }
    }
  }

</style>