<template>
	<div class="back-con" @click="back">
		<div class="iconfont icon-fanhui"></div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		methods:{
			back() {
				this.$router.back(-1);
			}
		}
	}
</script>

<style lang="scss" scoped>
	.back-con {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		background-color: rgba(0, 0, 0, 0.4);
		position: fixed;
		left: 30px;
		top: 30px;
		z-index: 1000;
	
		.icon-fanhui {
			color: #fff;
			font-size: 32px;
		}
	}
</style>
