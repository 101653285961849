<template>
	<div class="container-wrapper">
		<div class="top-container">
			<div class="top-left-con">
				<div class="back-con" @click="back">
					<div class="iconfont icon-fanhui"></div>
				</div>
				<div class="top-title">我的买家秀<span class="title-total-num">({{totalNum}})</span></div>
			</div>
			<div class="top-btn-con">
				<div class="top-btn-item" @click="editAlbum">{{is_edit?'取消编辑':'编辑'}}</div>
				<div class="top-btn-item" @click="toUploadAlbum">上传</div>
			</div>
		</div>
		<div v-if="isLoading" class="loading-con">
			<van-loading type="spinner"/>
		</div>
		<template v-else>
			<empty v-if="isNull" title="暂无买家秀"></empty>
			<scroll-y class="scroll-container" v-else ref="iscroll" @loadMore="getMjxs">
				<div class="photos-container">
					<div class="photo-item" v-for="(item,index) in mjx" :key="index" @click="toAlbumDetail(item.bg_id,item.mt_id)">
						<image-loader class="photo-image" :src="addWaterMark(item.cover_image||item.cover)" ></image-loader>
						<div class="my-icon-con" @click.stop="showMask(index)">
							<i class="my-icon-item iconfont" :class="item.is_private?'icon-Notvisible':'icon-browse'"></i>
						</div>
						<div :class="['item-bottom-con',is_edit?'show-delete':'']">
							<div class="status-con">
								<div class="item-status" v-if="item.state == 1">审核中</div>
								<div class="item-status" v-if="item.state == 2">已通过</div>
								<div class="item-status" v-if="item.state == 3"
									@click.stop="showRefuseModel(item.refuse_reason)">
									<div class="status-icon">
										<i class="iconfont icon-question-circle"></i>
										<div>已拒绝</div>
									</div>
								</div>
								<div class="total-num">
									<i class="iconfont icon-tupian"></i>
									<div>{{item.photos.length+item.videos.length}}</div>
								</div>
							</div>
							<div class="bottom-delete" @click.stop="showDeleteModel(index)">
								<i class="iconfont icon-delete"></i>
							</div>
						</div>
			
						<div class="photo-mask" :class="item.mask_show?'mask-show':''">
							<div class="mask-title">
								<template v-if="item.is_private">
									<i class="iconfont eyes-icon icon-browse"></i>
									<div>公开展示</div>
								</template>
								<template v-else>
									<i class="iconfont eyes-icon icon-Notvisible"></i>
									<div>仅自己可见</div>
								</template>
							</div>
							<div class="mask-text">{{item.is_private?'选择后该相册将会展示在您的个人主页':'选择后该相册不对其他人展示'}}</div>
							<div class="mask-btn-con">
								<div class="mask-btn-item" @click.stop="hideMask">否</div>
								<div class="mask-btn-item sure" @click.stop="setPrivate">是</div>
							</div>
						</div>
					</div>
				</div>
				<div class="nomore" v-if="stopLoad">没有更多了~</div>
			</scroll-y>
		</template>
		

		<album-model :show="modelName=='albumModel'" :swiper_photos="swiper_photos" :swiper_videos="swiper_videos" :current="0"
			@hideMask="hideModel"></album-model>

		<tip-model :show="modelName=='deleteModel'" title="温馨提示" content="确认要删除该相册吗？" @cancel="hideModel" @confirm="deleteAlbum">
		</tip-model>
		
		<tip-model :show="modelName=='refuseModel'" title="拒绝原因" :content="refuseReason" :showCancelBtn="false" confirmText="我知道了" @confirm="hideModel">
		</tip-model>
		
	</div>
</template>

<script>
	import empty from "@/components/empty.vue"
	import scrollY from "@/components/app-scroll-y.vue"
	import albumModel from "@/components/album-model.vue"
	import tipModel from "@/components/tip-model.vue"
	export default {
		data() {
			return {
				stick_top: 0,
				totalNum: 0,
				page: 1,
				page_size: 10,
				isNull: false,
				mjx: [],
				stopLoad: false,
				swiper_photos: [],
				swiper_videos: [],
				mask_index: -1,
				is_edit: false,
				modelName: "",
				deleteMjx_index: -1,
				isShowModel: false,
				refuseReason:"",
				isLoading:true
			}
		},
		components: {
			empty,
			albumModel,
			scrollY,
			tipModel
		},
		activated() {
			this.initData()
			this.getMjxs()
		},
		methods: {
			initData() {
				this.page = 1
				this.mjx = []
				this.isNull = false
				this.stopLoad = false
				this.$nextTick(()=>{
					this.$refs.iscroll&&this.$refs.iscroll.scrollToTop();
				})
			},
			//获取模特相册信息
			getMjxs() {
				if (this.stopLoad) {
					return
				}
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					page: this.page,
					page_size: this.page_size,
					is_mine: 1
				}
				this.page++
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/mjxphotolist",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						this.isLoading = false
						if (res.data.status == 'y') {
							let result = JSON.parse(res.data.data)
							console.log(result)
							this.totalNum = result.total_nums
							
							let mjx_list = result.list
							if (mjx_list.length < this.page_size) {
								if (mjx_list.length==0&&this.page == 2) {
									this.isNull = true
								}
								this.stopLoad = true
							}
							
							if (mjx_list.length != 0) {
								for (let i = 0; i < mjx_list.length; i++) {
									mjx_list[i].is_private = mjx_list[i].cus_show == 1
									mjx_list[i].photos = mjx_list[i].photos ? mjx_list[i].photos.split(";") : []
									mjx_list[i].videos = mjx_list[i].videos ? mjx_list[i].videos.split(";") : []
									mjx_list[i].cover_image = mjx_list[i].photos.length == 0 ? "" : mjx_list[i]
										.photos[0]
								}
								this.mjx.push(...mjx_list)
							}
						}
					});
			},
			//显示相册弹框
			showMjxAlbum(index) {
				let currentMjx = this.mjx[index]
				console.log(currentMjx)
				this.swiper_photos = currentMjx.photos
				this.swiper_videos = currentMjx.videos
				this.modelName = 'albumModel'
			},
			toAlbumDetail(album_id,mt_id){
				this.$router.push({
					name: "albumDetail",
					query: {
						mt_id,
						album_id,
						album_type: 1
					}
				});
			},
			// 展示仅自己可见遮罩层
			showMask(index) {
				this.hideMask()
				this.mask_index = index
				this.mjx[index].mask_show = true
				this.$forceUpdate()
			},
			// 隐藏仅自己可见遮罩层
			hideMask() {
				// console.log(this.mask_index)
				if (this.mask_index != -1) {
					this.mjx[this.mask_index].mask_show = false
					this.$forceUpdate()
					this.mask_index = -1
				}
			},
			//确认设置为仅自己可见/公开展示
			setPrivate() {
				let album = this.mjx[this.mask_index]
				let act_type = 1
				let act_id = album.bg_id
				let act_val = album.is_private ? "0" : "1"


				let sentData = {
					access_token: localStorage.getItem("access_token"),
					act_type,
					act_id,
					act_val
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/level",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							console.log("操作成功")
							this.$toast("设置成功")
							this.hideMask()
							album.is_private = !album.is_private
						} else {
							this.$toast(res.data.error)
						}
					});
			},
			toUploadAlbum() {
				console.log("创建买家秀")
				this.$router.push({
					name: "uploadMjx"
				});
			},
			editAlbum() {
				this.hideMask()
				this.is_edit = !this.is_edit
			},
			showRefuseModel(content) {
				this.refuseReason = content
				this.modelName = "refuseModel"
			},
			showDeleteModel(index) {
				this.hideMask()
				this.deleteMjx_index = index
				this.modelName = "deleteModel"
			},
			hideModel() {
				this.modelName = ""
			},
			back() {
				this.$router.back(-1);
			},
			deleteAlbum() {
				let deleteMjx = this.mjx[this.deleteMjx_index]
				if (deleteMjx.state != 3) {
					this.$toast("只允许删除审核拒绝的相册")
					this.hideModel()
					return
				}

				let sentData = {
					access_token: localStorage.getItem("access_token"),
					bg_id: deleteMjx.bg_id
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/delmjxphoto",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							this.$toast("删除成功")
							this.hideModel()
							this.totalNum--
							this.mjx.splice(this.deleteMjx_index, 1)
							if (this.mjx.length < this.page_size) {
								if (this.mjx.length == 0) {
									this.isNull = true
								}
								this.getMjxs()
							}
						} else {
							this.$toast(res.data.error)
						}
					});
			},
			addWaterMark(url) {
				let compressParams = "?imageView2/1/w/500/h/700"
				let waterMark =
					"|watermark/1/blogo/2/image/aHR0cDovL2ptc3RhdGljLTEyNTU0OTc5MTYuY29zLmFwLXNoYW5naGFpLm15cWNsb3VkLmNvbS9jb21tb24vd2F0ZXJtYXJrZXIucG5n/dissolve/90/dx/10/dy/10"
				let newUrl = url + compressParams + waterMark
				newUrl = newUrl.replace('https://img.jimeimadou.com',
					'https://jmstatic-1255497916.cos-website.ap-shanghai.myqcloud.com')
				return newUrl
			}
		}
	}
</script>

<style lang="scss" scoped>
	.loading-con{
		width: 100vw;
		height: 50vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		
	}
	.container-wrapper {
		background-color: #fff;
	}

	.top-container {
		display: flex;
		justify-content: space-between;
		padding: 0 24px;
		align-items: center;
		line-height: 100px;
		position: sticky;
		z-index: 100;
		background-color: #fff;
	}

	.scroll-container {
		height: calc(100vh - 100px);
	}

	.top-left-con {
		display: flex;
		align-items: center;

		.back-con {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background-color: rgba(0, 0, 0, 0.4);
			margin-right: 10px;
			position: relative;
			left: -5px;

			.icon-fanhui {
				color: #fff;
				font-size: 32px;
			}
		}
	}

	.top-title {
		font-size: 36px;
		font-weight: 700;

		.title-total-num {
			font-size: 30px;
		}
	}

	.top-btn-con {
		display: flex;
	}

	.top-btn-item {
		margin-left: 20px;
		padding: 0 25px;
		line-height: 60px;
		border-radius: 30px;
		background-color: #FF6685;
		color: #fff;
		font-size: 28px;
	}

	.photos-container {
		padding: 0 24px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.photo-item {
		width: 344px;
		height: 480px;
		position: relative;
		margin-bottom: 15px;
		border-radius: 10px;
		overflow: hidden;
	}

	.my-icon-con {
		position: absolute;
		right: 15px;
		top: 15px;
		width: 40px;
		height: 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		color: #fff;
		border-radius: 50%;
		background-color: rgba(0, 0, 0, 0.5);
	}

	.my-icon-con .my-icon-item {
		font-size: 32px;
		opacity: 0.9;
	}

	.my-icon-con .icon-Notvisible {
		color: #FF6685;
	}

	.photo-mask {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		bottom: 100%;
		background-color: rgba(0, 0, 0, 0.8);
		color: #fff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		font-size: 30px;
		padding: 0 35px;

	}

	.mask-show {
		bottom: 0;
		transition: 0.2s;
	}

	.mask-title {
		line-height: 70px;
		font-size: 36px;
		display: flex;
	}

	.photo-mask .eyes-icon {
		color: #FF6685;
		margin-right: 10px;
		font-size: 44px;
	}

	.mask-text {
		line-height: 40px;
		margin-bottom: 80px;
		font-size: 26px;
		color: rgba(255, 255, 255, 0.8);
	}

	.mask-btn-con {
		display: flex;
		justify-content: space-between;
	}

	.mask-btn-item {
		width: 120px;
		text-align: center;
		line-height: 60px;
		border-radius: 30px;
		color: #aaa;
		background-color: #fff;
	}

	.mask-btn-item.sure {
		background-color: #FF6685;
		color: #fff;
	}

	.photo-item .photo-image {
		width: 100%;
		height: 100%;
		display: block;
		border-radius: 10px;
		object-fit: cover;
	}

	.photo-item .item-video {
		width: 100%;
		height: 100%;
		display: block;
		border-radius: 10px;
	}

	.item-bottom-con {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		transform: translateY(70px);
		transition: 0.3s;
	}

	.show-delete {
		transform: translateY(0);
	}

	.status-con {
		padding: 15px;
		display: flex;
		justify-content: flex-end;
		.status-icon{
			display: flex;
		}
	}

	.item-status {
		line-height: 40px;
		font-size: 22px;
		padding: 0 15px;
		border-radius: 40px;
		background-color: rgba(0, 0, 0, 0.5);
		color: #fff;
	}

	.icon-question-circle {
		color: #fff;
		margin-right: 3px;
		font-size: 20px;
	}

	.total-num {
		display: flex;
		background-color: rgba(0, 0, 0, 0.5);
		color: #fff;
		line-height: 40px;
		height: 40px;
		border-radius: 20px;
		padding: 0 15px;
		font-size: 24px;
		margin-left: 10px;
	}

	.icon-tupian {
		margin-right: 5px;
		font-size: 20px;
	}

	.bottom-delete {
		display: flex;
		justify-content: center;
		line-height: 70px;
		color: #fff;
		background-color: #FF6685;
	}

	.icon-delete {
		font-size: 34px;
	}


	.nomore {
		font-size: 28px;
		color: #aaa;
		text-align: center;
		padding: 10px 0 40px;
	}

	.vant_popup_box {
		border-radius: 15px;

		.model-wrapper {
			padding: 20px;
			text-align: center;

			.model-title {
				line-height: 80px;
				font-weight: 700;
				font-size: 32px;
			}

			.model-content {
				padding: 25px 20px 35px;
				font-size: 30px;
			}

			.model-btn-con {
				display: flex;
				justify-content: space-around;
				width: 540px;
				padding: 20px 0 25px;

				div {
					width: 200px;
					line-height: 70px;
					border-radius: 35px;
					font-size: 30px;
					text-align: center;
				}

				.model-btn-item-l {
					border: 1px solid #ddd;
					color: #888;
				}

				.model-btn-item-r {
					border: 1px solid #000;
					background-color: #000;
					color: #fff;
				}
			}
		}
	}
</style>
