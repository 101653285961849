<template>
	<div class="container-wrapper">
		<navbar title="地址列表"></navbar>
		<empty v-if="isNull" title="暂无地址"></empty>
		<div v-else class="address-container">
			<div class="address-item" v-for="(item,index) in addressList" :key="index">
				<div class="addr-left">
					<img class="addr-icon"
						:src="item.is_default == 1 ? 'https://img.jimeimadou.com/common/resource/address_icon/address_sel_icon.png' : 'https://img.jimeimadou.com/common/resource/address_icon/address_icon.png'">
				</div>
				<div class="addr-center">
					<div class="name-con">
						<div class="name">{{item.receive_name}}</div>
						<div class="phone">{{item.receive_phone}}</div>
					</div>
					<div class="addr-detail">
						<span class="default" v-if="item.is_default == 1">默认</span>{{item.detail}}
					</div>
				</div>
				<div class="addr-right" @click="showModel(index)">
					<div>删除</div>
				</div>
			</div>
		</div>
		<div class="add-btn" @click="toAddAddress">+新增地址</div>
		<van-popup class="vant_popup_box" v-model="isShowModel">
			<div class="addr-model">
				<div class="model-title">温馨提示</div>
				<div class="model-content">确认要删除该地址吗？</div>
				<div class="model-btn-con">
					<div class="model-btn-item-l" @click="hideModel">取消</div>
					<div class="model-btn-item-r" @click="deleteAddress">确认</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	import empty from "@/components/empty.vue"
	export default {
		data() {
			return {
				addressList: [],
				current_index:-1,
				isShowModel:false,
				isNull:false
			}
		},
		components:{
			navbar,
			empty
		},
		activated() {
			this.getAddressList()
		},
		methods: {
			toAddAddress(){
				this.$router.push({
					name: "addAddress"
				});
			},
			getAddressList() {
				let sentData = {
					access_token: localStorage.getItem("access_token")
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/addrlist",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						//console.log(res.data)
						if (res.data.status == "n") {
							return
						}
						let result = JSON.parse(res.data.data)
						if(result.length==0){
							this.isNull = true
							return
						}
						this.addressList = result
					});
			},
			showModel(index){
				this.current_index = index
				this.isShowModel = true
			},
			hideModel(){
				this.isShowModel = false
			},
			deleteAddress() {
				let id = this.addressList[this.current_index].id
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					act: "del",
					id
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/actaddr",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						//console.log(res.data)
						if (res.data.status == "n") {
							this.$toast(res.data.error)
							return
						}
						this.hideModel()
						this.addressList.splice(this.current_index,1)
						this.$toast("删除成功")
					});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper {
		background-color: #F5F5F5;
		overflow-y: auto;
	}

	.address-item {
		display: flex;
		padding: 40px 30px;
		padding-right: 0;
		background-color: #fff;
		margin-bottom: 20px;

		.addr-left {
			display: flex;
			align-items: center;

			.addr-icon {
				width: 60px;
			}
		}

		.addr-center {
			flex: 1;
			display: flex;
			flex-direction: column;
			justify-content: center;
			overflow: hidden;
			padding: 0 20px;

			.name-con {
				display: flex;
				align-items: flex-end;
				margin-bottom: 20px;

				.name {
					font-size: 30px;
				}

				.phone {
					font-size: 24px;
					color: #888;
					margin-left: 15px;
				}
			}

			.addr-detail {
				font-size: 28px;
				line-height: 38px;
				max-height: 76px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				overflow: hidden;
				text-overflow: ellipsis;
				-webkit-box-orient: vertical;

				.default {
					display: inline-block;
					line-height: 30px;
					background-color: #FFEBEF;
					color: #FF6685;
					border-radius: 5px;
					font-size: 20px;
					padding: 0 10px;
					margin-right: 10px;
					position: relative;
					top: -2px;
				}
			}
		}

		.addr-right {
			width: 120px;
			text-align: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			border-left: 1px solid #ddd;
			font-size: 30px;
			color: #666;
		}
	}
	
	.add-btn{
		width: 400px;
		line-height: 90px;
		border-radius: 45px;
		text-align: center;
		color: #fff;
		background-color: #000;
		font-size: 30px;
		position: fixed;
		bottom: 100px;
		left: 50%;
		transform: translateX(-50%);
		box-shadow: 0 0 15px 1px rgba(0,0,0,0.1);
	}
	
	.vant_popup_box{
		border-radius: 15px;
		.addr-model{
			padding: 20px 30px;
			text-align: center;
		}
		.model-title {
			line-height: 90px;
			font-size: 36px;
			font-weight: 700;
		}
		
		.model-content {
			font-size: 30px;
			line-height: 36px;
			padding: 20px;
		}
		
		.model-btn-con {
			display: flex;
			justify-content: space-around;
			width: 540px;
			padding: 30px 0 20px;
		
			div {
				width: 200px;
				line-height: 70px;
				border-radius: 35px;
				font-size: 30px;
				text-align: center;
			}
		
			.model-btn-item-l {
				border: 1px solid #ddd;
				color: #888;
			}
		
			.model-btn-item-r {
				border: 1px solid #000;
				background-color: #000;
				color: #fff;
			}
		}
		
	}
</style>
