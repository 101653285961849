<template>
	<div class="container-wrapper">
		<navbar title="账户余额"></navbar>
		<div class="account_bgview"></div>
		<div class="account_wrapper">
			<div class="account_moneyview">
				<div class="account_money_numview">
					<div class="account_money_num">{{getNumber(userInfo.remain_amount)}} <span>￥</span></div>
				</div>
				<div class="account_money_title">账户余额</div>
				<div class="account_money_btn" @click="toWithdraw">提现</div>
				<div class="account_money_itemview">
					<div class="account_money_item" @click="showModel('freezeModel')">
						<div class="account_money_itemnumview">
							<div class="account_money_itemnum">{{getNumber(userInfo.freeze_amount)}}</div>
							<div class="account_money_itemnumlable">￥</div>
						</div>	
						<div class="account_money_itemtitle">冻结余额<i class="iconfont icon-question-circle"></i></div>
					</div>
					<div class="account_money_item">
						<div class="account_money_itemnumview">
							<div class="account_money_itemnum">{{getNumber(userInfo.task_amount)}}</div>
							<div class="account_money_itemnumlable">￥</div>
						</div>
						<div class="account_money_itemtitle">任务佣金</div>
					</div>
					<div class="account_money_item">
						<div class="account_money_itemnumview">
							<div class="account_money_itemnum">{{getNumber(userInfo.invite_amount)}}</div>
							<div class="account_money_itemnumlable">￥</div>
						</div>
						<div class="account_money_itemtitle">邀请奖励</div>
					</div>
				</div>
			</div>
			<div class="data-container">
				<div class="title-wrapper">
					<div class="title-item" :class="moneyType==item.type?'select-tab':''" v-for="(item,index) in titleList" :key="index" @click="selectTab(item.type)">{{item.name}}</div>
				</div>
				<div class="subtitle-wrapper">
					<div class="subtitle-item" style="width:20%;text-align: left;">余额变动</div>
					<div class="subtitle-item" style="width:30%;text-align: center;">日期</div>
					<div class="subtitle-item" style="width:20%;text-align: center;">账户余额</div>
					<div class="subtitle-item" style="width:40%;text-align: right;">备注</div>
				</div>
				<scroll-y class="scroll-container" ref="moneyScroll" @loadMore="getAcountlist">
					<div class="account_tableviewcell" v-for="(item,index) in accountList" :key="index">
						<div class="account_tablehead_title" style="width:20%;text-align: center;">
							{{item.change_amount > 0 ? "+" : ""}}{{getNumber(item.change_amount)}}
						</div>
						<div class="account_tablehead_title" style="width:30%;text-align: center;">
							{{item.add_time}}
						</div>
						<div class="account_tablehead_title" style="width:20%;text-align: center;">
							{{getNumber(item.new_amount)}}
						</div>
						<div class="account_tablehead_title" style="width:40%;text-align: right;">
							<div>{{item.remark}}</div>
							<template v-if="moneyType == 0||moneyType == 10">
								<div v-if="item.status==1" style="color: lightGreen;">待审核</div>
								<div v-if="item.status==2" style="color: lightGreen;">审核通过打款中</div>
								<div v-if="item.status==3" style="color: lightGreen;">已打款</div>
								<div v-if="item.status==4" style="color: lightGreen;">打款失败已退回</div>
							</template>
						</div>
					</div>
					<div class="nomore" v-if="stopLoad">没有更多了~</div>
				</scroll-y>
			</div>
			
			<tip-model :show="modelName=='tipModel'" title="温馨提示" content="您的账号已被限制，请联系客服" :showCancelBtn="false" confirmText="我知道了" @confirm="hideModel">
			</tip-model>
			
			<tip-model :show="modelName=='freezeModel'" title="说明" content="异常订单本金赔付至冻结余额，冻结余额15个工作日后可提现" :showCancelBtn="false" confirmText="我知道了" @confirm="hideModel">
			</tip-model>
			
		</div>
	</div>
</template>

<script>
	import ScrollY from "@/components/app-scroll-y.vue";
	import navbar from "@/components/navbar.vue";
	import tipModel from "@/components/tip-model.vue"
	export default {
		data() {
			return {
				accountList: [],
				current_page: 1,
				is_end: false,
				moneyType: 0,
				modelName: "",
				userInfo: {},
				titleList: [{
					name: "全部",
					type: 0
				}, {
					name: "佣金明细",
					type: 20
				}, {
					name: "奖励明细",
					type: 21
				}, {
					name: "提现明细",
					type: 10
				}],
				subtitle: [{
					name: "余额变动",
					width: "20%"
				}, {
					name: "日期",
					width: "30%"
				}, {
					name: "账户余额",
					width: "20%"
				}, {
					name: "备注",
					width: "40%"
				}],
				stopLoad: false
			}
		},
		components: {
			ScrollY,
			navbar,
			tipModel
		},
		activated() {
			this.getUserInfo()
		},
		created() {
			this.getAcountlist()
		},

		methods: {
			selectTab(type) {
				this.moneyType = type
				this.current_page = 1
				this.accountList = []
				this.stopLoad = false
				this.isNull = false
				this.$refs.moneyScroll.scrollToTop();
				this.getAcountlist()
			},
			getAcountlist() {
				if (this.stopLoad) {
					return
				}
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					page: this.current_page++,
					page_size: 10,
					type: this.moneyType
				}

				let params = this.$addParams(sentData)
				// console.log(params);

				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/amountlist",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						//console.log(res.data)
						if (res.data.status == "n") {
							return
						}
						let result = JSON.parse(res.data.data)
						console.log(result)
						if (result.list.length < 10) {
							this.stopLoad = true
							if (result.list.length == 0 && this.current_page == 2) {
								this.isNull = true
							}
						}
						this.accountList.push(...result.list)
						console.log(this.accountList)
					});
			},
			showModel(name){
				this.modelName = name
			},
			hideModel(){
				this.modelName = ""
			},
			getNumber(money) {
				if (!money) {
					return '0.00'
				}
				money /= 100
				return money.toFixed(2)
			},
			toWithdraw() {
				console.log("提现")
				if(this.userInfo&&this.userInfo.close_withdraw==0){
					this.modelName = "tipModel"
					return
				}
				this.$router.push({
					name: "withdraw"
				});
			},
			getUserInfo() {
				let sentData = {
					access_token: localStorage.getItem("access_token")
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/index",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						//console.log(res.data)
						if (res.data.status == "n") {
							return
						}
						let result = JSON.parse(res.data.data)
						this.userInfo = result
					});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper {
		position: relative;
		width: 100vw;
		height: 100vh;
		background-color: #f5f5f5;
	}
	.account_bgview {
		width: 100%;
		height: 400px;
		background-color: #1A1F29;
		border-radius: 0 0 100px 100px;
		position: absolute;
		top: 90px;
		z-index: 1;
	}

	.account_wrapper {
		position: absolute;
		top: 90px;
		left: 0;
		width: 100%;
		height: calc(100% - 90px);
		z-index: 10;
		display: flex;
		flex-direction: column;
		padding: 50px 24px 30px;
		box-sizing: border-box;
	}

	.data-container {
		flex: 1;
		background-color: #fff;
		margin-top: 20px;
		border-radius: 20px;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		.title-wrapper {
			display: flex;
			justify-content: space-around;
			line-height: 80px;
			padding: 0 30px;

			.title-item {
				// padding: 0 20px;
				font-size: 28px;
			}
		}

		.subtitle-wrapper {
			display: flex;
			line-height: 60px;
			background-color: #fafafa;
			color: #aaa;
			text-align: center;
			padding: 0 30px;
		}

		.scroll-container {
			flex: 1;
			overflow: hidden;
		}
	}

	.account_moneyview {
		height: 400px;
		background-color: white;
		border-radius: 20px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.account_money_title {
		height: 28px;
		font-size: 20px;
		color: #8A8E99;
		line-height: 28px;
	}

	.account_money_num {
		font-size: 60px;
		font-family: DINAlternate-Bold, DINAlternate;
		font-weight: bold;
		color: #FF6685;
		line-height: 80px;
		span{
			font-size: 40px;
		}
	}

	.account_money_numlable {
		font-size: 34px;
		color: #FF6685;
		line-height: 65px;
		font-family: DINAlternate-Bold, DINAlternate;
		font-weight: bold;

	}

	.account_money_btn {
		margin-top: 30px;
		width: 160px;
		background: #1A1F29;
		border-radius: 35px;
		font-size: 30px;
		color: #FFFFFF;
		line-height: 60px;
		text-align: center;
	}

	.account_money_itemview {
		width: 100%;
		display: flex;
		margin-top: 30px;
	}

	.account_money_item {
		flex:1;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.account_money_itemnumview {
		display: flex;
		align-items: flex-end;
		font-family: DINAlternate-Bold, DINAlternate;
		font-weight: bold;
	}

	.account_money_itemnum {
		height: 45px;
		font-size: 38px;
		color: #262A33;
		line-height: 45px;
	}

	.account_money_itemnumlable {
		font-size: 24px;
		color: #262A33;
		line-height: 38px;
	}


	.account_money_itemtitle {
		height: 30px;
		font-size: 22px;
		color: #8A8E99;
		line-height: 30px;
		margin-top: 10px;
	}
	.icon-question-circle{
		margin-left: 3px;
		color: #aaa;
		font-size: 22px;
	}

	.account_money_itemsubtitle {
		height: 45px;
		font-size: 38px;
		font-family: DINAlternate-Bold, DINAlternate;
		font-weight: bold;
		color: #262A33;
		line-height: 45px;
	}

	.account_moneydetail {
		width: 100%;
		height: 100%;
		border-radius: 24px;
		background-color: white;
		padding: 0 20px 20px 20px;
		box-sizing: border-box;
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	.account_moneydetail_title {
		height: 45px;
		font-size: 32px;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 600;
		color: #262A33;
		line-height: 45px;
		padding-top: 20px;
	}

	.account_title_wrap {
		width: 100%;
		height: 70px;
		/* background: yellow; */
		box-sizing: border-box;
		padding: 0 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;

	}

	.account_title_item {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 10px;
		color: #8A8E99;
		font-size: 30px;
		/* border-bottom: 5px solid #FF6685; */
	}

	.select-tab {
		color: #262A33;
		font-weight: bolder;
		position: relative;
	}

	.select-tab::after {
		content: "";
		position: absolute;
		height: 6px;
		background-color: #FF6685;
		border-radius: 3px;
		width: 100%;
		left: 0;
		bottom: 10px;
	}

	.account_tableviewcell {
		width: 100%;
		border-bottom: 1px solid #F0F2F5;
		height: 125px;
		display: flex;
		align-items: center;
		font-size: 22px;
		color: #262A33;
		padding: 0 30px;
		box-sizing: border-box;
		.account_tablehead_title{
			line-height: 30px;
		}
	}

	.nomore {
		padding: 30px 0 50px;
		text-align: center;
		font-size: 24px;
		color: #aaa;
	}
</style>
