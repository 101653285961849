<template>
	<div>
		<navbar title="关于灰豚通告"></navbar>
		<div class="content">
			<img class="logo" src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/guanyu%403x.png" >
			<div class="version">V2.1.4</div>
			<div class="intro">汇集全网高颜值模特，包含大学生、宝妈、白领等人群，为商家提供优质的买家秀、产品主图、详情页，种草视频拍摄等一切需要模特的场景。</div>
		</div>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	export default{
		data(){
			return{
				
			}
		},
		components:{
			navbar
		}
	}
</script>

<style lang="scss" scoped>
	.content{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0 50px;
		height: 60vh;
		.logo{
			width: 180px;
			display: block;
		}
		.version{
			font-size: 36px;
			font-weight: 700;
			margin-top: 20px;
		}
		.intro{
			line-height: 45px;
			font-size: 30px;
			color: #666;
			padding: 60px 0;
		}
	}
</style>
