<template>
	<div class="container-wrapper">
		<navbar title="上传主图详情页作品"></navbar>
		<div class="top-container">
			<div class="top-title">
				上传主图/详情页作品
				<span class="title-tip" v-if="totalNum==0">(首次上传最少{{min_length}}组)</span>
				<span class="title-tip" v-if="totalNum>0">（已上传{{masterMapList.length}}组）</span>
			</div>
			<div class="title-tip2">
				<div class="tip-require">
					要求：请上传带有产品展示或者介绍的主图/详情页作品，每个产品至少3张照片。</div>
				<div>审核标准：</div>
				<div>1.场景布置 细节清晰 有一定创意</div>
				<div>2.白底图有角度创意和风格创意，如切开横切面等</div>
				<div>3.专业摄影场景最好 产品拍摄出品质感</div>
			</div>
		</div>
		<div class="upload-container">
			<div class="upload-item upload-item-first" @click="upload_action()">
				<img class="upload-image"
					src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/edit_album/upload_image%403x.png"
					mode="widthFix"></img>
				<div class="upload-text">上传图片</div>
			</div>
			<div class="upload-item" v-for="(item,index) in masterMapList" :key="index">
				<img class="image-item" :src="item.images[0]" mode="aspectFill"></img>
				<div class="image-num" @click="previewImage(item.images)">{{item.images.length}}张</div>
				<div class="video-title-con" @click="edit_action(index)">
					<div class="video-title">{{item.title}}</div>
					<div class="edit-con">
						<i class="iconfont icon-bianji1"></i>
					</div>
				</div>
				<i class="iconfont icon-close-bold" @click="deleteItem(index)"></i>
			</div>
		</div>
		<div class="save-btn" @click="sure_confirm">保存</div>

		<van-popup class="vant_popup_box" v-model="isShowModel">
			<div class="model-con">
				<div class="model-title">请输入产品标题</div>
				<div class="model-content">
					<textarea class="model-textarea" v-model="content" placeholder="例:春款碎花连衣裙 纯棉T恤套装"></textarea>
					<div class="video-container">
						<div class="type-title">选择图片(最少{{min_images_len}}张)</div>
						<div class="videos-con">
							<van-uploader :before-read="chooseImage" multiple>
								<div class="video-item upload-btn">
									<i class="iconfont icon-upload"></i>
									<div class="upload-text">上传图片</div>
								</div>
							</van-uploader>
							<div class="video-item" v-for="(item,index) in group_images" :key="index">
								<img class="image" :src="item.url"></img>
								<div v-if="item.status=='fail'" class="image_mask">
									<div class="image-status">上传失败</div>
								</div>
								<i class="iconfont icon-close-bold" @click="deleteImage(index)"></i>
							</div>
						</div>
					</div>
				</div>


				<div class="model-btn-con">
					<div class="model-btn-item model-btn-item-l" @click="hideModel">取消</div>
					<div class="model-btn-item model-btn-item-r" @click="setTitle">确定</div>
				</div>
			</div>
		</van-popup>

	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	import {
		uploadFile
	} from "../../../util/uploadBaogao.js"
	import { ImagePreview } from 'vant'
	export default {
		data() {
			return {
				masterMapList: [],
				modelName: "",
				min_length: 3,
				group_images: [],
				min_images_len: 3,
				content: "",
				group_index: null,
				totalNum: -1,
				flag: false,
				fromPage: null,
				isShowModel: false
			}
		},
		components: {
			navbar
		},
		activated() {
			this.getTotalCount()
		},
		methods: {
			getTotalCount() {
				let sentData = {
					access_token: localStorage.getItem("access_token")
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/index",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "n") {
							return
						}
						let result = JSON.parse(res.data.data)
						this.totalNum = result.zt_total_num
					});
			},
			upload_action() {
				this.is_edit = false
				this.content = ""
				this.group_images = []
				this.isShowModel = true
			},
			edit_action(index) {
				this.is_edit = true
				this.group_index = index
				this.content = this.masterMapList[index].title
				this.group_images = this.masterMapList[index].group_images
				this.isShowModel = true
			},
			chooseImage(files) {
				console.log("选择照片")
				let file = [];
				if (!Array.isArray(files)) {
					file.push(files)
				} else {
					file = files;
				}
				if(file.length){
					this.$toast.loading({
						message: "上传中",
						duration: 0
					});
				}
				for (let i = 0; i < file.length; i++) {
					console.log(file[i]);
					uploadFile(file[i], "user/img/masterMap").then(res => {
						console.log("上传结果====", res);
						this.group_images.push({
							status: res.status,
							url: res.status == "fail" ? this.getFileURL(res.url) : res.url
						});
						if(this.group_images.length >= file.length){
							this.$toast.clear();
						}
					})
				}
			},
			deleteImage(index) {
				this.group_images.splice(index, 1)
			},
			deleteItem(index) {
				this.masterMapList.splice(index, 1)
			},
			hideModel() {
				this.isShowModel = false
			},
			setTitle() {
				if (!this.content) {
					this.$toast("请输入产品标题")
					return
				}
				if (this.group_images.length < this.min_images_len) {
					this.$toast("每组作品上传图片不得少于" + this.min_images_len + "张")
					return
				}
				let images = this.group_images
				for (let i = 0; i < images.length; i++) {
					if (images[i].status == "fail") {
						this.$toast("有未上传成功的照片，请删除或重新上传")
						return
					}
				}
				let item = {
					title: this.content,
					group_images: this.group_images,
					images: this.group_images.map(item => item.url)
				}
				if (this.is_edit) {
					//编辑已上传的
					this.masterMapList[this.group_index] = item
				} else {
					//上传新的
					this.masterMapList.push(item)
				}
				this.hideModel()
			},
			sure_confirm() {
				if (this.totalNum == 0 && this.masterMapList.length < this.min_length) {
					this.$toast("首次上传至少" + this.min_length + "组作品")
					return
				} else if (this.masterMapList.length == 0) {
					this.$toast("至少上传1组作品")
					return
				}
				if (this.flag) {
					return
				}
				this.flag = true
				let zhutu_json = []
				this.masterMapList.forEach(item => {
					zhutu_json.push({
						title: item.title,
						photos: item.images.join(",")
					})
				})
				console.log(zhutu_json)


				let sentData = {
					access_token: localStorage.getItem("access_token"),
					zhutu_json: JSON.stringify(zhutu_json)
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/createzhutudetail",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "y") {
							this.$toast("创建成功，已提交审核")
							setTimeout(() => {
								this.$router.back(-1);
							}, 1200)
						} else {
							this.flag = false
							this.$toast(res.data.error)
						}
					});
			},
			previewImage(images) {
				ImagePreview({
					images
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper {
		background-color: #fff;
		overflow-y: auto;
	}

	.top-container {
		padding: 20px 24px;

		.top-title {
			line-height: 50px;
			font-size: 34px;
		}

		.title-tip {
			font-size: 24px;
			line-height: 32px;
			color: #8A8E99;
		}

		.title-tip2 {
			font-size: 24px;
			line-height: 32px;
			color: #aaa;
		}

		.tip-require {
			margin-bottom: 8px;
			color: #FF6685;
		}
	}

	.upload-container {
		display: flex;
		flex-wrap: wrap;
		padding: 0 24px;
	}

	.upload-container .upload-item {
		width: 224px;
		height: 224px;
		margin-right: 15px;
		margin-bottom: 15px;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		overflow: hidden;
		position: relative;
		.image-item{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.upload-item .icon-close-bold {
		position: absolute;
		right: 0;
		top: 0;
		line-height: 45px;
		width: 45px;
		text-align: center;
		color: #fff;
		font-size: 30px;
		background-color: rgba(0, 0, 0, 0.8);
		border-radius: 0 10px 0 10px;
	}

	.upload-container .upload-item-first {
		background-color: #F1F1F1;
	}

	.upload-item-first .upload-image {
		width: 60px;
	}

	.upload-text {
		margin-top: 10px;
		font-size: 28px;
		color: #aaa;
	}

	.upload-item:nth-child(3n) {
		margin-right: 0;
	}

	.upload-item .upload-video-item {
		width: 100%;
		height: 100%;
		display: block;
		border-radius: 10px;
		position: relative;
	}

	.vant_popup_box {
		border-radius: 15px;

		.model-con {
			width: 600px;
			padding: 30px 40px 40px;
			text-align: center;

			.model-title {
				font-weight: 700;
				font-size: 32px;
				color: #000;
				line-height: 60px;
			}

			.model-content {
				padding: 20px 0 30px;
				font-size: 30px;
				color: #000;
				text-align: center;
			}

			.model-textarea {
				width: 100%;
				height: 120px;
				background-color: #F5F5F5;
				border-radius: 6px;
				padding: 20px;
				box-sizing: border-box;
				text-align: left;
				line-height: 40px;
				border: none;
			}

			::-webkit-input-placeholder {
				/* WebKit, Blink, Edge */
				color: #aaa;
			}

			:-moz-placeholder {
				/* Mozilla Firefox 4 to 18 */
				color: #aaa;
			}

			::-moz-placeholder {
				/* Mozilla Firefox 19+ */
				color: #aaa;
			}

			:-ms-input-placeholder {
				/* Internet Explorer 10-11 */
				color: #aaa;
			}

			.type-title {
				text-align: left;
				font-size: 24px;
				color: #aaa;
				line-height: 60px;
			}

			.videos-con {
				display: flex;
				flex-wrap: wrap;
			}

			.video-container .video-item {
				width: 164px;
				height: 164px;
				border-radius: 6px;
				overflow: hidden;
				margin-right: 14px;
				margin-bottom: 14px;
				position: relative;
			}

			.upload-btn {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				color: #AAA;
				background-color: #F5F5F5;

				.icon-upload {
					font-size: 48px;
				}

				.upload-text {
					font-size: 22px;
					margin-top: 5px;
				}
			}

			.video-item:nth-child(3n) {
				margin-right: 0;
			}

			.video-item .image {
				width: 100%;
				height: 100%;
				display: block;
				object-fit: cover;
			}

			.image_mask {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background: rgba(0, 0, 0, 0.5);
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				font-size: 24px;
				color: #fff;
			}

			.video-item .icon-close-bold {
				position: absolute;
				right: 0;
				top: 0;
				line-height: 40px;
				width: 40px;
				text-align: center;
				color: #fff;
				font-size: 24px;
				background-color: rgba(0, 0, 0, 0.8);
				border-radius: 0 6px 0 6px;
			}

			.model-btn-con {
				display: flex;
				justify-content: space-between;
				text-align: center;
			}

			.model-btn-item {
				width: 230px;
				line-height: 70px;
				border-radius: 35px;
				border: 1px solid #ddd;
				color: #aaa;
				font-size: 30px;
			}

			.model-btn-item-r {
				background-color: #000000;
				color: #fff;
				border-color: #000000 !important;
			}
		}

	}




	.image-num {
		position: absolute;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
		top: 50%;
		left: 50%;
		border-radius: 50%;
		color: #fff;
		background-color: rgba(0, 0, 0, 0.6);
		transform: translate(-50%, -50%);
	}
	
	.image-status{
		color: #fff;
		font-size: 20px;
		line-height: 36px;
		border-radius: 18px;
		padding: 0 20px;
		border: 1px solid #fff;
	}



	.image-status-btn {
		font-size: 22px;
		border-radius: 28px;
		line-height: 36px;
		border: 1px solid #fff;
		padding: 0 20px;
		margin-top: 10px;
	}

	.cuIcon-loading2 {
		font-size: 40px;
		color: #fff;
		animation: 1s linear 0s normal none infinite rotation;
	}

	@keyframes rotation {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}



	.video-title-con {
		position: absolute;
		bottom: 0;
		line-height: 50px;
		height: 50px;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		color: #fff;
		font-size: 28px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.video-title-con .video-title {
		padding: 0 10px;
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.video-title-con .cuIcon-edit {
		background-color: #FF6685;
		font-size: 20px;
		color: #fff;
		height: 40px;
		line-height: 40px;
		width: 40px;
		text-align: center;
		border-radius: 50%;
		margin-right: 10px;
	}

	.edit-con {
		background-color: #FF6685;
		font-size: 20px;
		color: #fff;
		height: 40px;
		line-height: 40px;
		width: 40px;
		text-align: center;
		border-radius: 50%;

		.icon-bianji1 {
			font-size: 24px;
		}
	}

	.save-btn {
		height: 90px;
		line-height: 90px;
		border-radius: 15px;
		background: #1A1F29;
		color: #fff;
		font-size: 32px;
		position: fixed;
		bottom: 30px;
		left: 24px;
		width: 702px;
		text-align: center;
		box-shadow: 0 10px 15px 1px rgba(0, 0, 0, 0.3);
		z-index: 100;
	}
</style>
