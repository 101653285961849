<template>
	<div>
		<navbar title="通知设置"></navbar>
		<div style="background-color: #fff;">
			<div class="setting-item" @click="toSmsSetting">
				<div class="user_name">短信通知</div>
				<i class="iconfont icon-you"></i>
			</div>
			<div class="setting-item" @click="toInform">
				<div class="user_name">公众号通知</div>
				<van-switch v-if="showSwitch" v-model="isOpen" active-color="#FF6685" size="20"
					@change="switchChange" />
				<i v-else class="iconfont icon-you"></i>
			</div>
		</div>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	export default {
		data() {
			return {
				isOpen: false,
				showSwitch: false
			}
		},
		components: {
			navbar
		},
		activated() {
			this.getSwitchStatus()
		},
		methods: {
			switchChange(flag) {
				this.isOpen = flag
				this.changeSwitchStatus()
			},
			changeSwitchStatus() {
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					gzh_switch: this.isOpen ? 1 : 0
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/setgzhconf",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "y") {
							console.log("设置成功")
						} else {
							console.log(res.data.error)
							this.$toast(res.data.error)
						}
					});
			},
			getSwitchStatus() {
				let sentData = {
					access_token: localStorage.getItem("access_token")
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/getgzhconf",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "y") {
							let result = JSON.parse(res.data.data)
							this.isOpen = result.gzh_switch ? true : false
						}
					});
			},
			toSmsSetting() {
				this.$router.push({
					name: "smsSetting"
				});
			},
			toInform() {
				if (!this.showSwitch) {
					this.$router.push({
						name: "inform"
					});
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.setting-item {
		background-color: white;
		line-height: 110px;
		margin: 0 24px;
		display: flex;
		justify-content: space-between;
		border-bottom: 2px solid #F7F7F7;
		font-size: 30px;
		align-items: center;
	}

	.van-switch {
		font-size: 36px !important;
	}

	.setting-item:last-child {
		border: 0;
	}

	.icon-you {
		font-size: 20px;
		color: #aaa;
	}


	.user_logout_view {
		background-color: white;
		margin-top: 20px;
		line-height: 100px;
		font-size: 30px;
		color: #262A33;
		line-height: 120px;
		text-align: center;
	}
</style>
