<template>
  <div class="my-container">
    <div class="card-title">
      <div class="back_img">
        <img class="back_img"
             src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/Back%403x.png"
             @click="back_list"
             alt/>
      </div>
      身份认证
    </div>

    <div class="content" v-if="type==1">
      <img class="img" src="https://img.jimeimadou.com/common/seller_xcx_image/plaza/success.png"/>
      <div class="text">实名认证成功</div>
    </div>
    <div class="content" v-if="type==2">
      <img class="img" src="https://img.jimeimadou.com/common/seller_xcx_image/plaza/fail.png"/>
      <div class="text">实名认证失败</div>
      <div class="msg">{{msg}}</div>
    </div>
  </div>


</template>

<script>
  export default {
    name: "AuthSuccess",
    data(){
      return {
        type:0,
        msg:""
      }
    },
    methods:{
      back_list() {
        this.$router.back(-1);
      }
    },
    activated(){
      if(this.$route.query.type){
        this.type = this.$route.query.type;
      }
      if(this.$route.query.msg){
        this.msg = this.$route.query.msg;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .my-container{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .card-title {
      width: 100%;
      height: 80px;
      display: flex;
      font-size: 35px;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 20px;
      background: #fff;


      .back_img {
        position: absolute;
        left: 20px;
        width: 50px;
        height: 50px;
        background-color: rgba(220, 220, 220, 0.5);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 80%;
          width: 80%;
          margin-left: -10px;
          opacity: 0.6;
        }
      }
    }

    .content {
      margin-top: 200px;
      text-align: center;
      .img {
        width: 200px;
      }
      .text {
        margin-top: 40px;
        font-weight: 700;
        color: #000;
        font-size: 40px;
      }
      .msg {
        color: red;
        margin-top: 30px;
        font-size: 30px;
      }
    }

  }

</style>