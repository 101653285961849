<template>
	<div>
		<back-top-fixed></back-top-fixed>
		<scroll-y class="scroll-container" ref="iscroll">
			<mt-detail-top album_type="family" @createFamilyAlbum="createFamilyAlbum" @editFamilyAlbum="editFamilyAlbum"></mt-detail-top>
			
			<van-tabs background="transparent" line-height="0" swipe-threshold="3">
				<van-tab v-for="(item,index) in tabs" :key="index">
					<template #title>
						<div class="tab-item-con">
							<div class="tab-item" ref="tab" @click="chooseTab(index)">
								<div :class="['tab-name',current==index?'current':'']">{{mdTypes[item.md_type]}}</div>
								<div class="tab-count" :style="current==index?'color:#FF6685':''">{{item.photo_list.length}}
								</div>
								<div v-if="item.audit_status" :class="['tab-status',auditStatus[item.audit_status].class]">
									{{auditStatus[item.audit_status].name}}
								</div>
							</div>
						</div>
					</template>
				</van-tab>
			</van-tabs>
			
			
			<div class="list-container" v-if="family_album.photo_list">
				<empty v-if="family_album.photo_list.length==0||tabs.length==0" title="暂无内容"></empty>
				<div v-else>
					<div class="family_mtinfo_item" v-if="tabs.length!=0">
						<div style="display: flex;">
							<div style="margin-right: 10px;">模特信息</div>
							<div class="mt_info_item" v-if="family_album.age&&family_album.age!=0">
								{{family_album.age}}岁
							</div>
							<div class="mt_info_item" v-if="family_album.height&&family_album.height!=0">
								{{family_album.height}}cm
							</div>
							<div class="mt_info_item" v-if="family_album.weight&&family_album.weight!=0">
								{{family_album.weight}}kg
							</div>
							<div class="size-item" v-if="family_album.coat">
								<img
									src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/edit_album/album_coat.png"></img>
								<div>{{family_album.coat}}</div>
							</div>
							<div class="size-item" v-if="family_album.trousers">
								<img
									src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/edit_album/album_pant.png"></img>
								<div>{{family_album.trousers}}</div>
							</div>
							<div class="size-item" v-if="family_album.cups">
								<img
									src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/edit_album/album_cup.png"></img>
								<div>{{family_album.cups}}</div>
							</div>
							<div class="size-item" v-if="family_album.shoes">
								<img
									src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/edit_album/album_shoe.png"></img>
								<div>{{family_album.shoes}}</div>
							</div>
						</div>
					</div>
					<div class="photos-container">
						<div class="photo-item" v-for="(item,index) in family_album.photo_list" :key="index"
							@click="previewImages(index)">
							<img class="photo-img" v-lazy="addWaterMark(item)">
						</div>
					</div>
				</div>
			</div>
		</scroll-y>
	</div>
</template>

<script>
	import empty from "@/components/empty.vue"
	import scrollY from "@/components/app-scroll-y.vue"
	import albumModel from "@/components/album-model.vue"
	import mtDetailTop from "@/components/mtdetail-top.vue"
	import backTopFixed from "@/components/back-top-fixed.vue"
	import {
		ImagePreview
	} from 'vant';
	export default {
		data() {
			return {
				tabs: [],
				family_album: [],
				current: 0,
				mdTypes: this.mdTypes,
				auditStatus: {
					1: {
						name: "审核中",
						class: "tab-status1"
					},
					2: {
						name: "已通过",
						class: "tab-status2"
					},
					3: {
						name: "已拒绝",
						class: "tab-status3"
					}
				},
				previewCompressParams: "?imageView2/2/w/1000", //预览图片压缩参数
				waterMark: "|watermark/1/blogo/2/image/aHR0cDovL2ptc3RhdGljLTEyNTU0OTc5MTYuY29zLmFwLXNoYW5naGFpLm15cWNsb3VkLmNvbS9jb21tb24vd2F0ZXJtYXJrZXIucG5n/dissolve/90/dx/10/dy/10",
				modelName:""
			}
		},
		components: {
			mtDetailTop,
			empty,
			scrollY,
			albumModel,
			backTopFixed
		},
		activated() {
			this.getFamilyAlbum()
		},
		methods: {
			//获取亲情相册列表
			getFamilyAlbum() {
				let sentData = {
					access_token: localStorage.getItem("access_token")
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/familyphotolist",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						// console.log(res.data)
						if (res.data.status == "n") {
							return
						}
						let result = JSON.parse(res.data.data)
						console.log("亲情相册列表===",result)
						if (result.length != 0) {
							for (let i = 0; i < result.length; i++) {
								result[i].audit_status = parseInt(result[i].audit_status)
								result[i].photo_list = result[i].photo_list ? result[i].photo_list.split(";") : []
							}
							this.tabs = result
							this.family_album = result[this.current] || result[0]
						}
					});
			},
			chooseTab(index) {
				this.current = index
				this.$refs.iscroll.scrollToTop()
				this.family_album = this.tabs[index]
			},
			previewImages(index) {
				let photos = this.family_album.photo_list
				let list = []
				photos.forEach(item => {
					let imageUrl = item + this.previewCompressParams + this.waterMark
					list.push(imageUrl)
				})
				// console.log(list)
				ImagePreview({
					images: list,
					startPosition: index
				})
			},
			createFamilyAlbum(){
				console.log("创建亲情相册")
				this.$router.push({
					name: "createFamilyAlbum"
				});
			},
			editFamilyAlbum(){
				console.log("更新亲情相册")
				this.$router.push({
					name: "editFamilyAlbum"
				});
			},
			addWaterMark(url) {
				let compressParams = "?imageView2/1/w/500/h/700"
				let waterMark =
					"|watermark/1/blogo/2/image/aHR0cDovL2ptc3RhdGljLTEyNTU0OTc5MTYuY29zLmFwLXNoYW5naGFpLm15cWNsb3VkLmNvbS9jb21tb24vd2F0ZXJtYXJrZXIucG5n/dissolve/90/dx/10/dy/10"
				let newUrl = url + compressParams + waterMark
				newUrl = newUrl.replace('https://img.jimeimadou.com',
					'https://jmstatic-1255497916.cos-website.ap-shanghai.myqcloud.com')
				return newUrl
			}
		}
	}
</script>

<style lang="scss" scoped>
	.scroll-container {
		width: 100vw;
		height: 100vh;
	}
	
	::v-deep .van-tab{
		padding: 0;
	}

	.tab-item-con {
		padding: 0 24px;
		display: inline-block;
		white-space: nowrap;

		.tab-item {
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: 60px;
		}

		.tab-name {
			position: relative;
			font-weight: 700;
			font-size: 32px;
		}

		.current {
			color: #FF6685;
		}

		.current::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 5px;
			border-radius: 3px;
			background-color: #FF6685;
		}

		.tab-status {
			height: 30px;
			line-height: 30px;
			font-size: 24px;
			padding: 0 10px;
			margin-left: 15px;
		}

		.tab-status1 {
			background: #E0EDFF;
			color: #338CFF;
		}

		.tab-status2 {
			background: #F2F3F7;
			color: #1DC11D;
		}

		.tab-status3 {
			background: #FFE0E7;
			color: #FF6685;
		}

		.tab-count {
			padding-left: 10px;
		}
	}

	.family_mtinfo_item {
		line-height: 50px;
		height: 50px;
		font-size: 24px;
		background-color: #fff;
		padding: 0 20px;
		border-radius: 10px;
		margin: 0 24px 15px;
		display: flex;
		justify-content: space-between;
	}

	.family_mtinfo_item>div {
		white-space: nowrap;
	}

	.family_mtinfo_item img {
		height: 20px;
		width: auto;
		display: block;
		margin-right: 8px;
	}

	.family_mtinfo_item .mt_info_item {
		text-align: center;
		padding: 0 10px;
	}

	.family_mtinfo_item .size-item {
		padding: 0 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: normal;
	}


	.photos-container {
		padding: 0 24px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.photo-item {
		width: 344px;
		height: 480px;
		position: relative;
		margin-bottom: 15px;
		border-radius: 10px;
		overflow: hidden;

		.photo-img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
</style>
