<template>
	<div class="login_container">
		<back-top-fixed></back-top-fixed>
		<div class="bg-round"></div>
		<div class="bg-square"></div>
		<div class="center-box">
			<div class="center">
				<div class="title-con">
					<div class="welcome">欢迎使用</div>
					<div class="title">灰豚通告</div>
				</div>
				<div class="input-con">
					<div class="label">手机号</div>
					<input class="input" v-model="phone" placeholder="请输入手机号" placeholder-class="placeholder" />
				</div>
				<div class="input-con">
					<div class="label">验证码</div>
					<div style="flex: 1;">
						<input class="input" v-model="smsCode" @input="getCode" placeholder="请输入验证码" placeholder-class="placeholder" />
					</div>
					<div class="send-btn has-send" v-if="isSend">重新发送({{time}}s)</div>
					<div class="send-btn" v-else @click="sendCode">发送验证码</div>
				</div>

				<div class="login-btn" @click="login">立即登录</div>


				<div class="wxLogin-btn" @click="toWxLogin">
					<i class="iconfont icon-weixin1"></i>
				</div>
				<div class="tip-con">
					<div>登录方式</div>
					<div>已认证用户：短信验证码和微信均可使用</div>
					<div>其他用户：仅可使用微信登录</div>
				</div>
			</div>
		</div>
		<!-- <div class="agreement">
			<div>
				<span>我已阅读并同意</span>
				<span @tap="toAgreeMent(1)">《灰豚通告用户协议》</span>
				<span @tap="toAgreeMent(2)">《灰豚通告社区规范》</span>
			</div>
		</div> -->
	</div>
</template>

<script>
	import backTopFixed from "@/components/back-top-fixed.vue"
	import qs from "qs";
	import axios from "axios";
	export default {
		data() {
			return {
				fromBindPhone: false,
				time: 60,
				isSend: false,
				is_agree: false,
				usePassword: false,
				phone: "",
				password: "",
				smsCode: "",
				modelName: "",
				code: null,
			};
		},
		components: {
			backTopFixed
		},
		methods: {
			initData() {
				this.time = 60
				this.isSend = false
				this.phone = ""
				this.smsCode = ""
			},
			//发送短信验证码
			sendCode() {
				if (!this.phone) {
					this.$toast('请输入手机号码')
					return
				}
				let initparams = {
					phone: this.phone
				};
				let params = this.sign_module.signParams(initparams);
				params["_platform"] = "mobi";
				params["_version"] = "1.0.0";
				//console.log(params);
				axios.post(
						process.env.VUE_APP_URL + "/mobi/sms/send",
						qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						//console.log(res.data)
						if (res.data.status == "n") {
							this.$toast(res.data.error)
							return
						}
						this.$toast("发送成功")
						this.isSend = true
						this.countDown()
					});
			},
			//倒计时
			countDown() {
				let total_time = this.time
				let self = this
				let timer = setInterval(function() {
					self.time--
					if (self.time == 0) {
						self.isSend = false
						self.time = total_time
						clearInterval(timer)
						return
					}
				}, 1000)
			},
			sureAgree() {
				this.is_agree = true
				this.login()
			},
			toWxLogin() {
				// this.$toast('功能暂未开放');
				// let appid = ''; 
				// let uri = encodeURIComponent("https://api.jimeimadou.com/mobi/user/mauth"); 
				// let authURL = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${uri}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`; //这里使用的是非静默授权

				window.location.href = "https://api.jimeimadou.com/mobi/user/mauth";
			},
			//用户登录
			login() {
				if (!this.phone) {
					this.$toast('请输入手机号码');
					return
				}
				if (!this.smsCode) {
					this.$toast('请输入短信验证码');
					return
				}
				let initparams = {
					phone: this.phone,
					sms_code: this.smsCode
				};
				let params = this.sign_module.signParams(initparams);
				params["_platform"] = "mobi";
				params["_version"] = "1.0.0";
				//console.log(params);
				axios.post(
						process.env.VUE_APP_URL + "/mobi/user/loginv2",
						qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "n") {
							this.$toast(res.data.error);
							return
						}
						let result = JSON.parse(res.data.data);
						//console.log(result)
						localStorage.setItem("access_token", result.access_token);
						this.getyxtoken();
						this.$router.back(-1);
					});
			},
			getCode() {
				if (this.smsCode.length > 6) this.smsCode = this.smsCode.slice(0, 6)
			},
			getyxtoken() {
				let sendData = {
					access_token: localStorage.getItem('access_token'),
				};
				let params = this.$addParams(sendData);
				this.$axios.post(
					process.env.VUE_APP_URL + "/mobi/user/getyxtoken",
					this.$qs.stringify(params), {
						headers: {
							"Content-Type": "application/x-www-form-urlencoded",
						},
					}
				).then((res) => {
					if (res.data.status == 'y') {
						let result = JSON.parse(res.data.data);
						console.log("云信token返回结果", result);
						let {
							accid,
							token
						} = result;
						//连接socket
						this.$store.dispatch('createSocketConnect', {
							account: accid,
							token: token
						});
					}
				})
			}
		},
		activated() {
			this.initData()
		}
	}
</script>

<style lang="scss" scoped>
	.login_container {
		width: 100vw;
		height: 100vh;
		background-color: #FEFEFE;
		position: relative;
		overflow: hidden;

		.bg-round {
			position: absolute;
			top: 0;
			right: 0;
			width: 700px;
			height: 700px;
			border-radius: 50%;
			border: 100px solid #FFDDDE;
			transform: translate(50%, -50%);
		}

		.bg-square {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 500px;
			height: 500px;
			border: 100px solid #C6E2FA;
			transform: translate(-20%, 50%) rotate(45deg);
		}

		.center-box {
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin: 0 auto;

			.center {
				width: 580px;
				margin: 0 auto;
			}

			.title-con {
				font-size: 44px;
				font-weight: 700;
				margin-bottom: 80px;

				.welcome {
					font-size: 36px;
				}

				.title {
					margin-top: 10px;
					text-shadow: -1.5px -1.5px 0 #0ff, 1.5px 1.5px 0 #f00;
					font-size: 52px;
					// animation: animate 0.5s linear infinite;
				}

				.title-tip {
					font-size: 20px;
					color: #AAAAAA;
					margin-top: 10px;
					font-weight: normal;
				}

				@keyframes animate {

					0%,
					100% {
						text-shadow: -1.5px -1.5px 0 #0ff, 1.5px 1.5px 0 #f00;
					}

					25% {
						text-shadow: 1.5px 1.5px 0 #0ff, -1.5px -1.5px 0 #f00;
					}

					50% {
						text-shadow: 1.5px -1.5px 0 #0ff, 1.5px -1.5px 0 #f00;
					}

					75% {
						text-shadow: -1.5px 1.5px 0 #0ff, -1.5px 1.5px 0 #f00;
					}
				}
			}

			.input-con {
				margin-bottom: 40px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				line-height: 90px;
				background-color: #f7f7f7;
				border-radius: 45px;
				font-size: 32px;

				.label {
					width: 120px;
					color: #888;
					font-weight: 700;
					// margin-left: 30px;
					white-space: nowrap;
					flex-shrink: 0;
					margin-left: 30px;
				}

				.input {
					height: 90px;
					width: 100%;
					padding-right: 45px;
					background-color: transparent;
				}

				.placeholder {
					color: #DDDDDD;
				}

				.send-btn {
					height: 60px;
					line-height: 60px;
					background-color: #000;
					font-size: 24px;
					color: #FFFFFF;
					border-radius: 30px;
					padding: 0 20px;
					margin-right: 20px;
					white-space: nowrap;
				}

				.has-send {
					background-color: rgba(0, 0, 0, 0.5);
				}
			}


			.login-btn {
				background-color: #FF6685;
				line-height: 90px;
				border-radius: 45px;
				color: #FFFFFF;
				font-weight: 700;
				font-size: 34px;
				text-align: center;
			}

			.btn-bottom {
				display: flex;
				justify-content: space-between;
				padding: 30px 15px 150px;
				line-height: 40px;
				font-weight: 700;
				font-size: 26px;
			}

			.wxLogin-btn {
				width: 120px;
				height: 120px;
				line-height: 120px;
				border-radius: 50%;
				background-color: #FFFFFF;
				box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.05);
				text-align: center;
				margin: 100px auto 60px;

				.icon-weixin1 {
					color: #00C800;
					font-size: 48px;
				}

				.getPhoneBtn {
					position: absolute;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					background-color: transparent;
				}
			}

			.tip-con {
				font-size: 24px;
				color: #FF6685;
				line-height: 30px;
			}
		}

		.agreement {
			position: fixed;
			width: 70%;
			left: 15%;
			bottom: 100px;
			text-align: center;
			color: #aaa;
			font-size: 22px;
			display: flex;
			justify-content: center;
			line-height: 34px;

			.agree-icon {
				font-size: 26px;
			}

			.cuIcon-roundcheckfill {
				color: #FF6685;
			}
		}

		.agree-model {
			padding: 30px;
			border-radius: 20px 20px 0 0 !important;
			background-color: #fff;

			.model-title {
				font-size: 34px;
				font-weight: 700;
			}

			.agree-content {
				padding: 60px 0;
				text-align: center;
				color: #FF6685;
				font-size: 26px;
			}

			.agree-btn {
				background-color: #FF6685;
				color: #fff;
				border-radius: 40px;
				line-height: 80px;
				margin: 20px 30px;
				font-size: 32px;
			}
		}

	}
</style>
