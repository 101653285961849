<template>
	<div class="container-wrapper">
		<navbar title="余额提现"></navbar>
		<div class="drawmoney_container">
			<div class="drawmoney_inputcontainer">
				<div class="drawmoney_tip">提现最低1元。单笔不超过1000，每天可以提3次。提交申请后，预计在48小时内到账。</div>
				<div class="drawmoney_inpuview">
					<div class="drawmoney_inpuview_title">提现金额</div>
					<div class="drawmoney_inputcontent">
						<div class="drawmoney_input_label">￥</div>
						<input class="drawmoney_input" v-model="money" placeholder-class="drawmoney_input_placeholder"
							placeholder="请输入提现金额"></input>
					</div>
				</div>
				<div class="drawmoney_inputsubtitle">账户余额：{{balance}}元</div>
			</div>


			<div class="drawmoney_platformcontainer">
				<div class="drawmoney_platform_title">提现到</div>
				<div class="drawmoney_platformitem" v-for="(item,index) in payments" :key="index"
					@click="chooseType(item.type)">
					<div class="drawmoney_platform_sel" :class="to_type==item.type?'drawmoney_platform_selected':''">
					</div>
					<img class="drawmoney_platform_img" :src="item.icon"></img>
					<div class="drawmoney_platform_name">{{item.text}}</div>
				</div>

				<div class="zfb_info_wrap" v-if="to_type=='alipay'">
					<div class="zfb_info_item">
						<div class="zfb_tit">支付宝账号</div>
						<input class="zfb_inp" v-model="zfb_number" placeholder="请输入支付宝账号" />
					</div>
					<div class="zfb_info_item">
						<div class="zfb_tit">支付宝姓名</div>
						<input class="zfb_inp" v-model="zfb_name" placeholder="请输入支付宝账号名称" />
					</div>
				</div>
			</div>
			<div class="drawmoney_btn" @click="submit">确认提现</div>
		</div>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	export default {
		data() {
			return {
				money: '',
				zfb_number: '',
				zfb_name: '',
				to_type: 'alipay',
				// payments: [{
				// 	text: "微信",
				// 	icon:"https://img.jimeimadou.com/common/resource/mine_icon/mine_weixin_icon.png",
				// 	type: "wechat"
				// }, {
				// 	text: "支付宝",
				// 	icon:"https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/mine_icon/zfb%403x.png",
				// 	type: "alipay"
				// }],
				payments: [{
					text: "支付宝",
					icon:"https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/mine_icon/zfb%403x.png",
					type: "alipay"
				}],
				balance:0
			}
		},
		components:{
			navbar
		},
		activated() {
			this.initData()
			this.getUserInfo()
			let zfb_number = localStorage.getItem('zfb_number');
			if (zfb_number) {
				this.setData({
					zfb_number: zfb_number,
					zfb_name: localStorage.getItem('zfb_name')
				})
			}
		},
		methods: {
			initData(){
				this.money = ''
				this.zfb_number = ''
				this.zfb_name = ''
			},
			getUserInfo() {
				let sentData = {
					access_token: localStorage.getItem("access_token")
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/index",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						//console.log(res.data)
						if (res.data.status == "n") {
							return
						}
						let result = JSON.parse(res.data.data)
						this.userInfo = result
						this.balance = this.userInfo.remain_amount/100
					});
			},
			chooseType(type) {
				this.to_type = type
			},
			//确认体现
			submit() {
				if (parseFloat(this.money) <= 0) {
					this.$toast('请输入金额')
					return
				}
				if (parseFloat(this.money) < 1) {
					this.$toast('提现最低1元')
					return
				}
				if (parseFloat(this.money) > 1000) {
					this.$toast('单笔提现最高1000元')
					return
				}
				if (this.to_type == "alipay") {
					if(!this.zfb_number){
						this.$toast('请输入支付宝账号')
						return
					}
					if (!this.zfb_name) {
						this.$toast('请输入账号名称')
						return
					}
				}
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					amount: parseFloat(this.money) * 100,
					to_type: this.to_type
				}
				if (this.to_type == "alipay") { //支付宝
					sentData['alipay_name'] = this.zfb_name;
					sentData['alipay_account'] = this.zfb_number;
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/withdraw",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'n') {
							this.$toast(res.data.error)
							return
						}
						this.$toast("申请成功")
						localStorage.setItem('zfb_number', this.zfb_number)
						localStorage.setItem('zfb_name', this.zfb_name)
						setTimeout(()=>{
							this.$router.back(-1);
						},1200)
					})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper{
		background-color: #f5f5f5;
	}
	.drawmoney_container {
		padding: 20px 24px;
	}

	.drawmoney_tip {
		font-size: 24px;
		line-height: 34px;
	}

	.drawmoney_inputcontainer {
		border-radius: 24px;
		background-color: white;
		padding: 20px;
	}

	.drawmoney_inputtitle {
		height: 33px;
		font-size: 24px;
		color: #8A8E99;
		line-height: 33px;
	}

	.drawmoney_inpuview {
		width: 100%;
		display: flex;
		align-items: center;
		margin-top: 30px;
	}

	.drawmoney_inpuview_title {
		font-size: 24px;
		color: #262A33;
		flex-shrink: 0;
	}

	.drawmoney_inputcontent {
		height: 70px;
		background: #FFFFFF;
		border-radius: 3px;
		border: 1px solid #EBECF0;
		margin-left: 10px;
		width: 100%;
		display: flex;
		align-items: center;
	}

	.drawmoney_input_label {
		width: 24px;
		font-size: 24px;
		color: #262A33;
		margin-left: 10px;
		flex-shrink: 0;
	}

	.drawmoney_input {
		margin-left: 10px;
		width: 100%;
		height: 100%;
	}

	.drawmoney_input_placeholder {
		color: #B8BECC;
		font-size: 24px;
	}

	.drawmoney_inputsubtitle {
		font-size: 22px;
		color: #B8BECC;
		line-height: 60px;
		margin-left: 106px;
	}

	.drawmoney_platformcontainer {
		width: 100%;
		/* height: 185px; */
		background-color: white;
		border-radius: 24px;
		margin-top: 20px;
		padding: 0 20px 20px;
		box-sizing: border-box;
	}

	.drawmoney_platform_title {
		font-size: 32px;
		font-weight: 600;
		color: #262A33;
		line-height: 45px;
		padding-top: 20px;
	}

	.drawmoney_platformitem {
		display: flex;
		align-items: center;
		padding: 15px 0;
	}

	.drawmoney_platform_select {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		border: 2px solid #E1E3EB;
	}


	.drawmoney_platform_sel {
		width: 24px;
		height: 24px;
		border-radius: 50%;
		border: 2px solid #E1E3EB;
		box-sizing: border-box;
		padding: 6px;
		background: transparent;
		background-clip: padding-box;
	}

	.drawmoney_platform_selected {
		background: limegreen;
	}

	.drawmoney_platform_img {
		width: 50px;
		height: 50px;
		margin-left: 20px;
	}

	.drawmoney_platform_name {
		height: 33px;
		font-size: 24px;
		color: #262A33;
		line-height: 33px;
		margin-left: 20px;
	}

	.drawmoney_btn {
		width: 400px;
		height: 80px;
		background: #1A1F29;
		border-radius: 40px;
		font-size: 34px;
		color: #FFFFFF;
		line-height: 80px;
		text-align: center;
		position: fixed;
		bottom: 100px;
		left: 50%;
		transform: translateX(-50%);
	}

	.zfb_info_wrap {
		width: 100%;
		padding: 10px 0;

	}

	.zfb_info_item {
		display: flex;
		justify-content: space-around;
		align-items: center;
		width: 100%;
		height: 80px;
		margin: 10px 0;
	}

	.zfb_tlt {
		color: #262A33;
		font-size: 28pxx;
	}

	.zfb_inp {
		width: 490px;
		height: 70px;
		border: 1px solid #EBECF0;
		box-sizing: border-box;
		padding: 0 10px;
	}
</style>
