<template>
	<div>
		<div :class="['mask',show?'mask-show':'']" @click="hideMask">
			<div :class="['model-container',show?'model-show':'']" @click.stop="preventEvent">
				<div class="model-title">{{model_title}}</div>
				<div class="size-container" v-if="type=='mdType'">
					<div class="size-item-con" v-for="(item,index) in mdTypeList" :key="index">
						<div :class="['size-item',select_item==item.type?'selected':'']"
							@click="selectModelType(item.type)">{{item.name}}</div>
					</div>
				</div>
				<div class="size-container" v-else>
					<div class="size-item-con" v-for="(item,index) in show_size" :key="index">
						<div :class="['size-item',select_item==item?'selected':'']" @click="selectSizeItem(item)">
							{{item}}</div>
					</div>
				</div>
				<div class="model-btn">
					<div class="confirm-btn" @click.stop="confirm">确认</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			show: {
				type: Boolean, //显示弹框
				default: false
			},
			type: {
				type: String, //尺寸类型
				default: ""
			},
			value: {
				type: [Number,String], //进来默认选中
				default: ""
			},
			isChild: {
				type: Boolean, //是否童模
				default: false
			}
		},
		watch: {
			//监听传进来值的变化
			show(value) {
				if (value) {
					this.select_item = this.value
					console.log(`打开 ${this.type} 弹框，默认选中 ${this.value||'无'}，是否是童模 ${this.isChild}`)
					switch (this.type) {
						case "mdType":
							this.model_title = "请选择模特类型"
							break;
						case "coat":
							this.model_title = "请选择上衣尺寸"
							this.show_size = this.isChild ? this.child_coat_size : this.coat_size
							break;
						case "trousers":
							this.model_title = "请选择裤子尺寸"
							this.show_size = this.isChild ? this.child_trousers_size : this.trousers_size
							break;
						case "shoes":
							this.model_title = "请选择鞋子尺寸"
							this.show_size = this.isChild ? this.child_shoes_size : this.shoes_size
							break;
						case "cup":
							this.model_title = "请选择罩杯尺寸"
							this.show_size = this.cup_size
							break;
						default:
							break;
					}
				}
			}
		},
		data() {
			return {
				//模特类型
				mdTypeList: [{
					name: "男模",
					type: 101
				}, {
					name: "女模",
					type: 102
				}, {
					name: "童模",
					type: 103
				}, {
					name: "中老年女模",
					type: 104
				}, {
					name: "中老年男模",
					type: 105
				}, {
					name: "情侣模特",
					type: 106
				}, {
					name: "亲子模特",
					type: 107
				}, {
					name: "宠模",
					type: 108
				}],
				//成人 上衣裤子鞋子罩杯尺码
				coat_size: ['XS', 'S', 'M', 'L', 'XL', 'XXL', '3XL', '4XL'],
				trousers_size: [25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40],
				shoes_size: [35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45],
				cup_size: ['70A', '70B', '75A', '75B', '75C', '80A', '80B', '80C', '85A', '85B', '85C', '90A'],

				//童模 上衣裤子鞋子尺码
				child_coat_size: [52, 59, 66, 73, 80, 90, 100, 110, 120, 130, 140, 150, 160, 165, 170],
				child_trousers_size: [70, 80, 90, 100, 110, 120, 130, 140, 150, 160],
				child_shoes_size: [16, 17, 18, 19, 20, 22, 23, 24, 25, 27, 29, 31, 32, 33, 34, 35, 36],

				model_title: "",
				show_size: [],
				select_item: "",
				select_type: 0
			}
		},
		methods: {
			selectSizeItem(size) {
				this.select_item = size
			},
			selectModelType(type) {
				this.select_item = type
			},
			preventEvent() {},
			hideMask() {
				this.$emit('cancel')
			},
			//确认提交
			confirm() {
				console.log(`选中的 ${this.type} 是 ${this.select_item}`)
				this.$emit('confirm', {
					type: this.type,
					value: this.select_item
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.mask {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0);
		transition: 0.3s;
		visibility: hidden;
		z-index: 1000;
	}

	.mask-show {
		background-color: rgba(0, 0, 0, 0.5);
		visibility: visible;
	}

	.model-container {
		position: absolute;
		bottom: 0;
		width: 100%;
		box-sizing: border-box;
		border-radius: 20px 20px 0 0;
		background-color: #fff;
		transform: translateY(100%);
		transition: 0.3s;
		visibility: hidden;
		z-index: 1000;
		/* display: none; */
	}

	.model-show {
		transform: translateY(0);
		opacity: 1;
		visibility: visible;
		/* display: block; */
	}

	.model-title {
		line-height: 120px;
		font-size: 34px;
		font-weight: 700;
		color: #000;
		text-align: center;
	}


	.size-container {
		display: flex;
		flex-wrap: wrap;
		padding: 0 20px;
	}

	.size-item-con {
		width: 25%;
		padding: 8px;
	}

	.size-item {
		text-align: center;
		background-color: #f5f5f5;
		border-radius: 15px;
		border: 1px solid #F5F5F5;
		line-height: 64px;
	}

	.selected {
		border-color: #FF6685;
		background-color: #FFEBEF;
		color: #FF6685;
	}

	.model-btn {
		display: flex;
		justify-content: center;
		padding: 40px 0 60px;
	}

	.confirm-btn {
		width: 300px;
		line-height: 80px;
		border-radius: 40px;
		text-align: center;
		font-size: 30px;
		background-color: #000;
		color: #fff;
	}
</style>
