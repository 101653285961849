<template>
	<div class="top_info_wrap">
		<img class="bg-img" :src="mtinfo.bg_cover?mtinfo.bg_cover:'https://img.jimeimadou.com/common/resource/mine_icon/default_bg01.jpg'" >
		<div class="mtinfo-container">
			<div class="info_top">
				<img class="headico_wrap" :src="mtinfo.head_ico" mode="aspectFill"></img>
				<div class="main_info">
					<div class="main_top">
						<div class="nick_name">{{mtinfo.nick_name}}</div>
						<div class="can-photo" v-if="mtinfo.is_zckb==1" style="background-color: #8AE6A8;">可种草</div>
						<div class="can-photo" v-if="mtinfo.is_ztxq==1" style="background-color: #FF6685;">可拍主图</div>
					</div>
					<div class="top-channel-con" v-if="mtChannelList&&mtChannelList.length!=0" @tap="showModel('fansModel')">
						<img class="top-channel-icon" v-for="(item,index) in mtChannelList" :key="index"
							:src="item.icon" mode="aspectFit"></img>
					</div>
					<div class="main_m_b">
						<div class="m_b_item" v-if="mtinfo.province_name">
							<img mode="widthFix" class="m_b_item_icon"
								src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/userInfo_icon/dingwei%403x.png" />
							<span class="m_b_item_text">{{checkProvince(mtinfo.province_name)}}</span>
						</div>
						<div class="m_b_item" v-if="mtinfo.age&&mtinfo.age!=0">
							<img mode="widthFix" class="m_b_item_icon"
								src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/userInfo_icon/nianling%403x.png" />
							<span class="m_b_item_text">{{mtinfo.age}}</span>
						</div>
						<div class="m_b_item" v-if="mtinfo.height">
							<img mode="widthFix" class="m_b_item_icon"
								src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/userInfo_icon/shengao%403x.png" />
							<span class="m_b_item_text">{{mtinfo.height}}cm</span>
						</div>
						<div class="m_b_item" v-if="mtinfo.weight">
							<img mode="widthFix" class="m_b_item_icon"
								src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/userInfo_icon/tizhong%403x.png" />
							<span class="m_b_item_text">{{mtinfo.weight}}kg</span>
						</div>
					</div>
					<div class="main_m_b">
						<div class="m_b_item" v-if="mtinfo.coat">
							<img mode="widthFix" class="m_b_item_icon"
								src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/userInfo_icon/shangyi%403x.png" />
							<span class="m_b_item_text">{{mtinfo.coat}}</span>
						</div>
						<div class="m_b_item" v-if="mtinfo.trousers">
							<img mode="widthFix" class="m_b_item_icon"
								src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/userInfo_icon/kuzi%403x.png" />
							<span class="m_b_item_text">{{mtinfo.trousers}}</span>
						</div>
						<div class="m_b_item" v-if="mtinfo.shoes">
							<img mode="widthFix" class="m_b_item_icon"
								src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/userInfo_icon/xiema%403x.png" />
							<span class="m_b_item_text">{{mtinfo.shoes}}</span>
						</div>
						<div class="m_b_item" v-if="mtinfo.cups">
							<img mode="widthFix" class="m_b_item_icon"
								src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/userInfo_icon/zhaobei%403x.png" />
							<span class="m_b_item_text">{{mtinfo.cups}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="info_middle">{{mtinfo.autograph?mtinfo.autograph:'暂无简介'}}</div>
			<div class="info_bottom">
				<div class="album-btns" v-if="album_type == 'family'">
					<div class="album-btns-item pink-btn" @click="createFamilyAlbum">创建亲情相册</div>
					<div class="album-btns-item white-btn" @click="editFamilyAlbum">更新亲情相册</div>
				</div>
				<div class="album-btns" v-if="album_type == 'zhongcao'">
					<div class="album-btns-item pink-btn" @click="uploadPhoto">上传作品</div>
					<div class="album-btns-item white-btn" @click="editPhoto">{{is_edit?'取消编辑':'编辑作品'}}</div>
				</div>
				<div class="mine-bottom" v-if="album_type == 'detail_mine'||album_type == 'detail_other'">
					<div class="mine-left">接单：{{mtinfo.send_order_num}}</div>
					<div class="mine-right">
						<div v-if="album_type == 'detail_mine'" class="right-btn-item white-btn" @click="editMessage">编辑资料</div>
						<div v-if="album_type == 'detail_other'" :class="['right-btn-item white-btn',mtinfo.is_follow==1?'followed':''] " @click="followModel">{{mtinfo.is_follow==1?'已关注':'关注'}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			album_type: {
				type: String,
				default: ""
			},
			mt_id:{
				type: [String,Number],
				default: 0
			},
			is_edit:{
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				mtinfo: {},
				channels: this.channels,
				mtChannelList: [],
				id:0,
				isMine:0
			}
		},
		activated() {
			this.getMyInfo()
		},
		methods: {
			checkProvince(name) {
				if (name.indexOf('广西') != -1) {
					return "广西"
				}
				if (name.indexOf("宁夏") != -1) {
					return "宁夏"
				}
				if (name.indexOf("黑龙江") != -1) {
					return "黑龙江"
				}
				if (name.indexOf("新疆") != -1) {
					return "新疆"
				}
				if (name.indexOf("西藏") != -1) {
					return "西藏"
				}
				if (name.indexOf("内蒙古") != -1) {
					return "内蒙古"
				}
				return name
			},
			createFamilyAlbum(){
				this.$emit("createFamilyAlbum")
			},
			editFamilyAlbum(){
				this.$emit("editFamilyAlbum")
			},
			uploadPhoto(){
				this.$emit("uploadPhoto")
			},
			editPhoto(){
				this.$emit("editPhoto")
			},
			editMessage(){
				this.$emit("editMessage")
			},
			shareCard(){
				this.$emit("shareCard")
			},
			toWeApp(){
				this.$router.push({
					name: 'shareCode'
				})
			},
			//获取模特基本信息
			getMyInfo() {
				console.log("模特主页组件信息",this.mt_id)
				let my_id = JSON.parse(localStorage.getItem("userInfo")).user_id
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					is_mine:this.album_type=="detail_mine"?1:0,
					mt_id:this.album_type=="detail_other"?this.mt_id:my_id
				}
				
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/mtdetailv2",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "n") {
							return
						}
						if(res.data.data){
							let result = JSON.parse(res.data.data)
							console.log("模特详情信息======",result)
							let mtChannelList = result.qudao
							if (mtChannelList && mtChannelList.length != 0) {
								mtChannelList.forEach(item => {
									if (this.channels[item.auth_type]) {
										item.icon = this.channels[item.auth_type].icon
										item.name = this.channels[item.auth_type].name
									}
								})
							}
							this.mtinfo = result.mtinfo
							this.mtChannelList = mtChannelList
							if(this.album_type=="detail_mine"||this.album_type=="detail_other"){
								this.$emit("detailLoadFinish",result)
							}
						}
					});
			},
			followModel() {
				console.log("关注")
				this.toWeApp()
				return
				if (!localStorage.getItem("access_token")) {
					this.$router.push({
						name: "login"
					});
					return
				}
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					follow_id: this.mt_id,
					state: this.mtinfo.is_follow ? 2 : 1
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/mote/handlefollow",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == 'y') {
							this.$toast(this.mtinfo.is_follow ? "已取消关注" : "关注成功")
							this.mtinfo.is_follow = this.mtinfo.is_follow ? 0 : 1
						}
					});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.top_info_wrap {
		position: relative;
		overflow: hidden;
		height: 500px;

		.bg-img {
			width: 100%;
			height: 100%;
			filter: blur(2px);
		}

		.mtinfo-container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.info_top {
			display: flex;
			justify-content: space-between;
			padding: 120px 30px 20px;

			.headico_wrap {
				height: 160px;
				width: 160px;
				border-radius: 50%;
				display: block;
				margin-right: 15px;
				object-fit: cover;
			}

			.main_info {
				flex: 1;
				overflow: hidden;
				box-sizing: border-box;

				.main_top {
					width: 100%;
					display: flex;
					align-items: center;
					overflow: hidden;
					align-items: center;
					line-height: 45px;

					.nick_name {
						color: #fff;
						font-size: 36px;
						font-weight: bold;
						max-width: 50%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.can-photo {
						padding: 0 15px;
						line-height: 36px;
						height: 36px;
						color: #fff;
						background-color: #8AE6A8;
						border-radius: 5px;
						font-size: 22px;
						font-weight: 700;
						margin-left: 10px;
						font-weight: normal;
					}
				}

				.top-channel-con {
					margin-top: 10px;
					display: flex;
					margin-bottom: 15px;

					.top-channel-icon {
						width: 40px;
						height: 40px;
						display: block;
						margin-right: 10px;
						background-color: #fff;
						border-radius: 50%;
					}
				}

				.main_m_b {
					height: 40px;
					width: 500px;
					background-color: rgba(0, 0, 0, 0.5);
					border-radius: 20px;
					box-sizing: border-box;
					padding-left: 30px;
					display: flex;
					align-items: center;
					margin-top: 10px;

					.m_b_item {
						height: 100%;
						width: 120px;
						display: flex;
						align-items: center;
					}

					.m_b_item_icon {
						height: auto;
						width: 26px;
						margin-right: 10px;
						display: block;
					}

					.m_b_item_text {
						color: #fff;
						font-size: 22px;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						flex: 1;
					}
				}
			}

		}

		.info_middle {
			padding: 0 30px;
			height: 70px;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			color: #fff;
			font-size: 26px;
		}

		.info_bottom {
			padding:0 30px 30px;
			position: absolute;
			bottom: 0;
			width: 100%;
			.album-btns {
				display: flex;
				justify-content: center;
				align-items: center;

				.album-btns-item {
					width: 240px;
					height: 70px;
					line-height: 70px;
					border-radius: 35px;
					font-size: 30px;
					text-align: center;
					margin-right: 30px;
					&:last-child{
						margin-right: 0;
					}
				}
				.follow-btns-item{
					width: 200px;
					height: 64px;
					line-height: 64px;
					border-radius: 32px;
					font-size: 30px;
					text-align: center;
					margin-right: 30px;
					&:last-child{
						margin-right: 0;
					}
				}
			}
			.mine-bottom{
				display: flex;
				justify-content: space-between;
				align-items: center;
				color: #fff;
				line-height: 70px;
				.mine-right{
					display: flex;
					line-height: 60px;
					.right-btn-item{
						width: 180px;
						height: 60px;
						line-height: 60px;
						border-radius: 35px;
						font-size: 28px;
						text-align: center;
						margin-left: 20px;
						&:first-child{
							margin-left: 0;
						}
					}
				}
			}
		}
	}
	.pink-btn {
		background-color: #FF6685;
		color: #fff;
	}
	
	.white-btn {
		background-color: #fff;
		color: #FF6685;
	}
	.followed{
		background-color: rgba(0,0,0,0.6);
		color: #fff;
	}
</style>
