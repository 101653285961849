<template>
	<div class="container-wrapper">
		<navbar title="佣金明细"></navbar>
		<div class="title-container">
			<div class="title-item" :style="'width:'+widthList[0]+';text-align:left;'">订单编号</div>
			<div class="title-item" :style="'width:'+widthList[1]+';text-align:center;'">金额(元)</div>
			<div class="title-item" :style="'width:'+widthList[2]+';text-align:right;'">时间</div>
		</div>
		<scroll-y class="detail-scroll" @loadMore="getCommissionDetail">
			<empty v-if="isNull" title="暂无佣金明细"></empty>
			<div v-else class="detail-container">
				<div class="detail-item" v-for="(item,index) in list" :key="index">
					<div class="item-data" :style="'width:'+widthList[0]+';text-align:left;'">{{item.order_sn}}</div>
					<div class="item-data" :style="'width:'+widthList[1]+';text-align:center;'">
						{{getNumber(item.mt_price)}}</div>
					<div class="item-data" :style="'width:'+widthList[2]+';text-align:right;'">{{item.finish_time}}
					</div>
				</div>
				<div class="nomore" v-if="stopLoad">没有更多了~</div>
			</div>
		</scroll-y>
	</div>
</template>

<script>
	import scrollY from "@/components/app-scroll-y.vue"
	import empty from "@/components/empty.vue"
	import navbar from "@/components/navbar.vue"
	export default {
		data() {
			return {
				widthList: ["45%", "20%", "35%"],
				list: [],
				stopLoad: false,
				isNull: false,
				page: 1,
				page_size: 30
			}
		},
		components: {
			scrollY,
			empty,
			navbar
		},
		activated() {
			this.getCommissionDetail()
		},
		methods: {
			getNumber(money) {
				if (!money) {
					return '0.0'
				}
				money /= 100
				return money.toFixed(1)
			},
			getCommissionDetail() {
				if (this.stopLoad) {
					return
				}
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					page: this.page++,
					page_size: this.page_size
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/center/commissionlist",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						//console.log(res.data)
						if (res.data.status == "n") {
							return
						}
						let result = JSON.parse(res.data.data)
						console.log(result)
						if (result.list.length != 0) {
							this.list.push(...result.list)
						}
						if (result.list.length < this.page_size) {
							this.stopLoad = true
							if (result.list.length == 0 && this.page == 2) {
								this.isNull = true
							}
						}
					});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper {
		display: flex;
		flex-direction: column;
		background-color: #fff;

		.detail-scroll {
			flex: 1;
		}
	}

	.title-container {
		display: flex;
		padding: 0 30px;
		line-height: 100px;
		font-size: 28px;
		background-color: #F1F1F1;
	}

	.detail-item {
		display: flex;
		padding: 0 30px;
		line-height: 90px;
		font-size: 22px;
		border-bottom: 1px solid #f1f1f1;

		.goods-name {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
	.nomore{
		text-align: center;
		line-height: 40px;
		padding-top: 30px;
		padding-bottom: 50px;
		color: #aaa;
		font-size: 26px;
	}
</style>
