<template>
  <div class="xhs-auth-wrap">
    <div class="card-title">
      <div class="back_img">
        <img class="back_img"
             src="https://jmstatic-1255497916.cos.ap-shanghai.myqcloud.com/common/resource/normal_icon/Back%403x.png"
             @click="back_list"
             alt/>
      </div>
      身份认证
    </div>

    <div class="title">小红书实名认证</div>
    <input class="input" type="text" v-model="name" placeholder="姓名"/>
    <input class="input" type="text" v-model="card_no" placeholder="身份证号"/>
    <div class="commit-btn" @click="showModel">实名认证</div>

    <van-dialog v-model="isShow" title="温馨提示" show-cancel-button confirm-button-text="我已认证" @confirm="toAuthResult">
      <div class="link-con">
        <div class="link">{{verify_url}}</div>
        <div class="copy-btn" @click="copyContent">复制</div>
      </div>
      <div class="tip1">请复制链接，在手机任意浏览器中打开（百度浏览器除外），完成实名认证</div>
      <div class="tip2">链接只能打开一次，若认证失败或者未认证，请点击
        <van-icon name="replay" class="cuIcon-refresh" style="color:#FF6685" @click="getAuthLink"/>
      </div>
    </van-dialog>

  </div>
</template>

<script>
  export default {
    name: "XhsRename",
    data(){
      return {
        isShow: false,
        card_no: "",
        name: "",
        verify_url: "",
        verify_id: 0,
      }
    },
    methods:{
      showModel() {
        if (!this.name) {
          this.$toast("请填写姓名");
          return
        }
        if (!this.card_no) {
          this.$toast("请填写身份证号");
          return
        }
        this.getAuthLink()
      },
      hideModel() {
        this.isShow = false;
      },
      getAuthLink(){
        this.verify_url = "";
        this.verify_id = 0;
        let sendData = {
          real_name: this.name,
          real_idnum: this.card_no,
          access_token: localStorage.getItem('access_token')
        };
        let params = this.$addParams(sendData);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/mote/xhsrealname",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then(res => {
          if (res.data.status == "n") {
            this.$toast(res.data.error);
            return
          }
          let result = JSON.parse(res.data.data);
          console.log(result);
          this.verify_url = result.verify_url;
          this.verify_id = result.verify_id;
          this.isShow = true;
        });
      },
      //复制
      copyContent() {
        this.$copyText(this.verify_url).then(() => {
            this.$toast('复制成功');
          }, () => {
            this.$toast('复制失败');
          }
        )
      },
      //校验是否实名
      toAuthResult() {
        console.log("verify_id=========="+this.verify_id);
        let sendData = {
          verify_id: this.verify_id || 0,
          access_token: localStorage.getItem('access_token')
        };
        let params = this.$addParams(sendData);
        this.$axios.post(
          process.env.VUE_APP_URL + "/mobi/mote/xhsrealnamebtn",
          this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        ).then(res => {
          if (res.data.status == "n") {
            let error = res.data.error;
            this.$router.push({
              name:'authSuccess',
              query:{
                type: 2,
                msg: error
              }
            });
            return;
          }
          this.$router.push({
            name:'authSuccess',
            query:{
              type: 1,
            }
          })
        });
      },
      back_list() {
        this.$router.back(-1);
      },
    }
  }
</script>

<style lang="scss" scoped>

  .xhs-auth-wrap {
    background-color: #fff;
    height:100vh;
    .card-title {
      width: 100%;
      height: 80px;
      display: flex;
      font-size: 35px;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 20px;
      background: #fff;

      .back_img {
        position: absolute;
        left: 20px;
        width: 50px;
        height: 50px;
        background-color: rgba(220, 220, 220, 0.5);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 80%;
          width: 80%;
          margin-left: -10px;
          opacity: 0.6;
        }
      }
    }

    .title {
      padding: 0 30px;
      line-height: 120px;
      color: #aaa;
      background-color: #f7f7f7;
      font-size: 32px;
    }

    .input {
      padding: 0 30px;
      height: 100px;
      font-size: 30px;
    }

    .placeholder {
      font-weight: 700;
    }

    .commit-btn {
      margin: 100px 30px 0;
      line-height: 90px;
      border-radius: 45px;
      color: #fff;
      font-size: 30px;
      font-weight: 700;
      text-align: center;
      background-color: #000;
    }
  }

  ::v-deep .van-overlay {
    background-color: rgba(0, 0, 0, .5);
  }

  .van-dialog {
    width: 570px;
    font-size: 30px;
    padding: 30px 40px;

    ::v-deep .van-dialog__header {
      font-size: 34px;
      font-weight: 600;
      padding-top: 15px;
    }

    ::v-deep .van-dialog__content {
      margin-top: 40px;
      text-align: center;
      line-height: 40px;
      .link-con{
        display: flex;
        width: 100%;
        line-height: 70px;
        background-color: #f5f5f5;
        border-radius: 10px;
        justify-content: space-between;
      }
      .link{
        flex: 1;
        padding-left: 30px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .copy-btn{
        color: #FF6685;
        padding: 0 30px;
      }
      .tip1{
        font-weight: 600;
        color: #000;
        line-height: 40px;
        margin: 20px 0;
        text-align: left;
        font-size: 28px;
      }
      .tip2{
        font-weight: 700;
        color: #aaa;
        line-height: 40px;
        text-align: left;
        font-size: 28px;
        .cuIcon-refresh {
          display: inline-block;
          vertical-align: sub;
        }
      }

    }

    ::v-deep .van-dialog__footer {
      margin-top: 50px;
      justify-content: center;

      .van-button {
        font-size: 32px;
        height: 68px;
        border-radius: 50px;
        flex: none;
        width: 220px;

        &.van-dialog__confirm {
          background-color: #000;
          color: #fff;
        }

        &.van-dialog__cancel {
          border: 1px solid #8c8c8c;
          margin-right: 40px;
        }
      }
    }
  }

</style>