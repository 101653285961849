<template>
	<div class="container-wrapper">
		<navbar title="意见反馈"></navbar>
		<div class="my-container">
			<div class="feedback-subtitle">分类标签：</div>
			<div class="feedback-type-con">
				<div :class="['feedback-item',currentType==item.type?'selected-type':'']"
					v-for="(item,index) in feedbackTypes" :key="index" @click="chooseType(item.type)">{{item.text}}</div>
			</div>
		</div>
		<div class="my-container">
			<div class="feedback-subtitle">反馈描述：</div>
			<textarea class="feedback-textarea" v-model="content" placeholder="请留下您宝贵的建议，我们将不断改进"></textarea>
		</div>
		<div class="my-container">
			<div class="feedback-subtitle">上传图片：</div>
			<div class="picture-container">
				<van-uploader :disabled="this.feedbackImages.length >= this.max_length" :before-read="chooseImage" multiple>
					<div class="pic-item">
						<i class="iconfont icon-add"></i>
					</div>
				</van-uploader>
				
				<div class="pic-item" v-for="(item,index) in feedbackImages" :key="index">
					<img :src="item"></img>
					<i class="iconfont icon-close-bold" @click="deleteImage(index)"></i>
				</div>
			</div>
		</div>
		<div class="feedback-btn" @click="commitMessage">提交</div>
	</div>
</template>

<script>
	import navbar from "@/components/navbar.vue"
	import {
		uploadFile
	} from "../../../util/uploadBaogao.js"
	export default {
		data() {
			return {
				feedbackTypes: [{
					text: "bug反馈",
					type: 1
				}, {
					text: "功能建议",
					type: 2
				}, {
					text: "内容建议",
					type: 3
				}, {
					text: "体验建议",
					type: 4
				}],
				content: "",
				currentType: 1,
				feedbackImages: [],
				max_length: 3,
				flag: false
			}
		},
		components:{
			navbar
		},
		activated() {
			this.initData()
		},
		methods: {
			initData(){
				this.flag = false
				this.currentType = 1
				this.feedbackImages = []
				this.content = ""
			},
			chooseType(type) {
				this.currentType = type
			},
			chooseImage(files) {
				if (this.feedbackImages.length >= this.max_length) {
					this.$toast(`最多上传${this.max_length}张图`)
					return
				}
				console.log("选择照片")
				let file = [];
				if (!Array.isArray(files)) {
					file.push(files)
				} else {
					file = files;
				}
				for (let i = 0; i < file.length; i++) {
					console.log(file[i])
					uploadFile(file[i], "user/img/feedbackImages").then(res => {
						console.log("上传结果====",res)
						if (res.status == "success") {
							this.feedbackImages.push(res.url)
						}
					})
				}
			},
			deleteImage(index) {
				this.feedbackImages.splice(index, 1)
			},
			commitMessage() {
				if (this.flag) {
					return
				}
				this.flag = true
				if (!this.content) {
					this.$toast("请填写反馈意见")
					return
				}
				let sentData = {
					access_token: localStorage.getItem("access_token"),
					lable_id: this.currentType,
					content: this.content,
					attach: this.feedbackImages.join(",")
				}
				let params = this.$addParams(sentData)
				// console.log(params);
				this.$axios.post(
						process.env.VUE_APP_URL + "/mobi/user/feedcommit",
						this.$qs.stringify(params), {
							headers: {
								"Content-Type": "application/x-www-form-urlencoded",
							},
						}
					)
					.then((res) => {
						if (res.data.status == "y") {
							this.$toast("提交成功")
							setTimeout(()=> {
								this.$router.back(-1);
							}, 1200)
						} else {
							this.$toast(res.data.error)
						}
					});
			},
		}
	}
</script>

<style lang="scss" scoped>
	.container-wrapper{
		background-color: #fff;
	}
	.my-container {
		padding: 24px 24px 0;
	}

	.feedback-subtitle {
		line-height: 40px;
		margin-bottom: 10px;
		font-size: 28px;
	}

	.feedback-type-con {
		display: flex;
		justify-content: space-between;
	}

	.feedback-item {
		width: 160px;
		text-align: center;
		border-radius: 5px;
		border: 2px solid #ddd;
		font-size: 28px;
		color: #aaa;
		line-height: 70px;
	}

	.feedback-textarea {
		padding: 15px 20px;
		line-height: 45px;
		width: 100%;
		height: 220px;
		border-radius: 10px;
		background-color: #F5F5F5;
		border: 0;
	}

	::-webkit-input-placeholder {
		/* WebKit, Blink, Edge */
		color: #aaa;
	}

	:-moz-placeholder {
		/* Mozilla Firefox 4 to 18 */
		color: #aaa;
	}

	::-moz-placeholder {
		/* Mozilla Firefox 19+ */
		color: #aaa;
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #aaa;
	}


	.feedback-btn {
		width: 300px;
		line-height: 80px;
		background-color: #000;
		color: #fff;
		border-radius: 40px;
		text-align: center;
		margin: 50px auto;
		font-size: 32px;
	}

	.selected-type {
		background-color: #FFEBEF;
		color: #FF6685;
		border-color: #FF6685;
	}

	.picture-container {
		display: flex;
		flex-wrap: wrap;
	}

	.pic-item:first-child {
		background-color: #f5f5f5;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
	}

	.pic-item .icon-add {
		color: #ccc;
		font-size: 60px;
	}

	.pic-item {
		width: 165px;
		height: 165px;
		margin-right: 14px;
		margin-bottom: 14px;
		position: relative;
	}

	.pic-item img {
		width: 100%;
		height: 100%;
		display: block;
		object-fit: cover;
	}

	.pic-item .icon-close-bold {
		position: absolute;
		right: 0;
		top: 0;
		width: 40px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		color: #fff;
		background-color: rgba(0, 0, 0, 0.6);
		font-size: 26px;
	}

	.pic-item:nth-child(4n) {
		margin-right: 0;
	}
</style>
